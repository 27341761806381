import { gql } from '@apollo/client';

const LD_PAGE_RECS = gql`
  query ldPageRecs {
    config {
      pageRecommendationJsonArray: genericType(name: "pageRecommendationJsonArray")
    }
  }
`;

/*
  We're keeping it as a named export for now just in case we want to shove more queries into this
  file before we're all done (hence the temporarily-disabled eslint rule below!)
*/
export {
  LD_PAGE_RECS, // eslint-disable-line import/prefer-default-export
};
