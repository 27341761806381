import PropTypes from 'prop-types';
import React from 'react';
import CategoryRailNavigation from '../CategoryRailNavigation/CategoryRailNavigation';
import FacetsLeftRail from '../FacetsLeftRail';
import StoreToggle from '../StoreToggle';
import style from './CategoryPage.module.scss';

function LeftRail({
  brand,
  categoryData,
  categoryId,
  handleCheckBoxChange,
  handleStoreFilter,
  hasStoreToggleFeature,
  parameters,
  storeData,
}) {
  return (
    <aside className={style.row_left}>
      <div className={style.nav}>
        <CategoryRailNavigation
          categoryId={categoryId}
          StoreToggle={
            !!hasStoreToggleFeature && (
              <>
                <StoreToggle
                  handleStoreFilter={handleStoreFilter}
                  isShopMyStore={parameters.filter !== ''}
                  storeDetails={storeData}
                />
                <hr />
              </>
            )
          }
        />
      </div>
      {categoryData?.facets?.length > 0 && (
        <div className={style.filters}>
          <FacetsLeftRail
            brand={brand}
            facet={parameters.facet}
            facetData={categoryData?.facets}
            isCategoryPage
            onCheckBoxChange={handleCheckBoxChange}
            popularSearches={categoryData?.popularSearches}
          />
        </div>
      )}
    </aside>
  );
}

LeftRail.propTypes = {
  brand: PropTypes.string.isRequired,
  categoryData: PropTypes.shape({
    breadcrumbTrailEntryView: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    categoryId: PropTypes.string,
    facets: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    leftRailCatNavFlag: PropTypes.bool,
    name: PropTypes.string,
    navParentCategoryId: PropTypes.string,
    popularSearches: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    searchURL: PropTypes.string,
    sortData: PropTypes.shape({
      defaultSortOption: PropTypes.string,
      sortOptions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          value: PropTypes.string,
        }),
      ),
    }),
    title: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  categoryId: PropTypes.string.isRequired,
  handleCheckBoxChange: PropTypes.func.isRequired,
  handleStoreFilter: PropTypes.func.isRequired,
  hasStoreToggleFeature: PropTypes.bool.isRequired,
  parameters: PropTypes.shape({
    categoryId: PropTypes.string,
    facet: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
    filter: PropTypes.string,
    rows: PropTypes.string,
    sort: PropTypes.string,
    start: PropTypes.string,
  }).isRequired,
  storeData: PropTypes.shape({
    storeName: PropTypes.string,
    storeId: PropTypes.string,
  }),
};

export default LeftRail;
