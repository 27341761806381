import { gql } from '@apollo/client';

const GET_MFE_COMPONENT = gql`
  query GetMfeComponent($componentName: String!, $includeScript: Boolean!, $mfeName: String!, $ttl: Int, $page: String) {
    standaloneMfe(componentName: $componentName, includeScript: $includeScript, mfeName: $mfeName, ttl: $ttl, page: $page) {
      componentHtml
    }
  }
`;

export default GET_MFE_COMPONENT;
