import { ACTION } from './constants';
import { getStringifiedFacetArray } from './getStringifiedFacetArray';

const emptyString = '';
const defaultValues = {
  actionType: 'pageLoad',
  historyNavigation: false,
  countryFulfillmentStore: emptyString,
  departmentId: emptyString,
  facet: [],
  filter: emptyString,
  initialSearchTerm: emptyString,
  previousSearchterm: null,
  rows: '90',
  searchTerm: emptyString,
  sort: 'bestmatch',
  start: '0',
  storePreview: 'false',
  submitMethod: { trigger: 'toaster' },
  userInputedSearchTerm: emptyString,
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION.FACET_TOGGLE:
      return {
        ...state,
        actionType: ACTION.FACET_TOGGLE,
        historyNavigation: defaultValues.historyNavigation,
        facet: getStringifiedFacetArray(action.payload),
        initialSearchTerm: defaultValues.initialSearchTerm,
        previousSearchterm: state.searchTerm,
        start: defaultValues.start,
      };

    case ACTION.CLEAR_ALL_PARAMETERS:
      return {
        ...state,
        actionType: ACTION.CLEAR_ALL_PARAMETERS,
        historyNavigation: defaultValues.historyNavigation,
        facet: defaultValues.facet,
        filter: defaultValues.filter,
        initialSearchTerm: defaultValues.initialSearchTerm,
        previousSearchterm: state.searchTerm,
        start: defaultValues.start,
      };

    case ACTION.SORT_UPDATE:
      return {
        ...state,
        actionType: ACTION.SORT_UPDATE,
        initialSearchTerm: defaultValues.initialSearchTerm,
        historyNavigation: defaultValues.historyNavigation,
        previousSearchterm: state.searchTerm,
        sort: action.payload,
        start: defaultValues.start,
      };

    case ACTION.DEPARTMENT_CHANGE:
      return {
        ...state,
        actionType: ACTION.DEPARTMENT_CHANGE,
        historyNavigation: defaultValues.historyNavigation,
        departmentId: action.payload,
        facet: defaultValues.facet,
        filter: defaultValues.filter,
        initialSearchTerm: defaultValues.initialSearchTerm,
        previousSearchterm: state.searchTerm,
        start: defaultValues.start,
      };

    case ACTION.SEARCHTERM:
      return {
        ...state,
        actionType: ACTION.SEARCHTERM,
        historyNavigation: defaultValues.historyNavigation,
        facet: action?.payload?.submitMethod?.trigger === 'attributePill' ? state.facet : defaultValues.facet,
        filter: defaultValues.filter,
        departmentId: action.payload.departmentId ?? defaultValues.departmentId,
        initialSearchTerm: defaultValues.initialSearchTerm,
        previousSearchterm: state.searchTerm,
        searchTerm: action.payload.searchTerm ?? defaultValues.searchTerm,
        start: defaultValues.start,
        submitMethod: action.payload.submitMethod ?? defaultValues.submitMethod,
        userInputedSearchTerm: action.payload.searchTerm ?? defaultValues.userInputedSearchTerm,
      };

    case ACTION.PAGINATION:
      return {
        ...state,
        actionType: ACTION.PAGINATION,
        initialSearchTerm: defaultValues.initialSearchTerm,
        historyNavigation: defaultValues.historyNavigation,
        start: action.payload,
      };

    case ACTION.PRICE:
      return {
        ...state,
        historyNavigation: defaultValues.historyNavigation,
        initialSearchTerm: defaultValues.initialSearchTerm,
        previousSearchterm: state.searchTerm,
        start: defaultValues.start,
      };

    case ACTION.LOCAL_STORE_TOGGLE:
      return {
        ...state,
        actionType: ACTION.LOCAL_STORE_TOGGLE,
        historyNavigation: defaultValues.historyNavigation,
        filter: action.payload ?? defaultValues.filter,
        initialSearchTerm: defaultValues.initialSearchTerm,
        previousSearchterm: state.searchTerm,
        start: defaultValues.start,
      };

    case ACTION.SUGGESTED_SEARCHTERM:
      return {
        ...state,
        historyNavigation: defaultValues.historyNavigation,
        departmentId: action.payload.departmentId,
        initialSearchTerm: null,
        searchTerm: action.payload.searchTerm,
      };

    case ACTION.HISTORY_NAVIGATION:
      return {
        ...state,
        ...(action.payload.categoryId && { categoryId: action.payload.categoryId }),
        ...(action.payload.departmentId && { departmentId: action.payload.departmentId }),
        ...(action.payload.previousSearchterm
          && { previousSearchterm: action.payload.previousSearchterm }),
        ...(action.payload.searchTerm && { searchTerm: action.payload.searchTerm }),
        historyNavigation: true,
        initialSearchTerm: null,
        facet: action.payload.facet || defaultValues.facet,
        filter: action.payload.filter || defaultValues.filter,
        sort: action.payload.sort || defaultValues.sort,
        start: action.payload.start || defaultValues.start,
      };

    default:
      return state;
  }
};

export default reducer;
