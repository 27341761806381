import { gql } from '@apollo/client';

const LDFLAGS = gql`
  query ldFlags {
    config {
      userPreferenceBobToaster: genericType(name: "userPreferenceBobToaster")
    }
  }
`;

export default LDFLAGS;
