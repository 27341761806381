import { useQuery } from '@apollo/client';
import { node } from 'prop-types';
import React from 'react';
import ProductGridContext from '../../context/ProductGridContext';
import { IMAGE_HOST_NAME } from '../../queries';
import { IMAGE_SIZE_POLICY } from '../../tools/constants';
import getImageUrl from '../../tools/getImageUrl';
import getNoImageFoundImageId from '../../tools/getNoImageFoundImageId';

const ProductGridContextProvider = ({ children }) => {
  const { data } = useQuery(IMAGE_HOST_NAME);
  let brand;
  let imageHostName;
  let noImageFoundImgUrl;

  if (data) {
    ({ brand, imageHostName } = data?.imageHost);
    noImageFoundImgUrl = getImageUrl(
      imageHostName, getNoImageFoundImageId(brand), IMAGE_SIZE_POLICY.MEDIUM,
    );
  }

  return (
    <ProductGridContext.Provider value={{
      noImageFoundImgUrl,
      imageHostName,
      brand,
    }}
    >
      {children}
    </ProductGridContext.Provider>
  );
};

ProductGridContextProvider.propTypes = {
  children: node.isRequired,
};

export default ProductGridContextProvider;
