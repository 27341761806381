import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import MiniGrid from '../MiniGrid';

const parseFacets = (facetIdValue) => (facetIdValue ? facetIdValue.split(',').map((facet) => facet.trim()) : []);

function MiniGridMarketingPortal({ store, storeId }) {
  // Contractual names between this code and the empty content (AEM) div it should be injected into
  const MINI_GRID_CLASSNAME = 'wcs-mini-grid';
  const MINI_GRID_CATEGORY_ATTR = 'data-category-id';
  const MINI_GRID_FACET_ATTR = 'data-facet-ids';

  const miniGridContainerElementSelector = `.${MINI_GRID_CLASSNAME}[${MINI_GRID_CATEGORY_ATTR}]`;
  const [miniGridContainers, setMiniGridContainers] = useState([]);
  const totalMsForDOMQueries = 5000;
  const msBetweenDOMQueries = 500;

  useEffect(() => {
    const timerId = setInterval(() => {
      const newMiniGridContainers = [];
      document.querySelectorAll(miniGridContainerElementSelector).forEach((element) => {
        if (element.getAttribute(MINI_GRID_CATEGORY_ATTR)) {
          newMiniGridContainers.push(element);
        }
      });
      setMiniGridContainers(newMiniGridContainers);
    }, msBetweenDOMQueries);

    setTimeout(() => { clearInterval(timerId); }, totalMsForDOMQueries);
  }, [miniGridContainerElementSelector]);

  return miniGridContainers.map((miniGridContainer) => (
    createPortal(<MiniGrid
      categoryId={miniGridContainer.attributes[MINI_GRID_CATEGORY_ATTR].value}
      facetIds={parseFacets(miniGridContainer.attributes[MINI_GRID_FACET_ATTR].value)}
      store={store}
      storeId={storeId}
    />, miniGridContainer)
  ));
}

MiniGridMarketingPortal.propTypes = {
  storeId: PropTypes.string.isRequired,
  store: PropTypes.string.isRequired,
};

export default MiniGridMarketingPortal;
