import { gql, useQuery } from '@apollo/client';
import { Icon, Button } from 'anf-core-react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import BreakpointContext from '../../context/breakpoint';
import useTranslatedText from '../../hooks/useTranslatedText';
import TmntHtml from '../../tools/TmntHtml';
import style from './MyListNotifications.module.scss';

const getAccount = gql`
  query account {
    user {
      isLoggedIn
    }
  }
`;

const MyListNotifications = ({
  isFirstTimeSave = false, signInLink, createAccountLink, myListLink, brand,
}) => {
  const { data } = useQuery(getAccount);

  const openMyList = useTranslatedText('openMyList', { fallback: 'Open My List' });
  const savedMsg = useTranslatedText('savedMsg', { fallback: 'Added to List!' });
  const savedMemberMsg = useTranslatedText('savedMemberMsg', { fallback: 'Item saved to your account' });
  const savedGuestMsg = useTranslatedText('savedGuestMsg', { fallback: 'Item added to your list!' });
  const savedSignInPrompt = useTranslatedText('savedSignInPrompt', { fallback: 'Sign in to keep your items for up to 60 days.', replacements: [signInLink, 'login-trigger signIn'] });
  const savedCreateAccountPrompt = useTranslatedText('savedCreateAccountPrompt', { fallback: 'Create an Account', replacements: [createAccountLink, 'login-trigger createAccount'] });
  const { large: isDesktop } = useContext(BreakpointContext);
  if (!data) return null;

  const guestFirstTimeMessage = isDesktop ? savedGuestMsg.value : savedMsg.value;
  const memberFirstTimeMessage = isDesktop ? savedMemberMsg.value : savedMsg.value;

  const { isLoggedIn } = data.user;
  if (isFirstTimeSave) {
    if (isLoggedIn) {
      // logged in first time message
      return (
        <div className={classNames(style.flyout, style.firstTime, style.loggedIn)}>
          <div>
            <p className={style.bigMessage} data-property={savedMemberMsg.key}>
              {memberFirstTimeMessage}
            </p>
            <p className={style.myListButton}>
              <Button
                href={myListLink}
                isFullWidth
                variant="secondary"
              >
                {openMyList.value}
              </Button>
            </p>
          </div>
        </div>
      );
    }
    // guest first time message
    return (
      <div className={classNames(style.flyout, style.firstTime, style.loggedOut)}>
        <div>
          <p className={style.bigMessage} data-property={savedGuestMsg.key}>
            <span className={style.heart}><Icon icon="heart-filled" size="s" /></span>
            {guestFirstTimeMessage}
          </p>
          <div className={style.smallMessage}>
            <p>
              <TmntHtml property={savedSignInPrompt.key || ''} value={savedSignInPrompt.value || ''} />
            </p>
            <p>
              <TmntHtml property={savedCreateAccountPrompt.key || ''} value={savedCreateAccountPrompt.value || ''} />
            </p>
          </div>
          <p className={style.myListButton}>
            <Button
              href={myListLink}
              isFullWidth
              theme={brand === 'hol' ? 'inverse' : ''}
              variant="secondary"
            >
              {openMyList.value}
            </Button>
          </p>
        </div>
      </div>
    );
  }
  // message for saves after the first one
  return (
    <div className={style.flyout}>
      <p className={style.bigMessage} data-property={savedMsg.key}>
        <span className={style.heart}><Icon icon="heart-filled" size="s" /></span>
        {savedMsg.value}
      </p>
    </div>
  );
};

MyListNotifications.propTypes = {
  brand: PropTypes.string.isRequired,
  createAccountLink: PropTypes.string.isRequired,
  isFirstTimeSave: PropTypes.bool,
  myListLink: PropTypes.string.isRequired,
  signInLink: PropTypes.string.isRequired,
};

export default MyListNotifications;
