import { gql, useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import ProductGridContext from '../../context/ProductGridContext';
import BreakpointContext from '../../context/breakpoint';
import useFetchMySaves from '../../hooks/useFetchMySaves';
import productsFragment from '../../queries/fragments/Products';
import $window from '../../tools/window';
import { ERROR_MESSAGE } from '../Messages/Messages';
import MiniGridHeader from '../MiniGridHeader/MiniGridHeader';
import ProductCard from '../ProductCard/ProductCard';
import ProductGrid from '../ProductGrid/ProductGrid';
import SkeletonProductGrid from '../ProductGrid/SkeletonGrid';
import style from './MiniGrid.module.scss';
import ShopAllButton from './ShopAllButton';
import getQueryString from './tools/getQueryString';

const getCategoryData = gql`
  ${productsFragment}
  query CategoryMiniGrid(
    $categoryId: String!,
    $icmpTag: String,
    $rows: String!,
    $start: String!,
    $facet: [String],
  ) {
    category(
      categoryId: $categoryId,
      facet: $facet,
      icmpTag: $icmpTag,
      rows: $rows,
      start: $start,
    ) {
      brand,
      url,
      products {
        ...Products
        price {
          priceFlag
        }
      }
      miniGridFacets {
        id
        name
        facetValues {
          id
          name
        }
      }
    }
  }
`;

const icmpShopAllTag = 'ICT:EVGRN:D-D:CP:EL:COL:PDT:X:ShopAll:X:';

const triggerFiltersApplied = (category, valueName) => {
  if ($window.digitalData) {
    $window.digitalData.trigger(
      'search_filter_applied',
      {
        event_name: 'filter_applied',
        search_filter_applied_category: category,
        search_filter_applied_value_name: valueName,
      },
    );
  }
};

const generateFacetQueryParam = (facetId, values) => [`${facetId}:(${values.map((options) => `"${options}"`).join(' ')})`];

// TODO: Remove lint exception and use the facetIds prop
// eslint-disable-next-line no-unused-vars
function MiniGrid({
  categoryId,
  facetIds = [],
}) {
  const defaultFacetState = {
    facetQueryParam: [],
    parentId: facetIds[0],
    valueIds: [],
  };
  const [products, setProducts] = useState([]);
  const [isDomLoaded, setIsDomLoaded] = useState(false);
  const [facetData, setFacetData] = useState([]);
  const [selectedFacet, setSelectedFacet] = useState(defaultFacetState);

  const { brand, imageHostName, noImageFoundImgUrl } = useContext(ProductGridContext);
  const { large: isDesktop } = useContext(BreakpointContext);

  const { mySavesItems, refetch } = useFetchMySaves();

  // Only query for products when we ask for them (after we render and determine width)
  const [getMiniGridData, {
    previousData, loading, error, data,
  }] = useLazyQuery(
    getCategoryData,
    {
      onCompleted: (fetchedProducts) => {
        setProducts(fetchedProducts?.category?.products);
        setFacetData(fetchedProducts?.category?.miniGridFacets);
      },
    },
  );

  const isSpotlightCard = (productIndex) => {
    if (isDesktop) {
      const modPosition = productIndex % 6;
      return modPosition === 4 || modPosition === 5;
    }
    const modPosition = productIndex % 3;
    return modPosition === 2;
  };

  const handleFilterButtonClick = (event) => {
    const { value } = event.target;

    setSelectedFacet((prevState) => {
      const { parentId } = prevState;
      const valueIds = [...prevState.valueIds, value];
      const facetQueryParam = generateFacetQueryParam(parentId, valueIds);

      triggerFiltersApplied(
        valueIds.map(() => parentId).toString(),
        valueIds.toString(),
      );

      return { ...prevState, valueIds, facetQueryParam };
    });
  };

  const handleFacetTagClick = (event) => {
    const { facetValue } = event;

    setSelectedFacet((prevState) => {
      const { parentId } = prevState;
      const valueIds = prevState.valueIds.filter((value) => value !== facetValue);
      const facetQueryParam = valueIds.length > 0
        ? generateFacetQueryParam(parentId, valueIds)
        : [];

      triggerFiltersApplied(
        valueIds.map(() => parentId).toString(),
        valueIds.toString(),
      );

      return { ...prevState, valueIds, facetQueryParam };
    });
  };

  const buildUrlWithFacet = (categoryUrl) => `${categoryUrl}${getQueryString(selectedFacet.facetQueryParam)}`;

  const facetNameFromId = (id) => facetData.find((facet) => facet.id === id)?.name;

  const handleFacetDropDownChange = (event) => {
    const parentId = event.target.value;
    setSelectedFacet({ ...defaultFacetState, parentId });
    triggerFiltersApplied('shop by', facetNameFromId(parentId));
  };

  useEffect(() => {
    setIsDomLoaded(true);

    getMiniGridData({
      variables: {
        categoryId,
        start: '0',
        rows: isDesktop ? '10' : '5',
        icmpTag: icmpShopAllTag,
        facet: selectedFacet.facetQueryParam,
      },
    });
  }, [categoryId, getMiniGridData, isDesktop, selectedFacet.facetQueryParam]);

  if (!isDomLoaded) return null;
  if (loading && !previousData) return <SkeletonProductGrid cardAmount={5} />;
  if (error) return ERROR_MESSAGE;

  return (
    <div className="scope-1892">
      <MiniGridHeader
        facetData={facetData}
        facetIds={facetIds}
        handleDropDownChange={handleFacetDropDownChange}
        onFacetTagClick={handleFacetTagClick}
        onFilterButtonClick={handleFilterButtonClick}
        selectedFacet={selectedFacet}
      />
      {loading && previousData ? <SkeletonProductGrid cardAmount={5} />
        : (
          <div className={style.miniGrid}>
            <ProductGrid
              breakpoint="large"
              desktopColumnCount={4}
              mobileColumnCount={2}
            >
              {products.map((product, index) => (
                <ProductCard
                  key={product.id}
                  brand={brand}
                  categoryId={categoryId}
                  imageHostName={imageHostName}
                  isDesktop={isDesktop}
                  isMiniGrid
                  isMiniGridSpotlightCard={isSpotlightCard(index)}
                  mySavesItems={mySavesItems}
                  noImageFoundImgUrl={noImageFoundImgUrl}
                  product={product}
                  refetch={refetch}
                />
              ))}
            </ProductGrid>
          </div>
        ) }
      {!!data?.category?.url
          && (
          <ShopAllButton
            brand={data.category?.brand}
            facet={selectedFacet.facetQueryParam}
            url={buildUrlWithFacet(data.category.url)}
          />
          )}
    </div>
  );
}

MiniGrid.propTypes = {
  categoryId: PropTypes.string.isRequired,
  facetIds: PropTypes.arrayOf(PropTypes.string),
};

export default MiniGrid;
