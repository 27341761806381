import { useQuery } from '@apollo/client';
import LDFLAGS from '../queries';

const useFeatureFlags = () => {
  const { data: ldFlagData } = useQuery(LDFLAGS);

  const searchFlyoutAlgonomySuggestions = ldFlagData?.config?.searchFlyoutAlgonomySuggestions?.value ?? false; // eslint-disable-line max-len

  return {
    searchFlyoutAlgonomySuggestions,
  };
};

export default useFeatureFlags;
