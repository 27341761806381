import { Pagination } from 'anf-core-react';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './gridPagination.module.scss';

function GridPagination({
  currentPage,
  onButtonClick,
  rows,
  start,
  totalPages,
}) {
  const rowsInt = parseInt(rows, 10);
  const startInt = parseInt(start, 10);

  return totalPages > 1
    ? (
      <div className={styles.gridPagination}>
        <Pagination
          firstButton={{
            isDisabled: currentPage === 1,
            // TODO: Replace with TMNT text?
            labelText: 'Go to first page',
            onClick: (event) => {
              /**
               * Ignore current start potentially being offset from page limit so that the first
               * page will display as many results as it can.
               */
              onButtonClick(event, '0');
            },
          }}
          lastButton={{
            isDisabled: currentPage === totalPages,
            // TODO: Replace with TMNT text?
            labelText: 'Go to last page',
            onClick: (event) => {
              const lastPageStartInt = startInt + rowsInt * (totalPages - currentPage);

              onButtonClick(event, lastPageStartInt.toString());
            },
          }}
          nextButton={{
            isDisabled: currentPage === totalPages,
            // TODO: Replace with TMNT text?
            labelText: 'Go to next page',
            onClick: (event) => {
              const nextPageStartInt = startInt + rowsInt;

              onButtonClick(event, nextPageStartInt.toString());
            },
          }}
          previousButton={{
            isDisabled: currentPage === 1,
            // TODO: Replace with TMNT text?
            labelText: 'Go to previous page',
            onClick: (event) => {
              /**
               * Prevent previous page from starting on a negative-index number. This should only
               * happen if the current start is offset from page limit and the previous page is the
               * first page, in which case we want to ignore the offset so that the first page will
               * display as many results as it can.
               */
              const previousPageStartInt = Math.max(startInt - rowsInt, 0);

              onButtonClick(event, previousPageStartInt.toString());
            },
          }}
          variant="abbreviated"
        >
          <div>
            {/* TODO: Replace with TMNT text? */}
            {`${currentPage} of ${totalPages}`}
          </div>
        </Pagination>
      </div>
    )
    : null;
}

GridPagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  rows: PropTypes.string.isRequired,
  start: PropTypes.string.isRequired,
  totalPages: PropTypes.number.isRequired,
};

export default GridPagination;
