import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import StoreDetailsContext from '../../context/StoreDetailsContext';

const StoreDetailsProvider = ({
  children, store, storeId, brand,
}) => {
  const storeData = useMemo(() => ({ store, storeId, brand }), [store, storeId, brand]);

  return (
    <StoreDetailsContext.Provider value={storeData}>
      {children}
    </StoreDetailsContext.Provider>
  );
};

StoreDetailsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  brand: PropTypes.string,
  store: PropTypes.string,
  storeId: PropTypes.string,
};

export default StoreDetailsProvider;
