import { Breadcrumb } from 'anf-core-react';
import PropTypes from 'prop-types';
import React from 'react';
import NumberOfResults from '../NumberOfResults/NumberOfResults';
import RefineButton from './RefineButton';
import style from './StickyRefine.module.scss';

function StickyRefineBar({
  brand,
  breadcrumbTrailEntryView = [],
  facet = [],
  facetData = [],
  filter = '',
  isCategoryPage = false,
  isFacetSelected = false,
  onCheckBoxChange = () => {},
  onClearAllBtnClick = () => {},
  onSortChange = () => {},
  resultsCount = null,
  searchTerm = '',
  selectedSort = '',
  sortData = {
    sortOptions: [],
  },
}) {
  const getBreadCrumbs = () => breadcrumbTrailEntryView.slice(1, 4).map((breadCrumb) => {
    const { label, url } = breadCrumb;
    return {
      href: url,
      label,
    };
  });
  return (
    <div className={style.stickyBar}>
      {breadcrumbTrailEntryView.length > 0 && (
        <Breadcrumb
          crumbs={getBreadCrumbs()}
          labelText="Breadcrumb"
        />
      )}
      {searchTerm && (
        <div className={style.overFlowHidden}>
          <h2 className={style.textDetails}>
            &quot;
            {searchTerm}
            &quot;
          </h2>
          <div>
            <NumberOfResults count={resultsCount} numberFirst />
          </div>
        </div>
      )}
      <RefineButton
        brand={brand}
        facet={facet}
        facetData={facetData}
        filter={filter}
        hasIconOnly
        isCategoryPage={isCategoryPage}
        isFacetSelected={isFacetSelected}
        onCheckBoxChange={onCheckBoxChange}
        onClearAllBtnClick={onClearAllBtnClick}
        onSortChange={onSortChange}
        resultsCount={resultsCount}
        selectedSort={selectedSort}
        sortData={sortData}
      />
    </div>
  );
}

StickyRefineBar.propTypes = {
  brand: PropTypes.string.isRequired,
  breadcrumbTrailEntryView: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
  facet: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  filter: PropTypes.string,
  isCategoryPage: PropTypes.bool,
  onSortChange: PropTypes.func,
  searchTerm: PropTypes.string,
  selectedSort: PropTypes.string,
  sortData: PropTypes.shape({
    sortOptions: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    })),
  }),
  resultsCount: PropTypes.number,
  facetData: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  isFacetSelected: PropTypes.bool,
  onClearAllBtnClick: PropTypes.func,
  onCheckBoxChange: PropTypes.func,
};

export default StickyRefineBar;
