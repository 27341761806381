import { gql } from '@apollo/client';
import facetDataFragment from '../../../queries/fragments/Facets';
import sortDataFragment from '../../../queries/fragments/ProductGridHeaderCoreFields';

const CATEGORY_PAGE_QUERY = gql`
  query CATEGORY_PAGE_QUERY($categoryId: String!, $isUnifiedCategoryPage: Boolean!) {
    category(categoryId: $categoryId) @include(if: $isUnifiedCategoryPage) {
      espotIdentifier
    }
    config {
      hasCatalogMfeEspots: genericType(name: "hasCatalogMfeEspots")
      hasShopMyStoreEnabled: genericType(name: "hasLISFilter")
      hasCategoryV3APIEnabled: genericType(name: "hasCategoryV3APIEnabled")
    }
  }
`;

const CATEGORY_PAGE_BY_FACET_QUERY = gql`
  ${sortDataFragment}
  ${facetDataFragment}
  query CATEGORY_PAGE_BY_FACET_QUERY($categoryId: String!, $facet: [String]) {
    category(categoryId: $categoryId, facet: $facet) {
      breadcrumbTrailEntryView {
        label
        value
        url
      }
      categoryId
      facets {
        ...Facets
      }
      sortData {
        ...SortData
      }
      leftRailCatNavFlag
      navParentCategoryId
      name
      popularSearches {
        name
        url
      }
      title
      searchURL
      url
    }
  }
`;

export {
  CATEGORY_PAGE_QUERY,
  CATEGORY_PAGE_BY_FACET_QUERY,
};
