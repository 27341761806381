import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { useLocalStorage } from 'react-use';
import {
  CLICKED_SUGGESTION_KEY,
} from '../../../tools/constants';
import { triggerSearchTermAnalytics } from '../../../tools/triggerAnalytics';
import { SEARCH_RESULTS_PAGE_DATA } from '../queries';

const useSearchResponse = (parameters) => {
  const [completionType] = useLocalStorage(CLICKED_SUGGESTION_KEY, []);
  const [resolvedSearchTerm, setResolvedSearchTerm] = useState(parameters.searchTerm);
  const isInitialSearch = parameters.submitMethod === 'toaster';
  const searchResponse = useQuery(SEARCH_RESULTS_PAGE_DATA, {
    variables: {
      countryFulfillmentStore: parameters.countryFulfillmentStore,
      departmentId: parameters.departmentId,
      facet: parameters.facet,
      filter: parameters.filter,
      initialSearchTerm: parameters.initialSearchTerm,
      rows: parameters.rows,
      searchTerm: parameters.searchTerm,
      sort: parameters.sort,
      start: parameters.start,
    },
    onCompleted: (searchResultsData) => {
      const {
        searchResults: {
          suggestedSearchTerm = null,
          productTotalCount: resultsCount,
        } = {},
      } = searchResultsData || {};

      setResolvedSearchTerm(suggestedSearchTerm ?? parameters.searchTerm);

      triggerSearchTermAnalytics({
        actionType: parameters.actionType,
        completionType,
        isInitialSearch,
        previousSearchterm: parameters.previousSearchterm,
        resultsCount: resultsCount || 0,
        submitMethod: parameters.submitMethod,
        searchTerm: suggestedSearchTerm,
        userInputedSearchTerm: parameters.userInputedSearchTerm || parameters.searchTerm,
      });
    },
    skip: !parameters.searchTerm,
  });

  const {
    data, previousData, loading, error,
  } = searchResponse;

  return {
    resolvedSearchTerm,
    setResolvedSearchTerm,
    data,
    previousData,
    loading,
    error,
  };
};

export default useSearchResponse;
