import { Link } from 'anf-core-react';
import PropTypes from 'prop-types';
import React from 'react';
import style from './SearchRecsItem.module.scss';

const SearchRecsItem = ({
  imageUrl, label, linkUrl, isBubbleStyle = false,
}) => (
  <Link classList={style['search-recs-link']} href={linkUrl}>
    <div className={style['search-recs-item-container']}>
      <div
        className={`${style['search-recs-item']} ${isBubbleStyle ? style['search-recs-item-bubble'] : ''}`}
        style={{ backgroundImage: `url(${imageUrl})` }}
      />
      <div className={style['search-recs-item-name']}>
        {label}
      </div>
    </div>
  </Link>
);

SearchRecsItem.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  linkUrl: PropTypes.string.isRequired,
  isBubbleStyle: PropTypes.bool,
};

export default SearchRecsItem;
