import { Button } from 'anf-core-react';
import {
  arrayOf, func, number, string,
} from 'prop-types';
import React from 'react';

export default function SuggestionButtons({
  addSuggestion = () => { },
  dupeCheckString = null,
  numberOfSuggestions = 3,
  suggestions = [],
}) {
  const buttons = [];

  suggestions.some((suggestion, index) => {
    if (suggestion === '') return false;
    if (dupeCheckString && dupeCheckString.includes(suggestion)) return false;

    buttons.push(
      <Button
        key={suggestion}
        index={index}
        onClick={addSuggestion}
        type="button"
        value={`${suggestion}`}
        variant="tertiary-light"
      >
        {suggestion}
      </Button>,
    );

    return buttons.length >= numberOfSuggestions;
  });
  return buttons;
}

SuggestionButtons.propTypes = {
  addSuggestion: func,
  dupeCheckString: string,
  numberOfSuggestions: number,
  suggestions: arrayOf(string),
};
