import { arrayOf, shape, string } from 'prop-types';
import priceType from './priceType';
import promoMessageType from './promoMessageType';

const categoryGridProductType = shape({
  badges: arrayOf(
    shape({
      text: string,
      theme: string,
    }),
  ),
  collection: string,
  defaultSwatchSequence: string,
  departmentName: string,
  gender: string,
  id: string.isRequired,
  imageSet: shape({
    modelImage: string,
    primaryFaceOutImage: string,
    primaryHoverImage: string,
    prodImage: string,
  }),
  kic: string,
  memberPrice: shape({
    description: string.isRequired,
    discountText: string,
    originalPrice: string.isRequired,
    variant: string.isRequired,
  }),
  name: string,
  partNumber: string,
  price: priceType.isRequired,
  productPageUrl: string,
  promoMessaging: promoMessageType.isRequired,
  shortDescriptors: arrayOf(string),
  socialProofMessage: string,
  swatchList: arrayOf(
    shape({
      id: string,
      name: string,
      product: shape({
        id: string.isRequired,
        imageSet: shape({
          primaryFaceOutImage: string,
        }),
      }),
      swatchImage: string,
    }),
  ),
});

export default categoryGridProductType;
