import { Icon } from 'anf-core-react';
import React, { useCallback, useEffect, useRef } from 'react';
import useTranslatedText from '../../hooks/useTranslatedText';
import $window from '../../tools/window';
import style from './scrollToTop.module.scss';

const ScrollToTop = () => {
  const btnRef = useRef();
  const labelText = useTranslatedText('scrollToTop', { fallback: 'Scroll to top' });

  const onClick = useCallback(() => {
    $window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const productGrid = document.querySelector('.product-grid__products');
    if (btnRef.current && productGrid) {
      const observer = new IntersectionObserver((entries) => {
        btnRef.current.style.visibility = entries[0].isIntersecting ? 'visible' : 'hidden';
      }, { rootMargin: `0px 0px -${$window.innerHeight}px 0px` });

      observer.observe(productGrid);

      // Cleanup function
      return () => {
        observer.disconnect();
      };
    }
  }, []);

  return (
    <button
      ref={btnRef}
      className={style.scrollToTop}
      onClick={onClick}
      type="button"
    >
      <span className="screen-reader-text">{labelText?.value}</span>
      <Icon data-aui="scroll-to-top" icon="arrow-up" />
    </button>
  );
};

export default ScrollToTop;
