import { gql } from '@apollo/client';

const FRANCHISE_DATA = gql`
  query getCountriesData {
    franchiseCountries {
      countryFromIP
      countries {
        countryCode
        name
        hasFranchisePortal
        redirectUrl
        franchiseAllowedCountries
      }
    }
  }
`;

const LDFLAGS = gql`
  query ldFlags {
    config {
      franchisePortal: genericType(name: "franchisePortal")
    }
  }
`;

export { LDFLAGS, FRANCHISE_DATA };
