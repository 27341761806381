import classNames from 'classnames';
import {
  bool, string, func, arrayOf, shape,
} from 'prop-types';
import React from 'react';
import useTranslatedText from '../../hooks/useTranslatedText';
import listStyles from '../SearchDefaultView/SearchDefaultView.module.scss';
import style from './SuggestionList.module.scss';

/**
 * SuggestionList component
 * @param {Object} props
 * @param {Number} props.listPosition - current higlighted position in the list
 * @param {Array} props.suggestions - list of suggestions
 * @param {Function} props.setSearchTerm - function to set search term
 * @param {Function} props.submitSearchTerm - function to submit search term
 * @returns {JSX.Element}
 */
export default function SuggestionList({
  blurHandler = () => {},
  onSearchTermChange = () => {},
  showAutoSuggest = false,
  suggestions = [],
}) {
  const headingLabel = useTranslatedText('autoSuggestSuggestions', { fallback: 'Suggestions' });

  const list = suggestions.map((suggestion) => {
    const name = suggestion?.name;
    const listItemSubmit = (event) => {
      if (event.type === 'click' || (event.key === 'Enter')) {
        onSearchTermChange({
          value: suggestion?.suggestedTerm || name,
          submitMethod: { trigger: 'suggestionList' },
          departmentId: suggestion?.departmentIdentifier || '',
        });
        blurHandler();
      }
    };

    return (
      <li
        key={name}
        aria-label={name}
        aria-selected={false}
        className="suggestion-list-item"
        data-value={name}
        onClick={listItemSubmit}
        onKeyDown={listItemSubmit}
        role="option"
        tabIndex={0}
      >
        <span aria-hidden="true" className="search-item">
          <span className="list-item-content">
            <span className="auto-suggest-list-item-label">
              {name}
            </span>
          </span>
        </span>
      </li>
    );
  });

  return (
    <div
      className={classNames(
        style.editableSearchContainer,
        { [style.open]: showAutoSuggest },
        listStyles.sectionWrapper,
        listStyles.listWrapper,
      )}
      data-testid="suggestion-list-wrapper"
    >
      <div
        className={classNames(
          listStyles.listContainer,
          'auto-suggest-list-menu-wrapper',
          'fixed-list-container',
        )}
        data-testid="auto-suggest-list-menu-wrapper"
      >
        <h2 className="h3">{headingLabel.value}</h2>
        <ul
          className="auto-suggest-list-menu"
        >
          {list}
        </ul>
      </div>
    </div>
  );
}

SuggestionList.propTypes = {
  blurHandler: func,
  showAutoSuggest: bool,
  onSearchTermChange: func,
  suggestions: arrayOf(shape({
    name: string,
  })),
};
