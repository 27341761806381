import classNames from 'classnames';
import {
  any,
  arrayOf,
  bool,
  func,
  number,
  objectOf,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import React, {
  useContext,
  useRef,
} from 'react';
import { useUpdateEffect, useClickAway } from 'react-use';
import StoreDetailsContext from '../../context/StoreDetailsContext';
import useDigitalData from '../../hooks/useDigitalData';
import useTranslatedText from '../../hooks/useTranslatedText';
import { AUTOSUGGEST_THRESHOLD, DEPARTMENT_ID_US_ANF_WOMENS, FACET_DICTIONARY } from '../../tools/constants';
import { getIndividualFacet } from '../../tools/getIndividualFacet';
import {
  DD_DISCOVER_BY_FACET_TEST,
  DD_TAP_TO_DISCOVER,
} from '../DigitalDataProvider';
import DiscoverByFacet from '../DiscoverByFacet/DiscoverByFacet';
import RefineButton from '../Refine/RefineButton';
import SearchInputField from '../SearchInputField/SearchInputField';
import SuggestedFilters from '../SuggestedFilters/SuggestedFilters';
import style from './EditableSearchHeader.module.scss';
import SuggestionList from './SuggestionList';
import useAutoSuggest from './hooks/useAutoSuggest';
// import useFeatureFlags from './hooks/useFeatureFlags';
import useSearchBarState from './hooks/useSearchBarState';

export default function EditableSearchHeader({
  departmentId = '',
  facet = [],
  facetData = [],
  filter = '',
  isDesktop = false,
  isFacetSelected = false,
  handleStoreFilter = () => { },
  onCheckBoxChange = () => { },
  onClearAllBtnClick = () => { },
  onFacetToasterChange = () => { },
  onSearchTermChange = () => { },
  onSortChange = () => { },
  resultsCount = null,
  searchTerm = '',
  suggestionObject = {
    words: [],
    phrases: [],
  },
  selectedSort = '',
  setResolvedSearchTerm = () => { },
  showStickyBar = false,
  sortData = {
    sortOptions: [],
  },
  storeDetails = {},
}) {
  // Having Issues with Cached values coming back unexpected Turning this feature off
  // commenting out and reverting the use of useDigitalData to enable this feature again
  // until we can figure out the issue
  // feature flags
  // const {
  //   tapToDiscoverAttributes,
  // } = useFeatureFlags();

  const {
    brand,
  } = useContext(StoreDetailsContext);

  const wrapper = useRef(null);
  const searchInputField = useRef(null);
  const {
    addSuggestion,
    blurHandler,
    clearSearchterm,
    clickedButtons,
    editableSearchTerm,
    focusHandler,
    isFocused,
    removeSuggestion,
    setEditableSearchTerm,
    submitSearchTerm,
  } = useSearchBarState(
    onSearchTermChange,
    searchInputField,
    searchTerm,
    wrapper,
    setResolvedSearchTerm,
  );
  const {
    displayedData, keyDownHandler,
  } = useAutoSuggest(
    blurHandler,
    editableSearchTerm,
  );
  const sizeFilters = getIndividualFacet(facetData, 'sizes');
  const lengthFilters = getIndividualFacet(facetData, 'lengths');
  const fitFilters = getIndividualFacet(facetData, 'fit');
  const {
    [DD_DISCOVER_BY_FACET_TEST]: hasDiscoverByFacetTestEnabled,
    [DD_TAP_TO_DISCOVER]: tapToDiscoverAttributes,
  } = useDigitalData([
    DD_DISCOVER_BY_FACET_TEST,
    DD_TAP_TO_DISCOVER,
  ]);

  const showDiscoverByFacet = hasDiscoverByFacetTestEnabled && !isDesktop;

  const showSuggestions = ((searchTerm.split(' ').length < 4 && suggestionObject?.rankedWords?.words?.length > 0)
    || clickedButtons?.size > 0);

  const showTapToDiscover = tapToDiscoverAttributes && showSuggestions
    && departmentId === DEPARTMENT_ID_US_ANF_WOMENS;

  const showAutoSuggest = (
    !showStickyBar
    && isFocused
    && editableSearchTerm.length >= AUTOSUGGEST_THRESHOLD
    && displayedData?.length > 0);

  useClickAway(wrapper, () => {
    blurHandler();
  });

  useUpdateEffect(() => {
    if (showStickyBar) {
      wrapper.current.parentElement.classList.add(style.sticky);
    } else {
      wrapper.current.parentElement.classList.remove(style.sticky);
    }
  }, [showStickyBar]);

  const onEditableSearchTermChange = (changedTermObj) => {
    setEditableSearchTerm(changedTermObj.value);
    onSearchTermChange(changedTermObj);
  };

  const clearLabel = useTranslatedText('autoSuggestClearButtonLabel', { fallback: 'Clear' });
  const placeholder = useTranslatedText('autoSuggestDesktopPlaceholder', { fallback: 'Search' });

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <form
      ref={wrapper}
      className={classNames(
        'scope-1892',
        style.editableSearchHeader,
        { [style.resizeForSuggestions]: showTapToDiscover },
      )}
      onFocus={focusHandler}
      onKeyDown={keyDownHandler}
      onSubmit={submitSearchTerm}
    >
      <fieldset>
        <SearchInputField
          ref={searchInputField}
          autoComplete="off"
          blurHandler={blurHandler}
          buttonLabelText={placeholder.value}
          clearButtonLabelText={clearLabel.value}
          clearButtonOnClick={() => {
            setEditableSearchTerm('');
            clearSearchterm();
          }}
          describedBy="editable-search-description"
          id="editable-search-input-field"
          isClearButton={editableSearchTerm.length > 0}
          isFocused={isFocused}
          labelText={placeholder.value}
          name="searchTerm"
          onChange={(event) => {
            setEditableSearchTerm(event.currentTarget.value);
          }}
          placeholder={placeholder.value}
          searchButtonOnClick={submitSearchTerm}
          value={editableSearchTerm}
        />
        <RefineButton
          brand={brand}
          facet={facet}
          facetData={facetData}
          filter={filter}
          handleStoreFilter={handleStoreFilter}
          hasIconOnly
          isFacetSelected={isFacetSelected}
          onCheckBoxChange={onCheckBoxChange}
          onClearAllBtnClick={onClearAllBtnClick}
          onSortChange={onSortChange}
          resultsCount={resultsCount}
          selectedSort={selectedSort}
          sortData={sortData}
          storeDetails={storeDetails}
        />
        {showAutoSuggest && (
          <SuggestionList
            blurHandler={blurHandler}
            onSearchTermChange={onEditableSearchTermChange}
            showAutoSuggest={showAutoSuggest}
            suggestions={displayedData}
          />
        )}
      </fieldset>
      <fieldset className={style.suggestedFilterBar}>
        {showDiscoverByFacet
          ? (
            <>
              {sizeFilters.filters?.length > 0 && (
                <DiscoverByFacet
                  appliedFacets={facet}
                  departmentId={departmentId}
                  facetId={sizeFilters.facetId}
                  facetName={sizeFilters.facetName}
                  filter={filter}
                  onFacetToasterChange={onFacetToasterChange}
                  options={sizeFilters.filters}
                  resultsCount={resultsCount}
                  searchTerm={searchTerm}
                />
              )}

              {lengthFilters.filters?.length > 0 && (
                <DiscoverByFacet
                  appliedFacets={facet}
                  departmentId={departmentId}
                  facetId={lengthFilters.facetId}
                  facetName={lengthFilters.facetName}
                  filter={filter}
                  onFacetToasterChange={onFacetToasterChange}
                  options={lengthFilters.filters}
                  resultsCount={resultsCount}
                  searchTerm={searchTerm}
                />
              )}

              {fitFilters.filters?.length > 0 && (
                <DiscoverByFacet
                  appliedFacets={facet}
                  departmentId={departmentId}
                  facetId={fitFilters.facetId}
                  facetName={fitFilters.facetName}
                  filter={filter}
                  onFacetToasterChange={onFacetToasterChange}
                  options={fitFilters.filters}
                  resultsCount={resultsCount}
                  searchTerm={searchTerm}
                />
              )}
            </>
          )
          : null}
        <SuggestedFilters
          addSuggestion={addSuggestion}
          appliedFacets={facet}
          clickedSuggestions={clickedButtons}
          dbfFilters={{
            [FACET_DICTIONARY.sizes]: sizeFilters,
            [FACET_DICTIONARY.lengths]: lengthFilters,
            [FACET_DICTIONARY.fit]: fitFilters,
          }}
          hasDiscoverByFacet={showDiscoverByFacet}
          onFacetToasterChange={onFacetToasterChange}
          removeSuggestion={removeSuggestion}
          searchTerm={searchTerm}
          showTapToDiscover={showTapToDiscover}
          suggestionObject={suggestionObject}
        />
      </fieldset>
    </form>
  );
}

EditableSearchHeader.propTypes = {
  departmentId: string,
  facet: oneOfType([
    arrayOf(string),
    string,
  ]),
  facetData: arrayOf(objectOf(any)),
  filter: string,
  isDesktop: bool,
  isFacetSelected: bool,
  handleStoreFilter: func,
  showStickyBar: bool,
  onCheckBoxChange: func,
  onClearAllBtnClick: func,
  onFacetToasterChange: func,
  onSortChange: func,
  onSearchTermChange: func,
  resultsCount: number,
  searchTerm: string,
  suggestionObject: shape({
    rankedWords: shape({
      words: arrayOf(string),
      wordObjects: arrayOf(shape({
        word: string,
        count: number,
      })),
    }),
    rankedPhrases: shape({
      phrases: arrayOf(string),
      phraseObjects: arrayOf(shape({
        phrase: string,
        count: number,
      })),
    }),
  }),
  selectedSort: string,
  setResolvedSearchTerm: func,
  sortData: shape({
    sortOptions: arrayOf(shape({
      id: string,
      value: string,
    })),
  }),
  storeDetails: shape({
    storeName: string,
    storeId: string,
  }),
};
