import { useQuery } from '@apollo/client';
import { STORE_ATTRIBUTES } from '../queries';

const useFeatureFlags = () => {
  const { data: storeAttributeData } = useQuery(STORE_ATTRIBUTES);

  const departmentSuggestionsFlag = storeAttributeData?.config?.noResultsDepartmentSuggestions?.value ?? false; // eslint-disable-line max-len
  const hasShopMyStoreEnabled = storeAttributeData?.config?.hasShopMyStoreEnabled?.value ?? false;

  return {
    departmentSuggestionsFlag,
    hasShopMyStoreEnabled,
  };
};

export default useFeatureFlags;
