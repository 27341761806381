import { MicroFrontend } from '@xp-utilities/web';
import React, { useContext } from 'react';
import BreakpointContext from '../../context/breakpoint';
import parsePlacementIds from '../../tools/parsePlacementIds';
import withBreakpointProvider from '../withBreakpointProvider/withBreakpointProvider';

/**
 * Chunk out this JSX to its own component so that we can wrap it in BreakpointProvider and use
 * BreakpointContext within this component rather than within an ancestor component to prevent
 * unnecessary re-renders of the latter on context value changes
 */
const CustomerRecommendationsMFE = withBreakpointProvider(() => {
  const breakpoints = useContext(BreakpointContext);
  const placements = parsePlacementIds(
    'category_page.rr1|category_page.rr2|category_page.rr3|category_page.rr4|category_page.rr5|category_page.rr6|category_page.m_rr1|category_page.m_rr2|category_page.m_rr3|category_page.m_rr4|category_page.m_rr5|category_page.m_rr6',
    breakpoints,
  );

  return placements.length > 0 ? (
    <MicroFrontend
      client="recommendations"
      mfe="CUSTOMER"
      queryString={`clientId=category-1&placements=${placements}`}
    />
  ) : null;
});

export default CustomerRecommendationsMFE;
