/**
 * Returns provided URL string with provided query parameters appended
 *
 * @param {string} url - URL string to append query parameters to
 * @param {Object} [parameters={}] - Object of query parameters to append to url
 * @returns {string} URL string with appended query parameters, or original URL string if URL is
 * falsy or if parameters is empty
 * @example
 * // Returns '/shop/us/mens?pagefm=navigation-breadcrumbs'
 * addUrlParameters('/shop/us/mens', { pagefm: 'navigation-breadcrumbs' });
 * @example
* // Returns 'https://www.abercrombie.com/shop/us/p/90s-jeans?categoryId=12203&faceout=model&seq=99&pagefm=navigation-grid&prodvm=navigationgrid'
 * addUrlParameters('https://www.abercrombie.com/shop/us/p/90s-jeans?categoryId=12203&faceout=model&seq=99', { pagefm: 'navigation-grid', prodvm: 'navigation-grid' });
 */
const addUrlParameters = (url, parameters = {}) => {
  if (!url) {
    return url;
  }

  const parametersString = Object.entries(parameters)
    .reduce((string, [k, v], i) => `${string}${i > 0 ? '&' : ''}${k}=${v}`, '');

  if (!parametersString) {
    return url;
  }

  return `${url}${url.includes('?') ? '&' : '?'}${parametersString}`;
};

export default addUrlParameters;
