import { useEffectOnce, useSessionStorage } from 'react-use';
import $window from '../tools/window';

const useScrollToProduct = () => {
  const [productToFocus, setProductToFocus] = useSessionStorage('productToFocus', {});

  // eslint-disable-next-line consistent-return
  useEffectOnce(() => {
    if ($window.history?.state?.productToFocus === 'true' && productToFocus?.productId) {
      const focusElement = document.querySelector(`[data-intlkic=${productToFocus.productId}] .catalog-productCard-module__product-image-section a`);
      setProductToFocus({ source: 'focusProduct' });

      if ('scrollRestoration' in $window.history) {
        $window.history.scrollRestoration = 'manual';
      }
      if (focusElement) {
        const triggerFocusOnelement = () => focusElement.focus();
        window.addEventListener('load', triggerFocusOnelement);
        return () => window.removeEventListener('load', triggerFocusOnelement);
      }
    } else {
      setProductToFocus(null);
    }
  });
};

export default useScrollToProduct;
