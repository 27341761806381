import Icon from 'anf-core-react/components/Icon';
import classNames from 'classnames';
import propTypes from 'prop-types';
import React, { useState } from 'react';
import style from './BrandTabs.module.scss';

function BrandTab({
  label,
  url,
  brand,
  icons,
  selected = false,
}) {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <li className={classNames(
      style.tab,
      style[`brand-${brand}`],
      { [style.current]: selected },
    )}
    >
      { /* eslint-disable jsx-a11y/control-has-associated-label */ }
      <a
        aria-current={selected ? 'page' : null}
        className={style.link}
        data-aui={`brand-tab-${brand}`}
        href={url}
        onBlur={() => setIsHovered(false)}
        onFocus={() => setIsHovered(true)}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Icon
          icon={isHovered ? icons.hoverIcon : icons.defaultIcon}
          labelText={label}
        />
      </a>
    </li>
  );
}

BrandTab.propTypes = {
  label: propTypes.string.isRequired,
  url: propTypes.string.isRequired,
  brand: propTypes.string.isRequired,
  icons: propTypes.shape({
    defaultIcon: propTypes.string.isRequired,
    hoverIcon: propTypes.string.isRequired,
  }).isRequired,
  selected: propTypes.bool,
};

export default BrandTab;
