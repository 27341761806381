import { gql } from '@apollo/client';

const LDFLAGS = gql`
  query ldFlags {
    config {
      searchFlyoutAlgonomySuggestions: genericType(name: "searchFlyoutAlgonomySuggestions")
    }
  }
`;

export default LDFLAGS;
