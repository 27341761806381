import PropTypes from 'prop-types';
import React from 'react';
import useTranslatedText from '../../hooks/useTranslatedText';
import TmntHtml from '../../tools/TmntHtml';
import FacetAccordions from './FacetsAccordionGroup';
import style from './FacetsLeftRail.module.scss';

function FacetsLeftRail({
  brand,
  facet = [],
  facetData = [],
  isCategoryPage = false,
  onCheckBoxChange = () => {},
  popularSearches = [],
}) {
  const filterLabel = useTranslatedText('filters', { fallback: 'Filters' });
  const filterInstructionLabel = useTranslatedText('filterInstruction',
    { fallback: 'The following options filter your results as you apply each one.' });

  return (
    <>
      <h2 className="screen-reader-text">
        <TmntHtml property={filterInstructionLabel.key || ''} value={filterInstructionLabel.value || ''} />
      </h2>
      <div aria-label={filterLabel.value} className={`${style.wrapper} Filters`} data-testid="filters-wrapper" role="region" tabIndex="-1">
        <FacetAccordions
          brand={brand}
          facet={facet}
          facetData={facetData}
          isCategoryPage={isCategoryPage}
          onCheckBoxChange={onCheckBoxChange}
          popularSearches={popularSearches}
        />
      </div>
    </>
  );
}

FacetsLeftRail.propTypes = {
  // Required props
  brand: PropTypes.string.isRequired,
  facetData: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  // Optional props
  isCategoryPage: PropTypes.bool,
  facet: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  onCheckBoxChange: PropTypes.func,
  popularSearches: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
};

export default FacetsLeftRail;
