import PropTypes from 'prop-types';
import React from 'react';
import style from './SearchRecommendations.module.scss';
import SearchRecsItem from './SearchRecsItem';
import useLDPageRecsJson from './hooks/useLDPageRecsJson';

const SearchRecommendations = ({
  recsObjectArray = [],
  recsTitle,
  isBubbleStyle = false,
}) => {
  /*
    Call our custom hook to get the JSON data from LaunchDarkly, but pass in the
    recs array that we already have (it was optionally passed in to our current component).
    This will be used to determine if we should skip the actual query to LaunchDarkly.
  */

  const recInfoObjects = useLDPageRecsJson({ existingRecsArray: recsObjectArray });

  return (
    recInfoObjects.length > 0
    && (
      <>
        <div className={style['search-recs-outer-container']}>
          { recsTitle ? <h2 className="h3">{recsTitle}</h2> : null }
          <div className={style['search-recs-inner-container']}>
            {recInfoObjects.map((rec) => (
              <SearchRecsItem
                key={rec.label}
                imageUrl={rec.imageUrl}
                isBubbleStyle={isBubbleStyle}
                label={rec.label}
                linkUrl={rec.linkUrl}
              />
            ))}
          </div>
        </div>
      </>
    )
  );
};

export default SearchRecommendations;

SearchRecommendations.propTypes = {
  recsObjectArray: PropTypes.arrayOf(
    PropTypes.shape({
      imageUrl: PropTypes.string,
      label: PropTypes.string,
      linkUrl: PropTypes.string,
    }),
  ),
  recsTitle: PropTypes.string,
  isBubbleStyle: PropTypes.bool,
};
