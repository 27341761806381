import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import ProductGridContext from '../../context/ProductGridContext';
import BreakpointContext from '../../context/breakpoint';
import DigitalDataContext from '../../context/digitalData';
import useFetchMySaves from '../../hooks/useFetchMySaves';
import { SSR_IMAGE_COUNT } from '../../tools/constants';
import getPageType from '../../tools/getPageType';
import getEspots from '../../tools/updateProductsWithEspots';
import $window from '../../tools/window';
import CmcEspotCard from '../CmcEspotCard';
import { DD_DISABLE_SWATCH_HOVER, DD_HYPERLINK_DESC, DD_MODEL_IMAGERY_TEST } from '../DigitalDataProvider';
import EspotCard from '../EspotCard/EspotCard';
import ProductCard from '../ProductCard';
import ProductGridContextProvider from '../ProductGridContextProvider';
import { productType } from '../types';
import productCardClickBehaviorType, { CLICK_BEHAVIORS } from '../types/productCardClickBehaviorType';
import ProductGrid from './ProductGrid';

function FullPageGrid({
  categoryId = undefined,
  espotContent = [],
  espotIdentifier = undefined,
  fullCatHead = false,
  productCardClickBehavior = CLICK_BEHAVIORS.productPage,
  products = null,
  shouldSetVisitedProduct = false,
}) {
  const { large: isDesktop } = useContext(BreakpointContext);
  const { brand, imageHostName, noImageFoundImgUrl } = useContext(ProductGridContext);
  const {
    [DD_DISABLE_SWATCH_HOVER]: hasSwatchHover,
    [DD_HYPERLINK_DESC]: hasHyperlinkShortDescriptor,
    [DD_MODEL_IMAGERY_TEST]: hasModelImagery,
  } = useContext(DigitalDataContext);

  const hasProdImagery = hasModelImagery === 'prod';
  const [hadSwatchInteraction, setHadSwatchInteraction] = useState(false);

  useEffect(() => {
    const pageData = { products };
    if (categoryId) {
      pageData.categoryId = categoryId;
    }
    if ($window.digitalData) {
      const pageType = getPageType();
      $window.digitalData.merge(`${pageType}`, { ...pageData });
      if (pageType === 'search') {
        $window.digitalData.trigger('mfe-search-page-mounted');
      }
    }
  }, [categoryId, products]);

  const { mySavesItems, refetch } = useFetchMySaves();

  const espotData = espotContent ? getEspots(espotContent) : null;

  const updateProductsWithGridLineEspots = (productsArray) => {
    const MIDLINE_POSITION = 9;
    const inlineKey = `${espotIdentifier}-inline`;
    const midlineKey = `${espotIdentifier}-midline`;
    const endlineKey = `${espotIdentifier}-endline`;
    const fullCatHeadKey = `${espotIdentifier}-full-cat-head`;
    const headerKey = `${categoryId}-FullCatHeadEMS`;

    productsArray.unshift(
      fullCatHead ? <EspotCard key={fullCatHeadKey} content={(espotData) ? espotData.get(fullCatHeadKey) : ''} fullCatHead={fullCatHead} name={fullCatHeadKey} />
        : <CmcEspotCard key={headerKey} content={(espotData) ? espotData.get(headerKey) : ''} name={headerKey} />,
      <EspotCard key={inlineKey} content={(espotData) ? espotData.get(inlineKey) : ''} name={inlineKey} />,
    );

    if (productsArray.length > MIDLINE_POSITION) {
      productsArray.splice(
        MIDLINE_POSITION,
        0,
        <EspotCard key={midlineKey} content={(espotData) ? espotData.get(midlineKey) : ''} name={midlineKey} />,
      );
    }
    productsArray.push(
      <EspotCard key={endlineKey} content={(espotData) ? espotData.get(endlineKey) : ''} name={endlineKey} />,
    );
    return productsArray;
  };

  const updateProductsWithEspots = () => {
    const productsArray = [];
    products.forEach((product, index) => {
      // defer loading to client-side for images likely to be outside initial viewport
      const deferImageLoad = (index + 1) > SSR_IMAGE_COUNT;
      const productInlineEspotName = `${product.partNumber}-ProductInline`;
      productsArray.push(<ProductCard
        key={product.id}
        brand={brand}
        clickBehavior={productCardClickBehavior}
        deferImageLoad={deferImageLoad}
        hadSwatchInteraction={hadSwatchInteraction}
        hasHyperlinkShortDescriptor={hasHyperlinkShortDescriptor}
        hasModelImagery={hasModelImagery === 'model'}
        hasProdImagery={hasProdImagery}
        hasSwatchHover={hasSwatchHover}
        imageHostName={imageHostName}
        isDesktop={isDesktop}
        isFirstProduct={index === 0}
        mySavesItems={mySavesItems}
        noImageFoundImgUrl={noImageFoundImgUrl}
        onSwatchInteraction={() => setHadSwatchInteraction(true)}
        product={product}
        refetch={refetch}
        shouldSetVisitedProduct={shouldSetVisitedProduct}
      />);
      if (espotData && espotData.has(productInlineEspotName)) {
        productsArray.push(
          <EspotCard
            key={productInlineEspotName}
            content={espotData.get(productInlineEspotName)}
            name={productInlineEspotName}
          />,
        );
      }
    });

    return (categoryId)
      ? updateProductsWithGridLineEspots(productsArray)
      : productsArray;
  };
  return (
    <ProductGridContextProvider>
      <ProductGrid>
        {updateProductsWithEspots()}
      </ProductGrid>
    </ProductGridContextProvider>
  );
}

FullPageGrid.propTypes = {
  categoryId: PropTypes.string,
  espotIdentifier: PropTypes.string,
  espotContent: PropTypes.arrayOf(PropTypes.shape({
    content: PropTypes.string,
    espotId: PropTypes.string,
  })),
  fullCatHead: PropTypes.bool,
  productCardClickBehavior: productCardClickBehaviorType,
  products: PropTypes.arrayOf(productType),
  shouldSetVisitedProduct: PropTypes.bool,
};

export default FullPageGrid;
