import { gql } from '@apollo/client';
import suggestionData from '../../../queries/fragments/SuggestedItem';

export const COMPLETIONS_V3 = gql`
${suggestionData}
query Suggestion($searchTerm: String, $genderTabsFlag: Boolean) {
  products: suggestion(searchTerm: $searchTerm, type: "products", genderTabsFlag: $genderTabsFlag) {
    suggestedItems {
      ...SuggestionData
      imageId
    }
  }
  productTypes: suggestion(searchTerm: $searchTerm, type: "productTypes", genderTabsFlag: $genderTabsFlag) {
    suggestedItems {
      ...SuggestionData
    }
  }
}
`;

export const GENDER_PRESERVATION = gql`
  query GenderPreservation {
    config {
      hasGenderPreservationEnabled: genericType(name: "hasGenderPreservationEnabled")
    }
  }
`;

export const CONFIG_DATA = gql`
  query storeAttributes {
    config {
      hasMFEMobileNavToaster: genericType(name: "hasMFEMobileNavToaster")
      assistiveSearchNav: nonCachedGenericType(name: "assistiveSearchNav")
      searchFlyoutRecsMigration: genericType(name: "searchFlyoutRecsMigration")
    }
  }
`;

export const GENDERLESS_SEARCH = gql`
  query GenderLessSearch {
    config {
      genderLessSearch: genericType(name: "genderLessSearch")
    }
  }
`;
