import { useState } from 'react';
import { useWindowSize, useIntersection } from 'react-use';

function useStickyIntersection(elementRef, offset = 0) {
  const { height } = useWindowSize();
  // need to set initial window height to avoid flickering on safari
  const [initWindowHeight] = useState(height);
  const intersection = useIntersection(elementRef, {
    rootMargin: `${offset}px 0px -${initWindowHeight - offset}px`,
  });

  return intersection?.isIntersecting;
}

export default useStickyIntersection;
