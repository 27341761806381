import { gql } from '@apollo/client';
import productsFragment from '../../../queries/fragments/Products';

export const SIMILAR_GRID_RESULTS = gql`
  ${productsFragment}
  query SimilarGridSearchResults(
    $countryFulfillmentStore: String,
    $departmentId: String,
    $facet: [String],
    $rows: String!,
    $searchTerm: String!,
    $start: String!,
  ) {
    searchResults(
      countryFulfillmentStore: $countryFulfillmentStore,
      departmentId: $departmentId,
      facet: $facet,
      rows: $rows,
      searchTerm: $searchTerm,
      start: $start,
    ) {
      brand
      products {
        ...Products
      }
      productTotalCount
    }
  }
`;

export const SIMILAR_CATEGORY_URL = gql`
query SimilarCategoryURL($categoryId: String!) {
  category(categoryId: $categoryId) {
      url
    }
  }
`;

export const SIMILAR_PRODUCT_DATA = gql`
  query ShopSimilarProductDetail($productId: String!) {
    product(productId: $productId) {
      name
      shopSimilarTerms {
        id
        style
        color
        pdpStyle
      }
    }
  }
`;
export const SIMILAR_SLIDER_RESULTS = gql`
  ${productsFragment}
  query Search(
    $countryFulfillmentStore: String,
    $departmentId: String,
    $facet: [String],
    $rows: String,
    $searchTerm: String!,
  ) {
    searchResults(
      countryFulfillmentStore: $countryFulfillmentStore,
      departmentId: $departmentId,
      facet: $facet,
      rows: $rows,
      searchTerm: $searchTerm,
    ) {
      brand
      products {
        ...Products
      }
      searchURL
    }
  }
`;
