import { RecommendationsContext, useUserConsent } from '@xp-utilities/web';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

export default function RecommendationsContextProvider({ id, children }) {
  const { getUserConsent } = useUserConsent();

  const ctx = useMemo(
    () => ({
      id,
      isEventDriven: false,
      variables: {
        hasUserConsent: getUserConsent(),
      },
    }),
    [id, getUserConsent],
  );

  return (
    <RecommendationsContext.Provider value={ctx}>
      { children }
    </RecommendationsContext.Provider>
  );
}

RecommendationsContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
};
