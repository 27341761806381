import PropTypes from 'prop-types';
import React from 'react';
import SearchResultsContext from '../../context/SearchResultsContext';
import {
  EDITABLE_SEARCH_SCROLL_OFFSET,
  PLACEMENT_IDS,
} from '../../tools/constants';
import handleAlgonomyProductClick from '../../tools/handleAlgonomyProductClick';
import BreakpointProvider from '../BreakpointProvider';
import DigitalDataProvider, {
  DD_DISABLE_SWATCH_HOVER,
  DD_HYPERLINK_DESC,
  DD_MODEL_IMAGERY_TEST,
} from '../DigitalDataProvider';
import ProductGridScrollHandler from '../ProductGridScrollHandler';
import Recommendations from '../Recommendations/Recommendations';
import recsStyles from '../Recommendations/recommendations.module.scss';
import { usePopularSearches } from '../SearchBox/hooks/useQueries';
import SearchDefaultView from '../SearchDefaultView';
import SearchGridWrapper from './SearchGridWrapper';

const SearchMainContent = ({
  elasticDepartmentData = null,
  facets = [],
  handleCheckBoxChange,
  handleSortChange,
  handleStoreFilter,
  onClearAllBtnClick,
  onClearFacetTag = () => { },
  onDepartmentChange,
  onPaginationButtonClick = () => { },
  onSearchTermChange,
  parameters = {},
  resultsCount = 0,
  searchGridData = {},
  sortData = {},
  storeDetails = { storeName: '', storeId: '' },
}) => {
  const popularSearchesList = usePopularSearches();

  // derived state:
  const userInitiatedAsyncSearch = parameters.submitMethod === 'user-initiated';

  return (
    <BreakpointProvider>
      <DigitalDataProvider
        keys={[
          DD_DISABLE_SWATCH_HOVER,
          DD_HYPERLINK_DESC,
          DD_MODEL_IMAGERY_TEST,
        ]}
      >
        <SearchResultsContext.Provider value={{ userInitiatedAsyncSearch }}>
          <ProductGridScrollHandler
            parameters={parameters}
            scrollOffset={EDITABLE_SEARCH_SCROLL_OFFSET}
          >
            { parameters.searchTerm !== ''
              ? (
                <SearchGridWrapper
                  elasticDepartmentData={elasticDepartmentData}
                  facets={facets}
                  handleCheckBoxChange={handleCheckBoxChange}
                  handleSortChange={handleSortChange}
                  handleStoreFilter={handleStoreFilter}
                  onClearAllBtnClick={onClearAllBtnClick}
                  onClearFacetTag={onClearFacetTag}
                  onDepartmentChange={onDepartmentChange}
                  onPaginationButtonClick={onPaginationButtonClick}
                  parameters={parameters}
                  resultsCount={resultsCount}
                  searchGridData={searchGridData}
                  sortData={sortData}
                  storeDetails={storeDetails}
                />
              )
              : (
                <SearchDefaultView
                  listItemSubmitHandler={onSearchTermChange}
                  popularSearchesList={popularSearchesList}
                  wrapperId="editable-search-list"
                >
                  <Recommendations
                    clientId="search-flyout-2"
                    handleOnProductClick={handleAlgonomyProductClick}
                    placementIds={`${PLACEMENT_IDS.mobile.searchFlyout}|${PLACEMENT_IDS.desktop.searchFlyout}`}
                    styles={`${recsStyles.recsWrapper}`}
                  />
                </SearchDefaultView>
              )}
          </ProductGridScrollHandler>
        </SearchResultsContext.Provider>
      </DigitalDataProvider>
    </BreakpointProvider>
  );
};

SearchMainContent.propTypes = {
  // Required props
  // Optional props
  elasticDepartmentData: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    defaultCategoryIdBySite: PropTypes.string,
  })),
  facets: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  handleCheckBoxChange: PropTypes.func.isRequired,
  handleSortChange: PropTypes.func,
  handleStoreFilter: PropTypes.func.isRequired,
  onClearAllBtnClick: PropTypes.func.isRequired,
  onClearFacetTag: PropTypes.func,
  onDepartmentChange: PropTypes.func.isRequired,
  onPaginationButtonClick: PropTypes.func,
  onSearchTermChange: PropTypes.func.isRequired,
  parameters: PropTypes.shape({
    searchTerm: PropTypes.string,
    countryFulfillmentStore: PropTypes.string,
    departmentId: PropTypes.string,
    facet: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.string,
    ]),
    filter: PropTypes.string,
    rows: PropTypes.string,
    sort: PropTypes.string,
    start: PropTypes.string,
  }),
  searchGridData: PropTypes.shape({
    loading: PropTypes.bool,
    pagination: PropTypes.shape({
      currentPage: PropTypes.number,
      totalPages: PropTypes.number,
    }),
    products: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      price: PropTypes.shape({
        currency: PropTypes.string,
        value: PropTypes.number,
      }),
      productImage: PropTypes.shape({
        altText: PropTypes.string,
        url: PropTypes.string,
      }),
      productPageUrl: PropTypes.string,
    })),
  }),
  sortData: PropTypes.shape({
    sortOptions: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    })),
  }),
  resultsCount: PropTypes.number,
  storeDetails: PropTypes.shape({
    storeId: PropTypes.string,
    storeName: PropTypes.string,
  }),
};

export default SearchMainContent;
