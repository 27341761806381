import PropTypes from 'prop-types';
import React from 'react';
import useTranslatedText from '../../hooks/useTranslatedText';

const NavFlyoutCategoryHeader = ({
  headerPairKey,
  additionalClasses = '',
}) => {
  const header = useTranslatedText(headerPairKey);

  if (!header?.value) {
    return null;
  }

  return (
    <h3 className={`nav-category-column-header ${additionalClasses}`} data-property={header.key}>
      {header.value}
    </h3>
  );
};

NavFlyoutCategoryHeader.propTypes = {
  headerPairKey: PropTypes.string.isRequired,
  additionalClasses: PropTypes.string,
};

export default NavFlyoutCategoryHeader;
