import { useCallback } from 'react';
import $window from '../../../tools/window';
import { triggerQuickviewAnalytics } from './triggerProductAnalytics';

const useQuickViewEvent = ({
  algonomyOnClickAnalytics,
  isMiniGrid,
  categoryId,
  product: {
    collection,
    defaultSwatchSequence,
    productId,
    imageSet,
    productName,
    socialProofMessage,
  },
  brand,
  faceoutImageType,
  setIsQuickviewOpen,
}) => {
  const triggerQuickView = useCallback((event) => {
    triggerQuickviewAnalytics({
      algonomyOnClickAnalytics, categoryId, isMiniGrid, socialProofMessage,
    });

    const quickviewEvent = new CustomEvent('quickview:openModal', {
      detail: {
        productId,
        collectionId: collection,
        seq: defaultSwatchSequence,
        productName,
        imageId: imageSet.primaryFaceOutImage,
        productBrand: brand,
        faceout: faceoutImageType,
        returnFocus: event.currentTarget,
        origin: isMiniGrid ? 'mini grid' : '',
        categoryId: categoryId ?? '',
        afsource: socialProofMessage ? 'social proofing' : '',
      },
    });

    /**
     * There's a possibility that something goes wrong and the QV doesn't open,
     * which will mess up our state.
     * This is the best we can do since we're not getting any acknowledgement
     * as to whether or not the QV opened in response to our event.
     */
    setIsQuickviewOpen(true);
    try {
      $window.dispatchEvent(quickviewEvent);
    } catch (error) {
      // TODO: Log this?
      setIsQuickviewOpen(false);
    }
  }, [
    algonomyOnClickAnalytics,
    brand,
    categoryId,
    collection,
    defaultSwatchSequence,
    faceoutImageType,
    imageSet.primaryFaceOutImage,
    isMiniGrid,
    productName,
    productId,
    socialProofMessage,
    setIsQuickviewOpen,
  ]);

  return { triggerQuickView };
};

export default useQuickViewEvent;
