import { Link as LinkButton } from 'anf-core-react';
import Switch from 'anf-core-react/components/Switch';
import PropTypes from 'prop-types';
import React from 'react';
import useTranslatedText from '../../hooks/useTranslatedText';
import TmntHtml from '../../tools/TmntHtml';
import style from './StoreToggle.module.scss';

export default function StoreToggle({
  handleStoreFilter,
  isShopMyStore,
  storeDetails = null,
}) {
  const storeQueryName = [storeDetails?.storeName ?? ''];
  const changeStore = useTranslatedText('changeStore', { fallback: 'Change Store' });
  const shopMyStore = useTranslatedText('shopMyStore', { fallback: 'Shop My Store' });
  const shoppingStore = useTranslatedText('shoppingStore', { fallback: `Shopping ${storeQueryName}`, replacements: storeQueryName });
  const elementChangeWarning = useTranslatedText('elementChangeWarning', { fallback: 'Activating this element will cause content on the page to be updated.' });

  const launchChangeStoreModal = () => {
    window.dispatchEvent(new CustomEvent('mfe:changeStore'));
  };

  const handleSwitch = () => {
    if (isShopMyStore) {
      // if switch is on, turn it off
      handleStoreFilter(null);
    } else if (!storeDetails) {
      // do we have a store? If not, launch modal so we can get one
      launchChangeStoreModal();
    } else {
      // if we have a store we can turn the switch on
      handleStoreFilter(`localStoreId:${storeDetails.storeId}`);
    }
  };

  return (
    <>
      <h2 aria-live="assertive" className="screen-reader-text">
        <TmntHtml
          property={elementChangeWarning.key || ''}
          value={elementChangeWarning.value || ''}
        />
      </h2>
      <div className={style.bar} data-testid="store-toggle">
        <Switch
          id="catalog-shop-store"
          isChecked={isShopMyStore}
          onChange={handleSwitch}
        >
          {
        (storeDetails?.storeName && isShopMyStore)
          ? shoppingStore.value // "Shopping [store details store name]"
          : shopMyStore.value // "Shop My Store"
        }
        </Switch>
        {isShopMyStore && (
          <div>
            <LinkButton
              onClick={() => launchChangeStoreModal()}
            >
              {changeStore.value}
            </LinkButton>
          </div>
        )}
      </div>
    </>
  );
}

StoreToggle.propTypes = {
  handleStoreFilter: PropTypes.func.isRequired,
  isShopMyStore: PropTypes.bool.isRequired,
  storeDetails: PropTypes.shape({
    storeName: PropTypes.string,
    storeId: PropTypes.string,
  }),
};
