import { gql, useQuery } from '@apollo/client';

const HELP_LINKS = gql`
  query HelpLinks {
    helpLinks {
        id
        title
        url
    }
  }
`;

const useHelpTopics = () => {
  const { data, loading, error } = useQuery(HELP_LINKS);

  if (loading || error) return null;

  return data;
};

export default useHelpTopics;
