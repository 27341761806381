import PropTypes from 'prop-types';
import React from 'react';
import BreadcrumbBar from '../BreadcrumbBar/BreadcrumbBar';
import SingleAemEspot from '../SingleAemEspot';
import style from './CategoryPage.module.scss';

function BreadcrumbSection({ brand, categoryId, espotIdentifier = '' }) {
  return (
    <>
      {!!espotIdentifier && <SingleAemEspot espotId={`${espotIdentifier}-breadcrumb-top`} />}
      <div className={style.breadcrumbs}>
        <BreadcrumbBar brand={brand} categoryId={categoryId} />
      </div>
      {!!espotIdentifier && <SingleAemEspot espotId={`${espotIdentifier}-breadcrumb-bottom`} />}
    </>
  );
}

BreadcrumbSection.propTypes = {
  brand: PropTypes.string.isRequired,
  categoryId: PropTypes.string.isRequired,
  espotIdentifier: PropTypes.string,
};

export default BreadcrumbSection;
