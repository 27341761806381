import Container from 'anf-core-react/components/Container';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import BreakpointContext from '../../context/breakpoint';
import GridPagination from '../GridPagination/GridPagination';
import FullPageGrid from '../ProductGrid/FullPageGrid';
import SkeletonProductGrid from '../ProductGrid/SkeletonGrid';
import RecommendationSlider from '../RecommendationSlider/RecommendationSlider';
import recsContainerStyles from '../RecommendationSlider/recommendationSlider.module.scss';
import SearchErrorPage from '../SearchErrorPage';
import { CLICK_BEHAVIORS } from '../types/productCardClickBehaviorType';

function SearchGrid({
  departmentId = '',
  departmentSuggestionsFlag = false,
  elasticDepartmentData = null,
  forShopSimilar = false,
  onDepartmentChange = () => { },
  onPaginationButtonClick = () => { },
  rows = '42',
  searchGridData = {},
  searchTerm,
  start = '0',
}) {
  const { large: isDesktop } = useContext(BreakpointContext);
  const {
    loading, products, pagination, error,
  } = searchGridData;

  if (loading) return <SkeletonProductGrid cardAmount={12} />;

  return (
    <div className="search-grid-wrapper">
      {products?.length > 0
        ? (
          <>
            <FullPageGrid
              productCardClickBehavior={forShopSimilar && !isDesktop
                ? CLICK_BEHAVIORS.quickview : CLICK_BEHAVIORS.productPage}
              products={products}
            />
            <GridPagination
              currentPage={pagination?.currentPage}
              onButtonClick={onPaginationButtonClick}
              rows={rows}
              start={start}
              totalPages={pagination?.totalPages}
            />
            <Container
              key="recommendation-slider-search-results-page"
            >
              <RecommendationSlider
                placementId="search_page.m_rr1|search_page.rr1"
                styles={recsContainerStyles.searchPageRecsWrapper}
              />
            </Container>
          </>
        )
        : (
          <SearchErrorPage
            departmentId={departmentId}
            departmentSuggestionsFlag={departmentSuggestionsFlag}
            elasticDepartmentData={elasticDepartmentData}
            error={error}
            onDepartmentChange={onDepartmentChange}
            searchTerm={searchTerm}
          />
        )}
    </div>
  );
}

SearchGrid.propTypes = {
  departmentId: PropTypes.string,
  departmentSuggestionsFlag: PropTypes.bool,
  elasticDepartmentData: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    defaultCategoryIdBySite: PropTypes.string,
  })),
  forShopSimilar: PropTypes.bool,
  onPaginationButtonClick: PropTypes.func.isRequired,
  rows: PropTypes.string,
  searchGridData: PropTypes.shape({
    loading: PropTypes.bool,
    pagination: PropTypes.shape({
      currentPage: PropTypes.number,
      totalPages: PropTypes.number,
    }),
    products: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      price: PropTypes.shape({
        currency: PropTypes.string,
        value: PropTypes.number,
      }),
      productImage: PropTypes.shape({
        altText: PropTypes.string,
        url: PropTypes.string,
      }),
      productPageUrl: PropTypes.string,
    })),
  }),
  searchTerm: PropTypes.string.isRequired,
  onDepartmentChange: PropTypes.func,
  start: PropTypes.string,
};

export default SearchGrid;
