import $window from '../../../tools/window';
import { triggerHoverAnalytics } from './triggerProductAnalytics';

const useSwatchHover = ({
  hasSwatchHover,
  hoveredSwatchId,
  setHoveredSwatch,
  hadSwatchInteraction,
  onSwatchInteraction,
}) => {
  const handleSwatchHover = (newHoveredSwatch) => {
    const isDifferentSwatch = newHoveredSwatch?.id !== hoveredSwatchId;

    // Only update state if there is a new swatch
    // This is needed because object comparison (swatch vs swatch) is shallow
    if (!hasSwatchHover && isDifferentSwatch) {
      setHoveredSwatch(newHoveredSwatch);
    }

    if (
      $window.digitalData
      && newHoveredSwatch
      && isDifferentSwatch
      && !hadSwatchInteraction
    ) {
      triggerHoverAnalytics();
      onSwatchInteraction();
    }
  };

  return { handleSwatchHover };
};

export default useSwatchHover;
