import { gql } from '@apollo/client';

const SEARCH_RESULTS_STATS_DATA = gql`
query Search(
  $departmentId: String,
  $searchTerm: String!,
  $rows: String,
  ) {
  searchResults(
    departmentId: $departmentId,
    searchTerm: $searchTerm,
    rows: $rows,
  ) {
      productTotalCount
    }
}
`;

export default SEARCH_RESULTS_STATS_DATA;
