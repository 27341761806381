import PropTypes from 'prop-types';
import React from 'react';
import StoreDetailsProvider from '../StoreDetailsProvider/StoreDetailsProvider';
import SearchWrapper from './SearchWrapper';

function SearchDataProvider({
  brand = '',
  searchUrl: intlSearchUrl = '',
  store = '',
  storeId = '',
}) {
  return (
    <StoreDetailsProvider store={store} storeId={storeId}>
      <SearchWrapper
        brand={brand}
        searchUrl={intlSearchUrl}
      />
    </StoreDetailsProvider>
  );
}

SearchDataProvider.propTypes = {
  brand: PropTypes.string,
  searchUrl: PropTypes.string,
  store: PropTypes.string,
  storeId: PropTypes.string,
};

export default SearchDataProvider;
