import {
  Logo,
  Modal,
  Toaster,
} from 'anf-core-react';
import PropTypes from 'prop-types';
import React, {
  useState,
} from 'react';
import { useSearchParam } from 'react-use';
import useTranslatedText from '../../hooks/useTranslatedText';
import { PLACEMENT_IDS } from '../../tools/constants';
import handleAlgonomyProductClick from '../../tools/handleAlgonomyProductClick';
import $window from '../../tools/window';
import BreakpointProvider from '../BreakpointProvider';
import RecommendationSlider from '../RecommendationSlider/RecommendationSlider';
import Recommendations from '../Recommendations/Recommendations';
import recsStyles from '../Recommendations/recommendations.module.scss';
import SearchBox from '../SearchBox/SearchBox';
import style from '../SearchBox/searchWrapper.module.scss';
import SearchRecommendations from '../SearchRecommendations';

// Need this for a DS bug where the onOpen event is called even when the toaster is closed
const handleModalOpen = () => {
  if ($window.digitalData) {
    $window.digitalData.trigger('mfe-search-toaster-opened');
  }
};

const SearchFlyout = ({
  brand = '',
  handleModalClose = () => {},
  isSearchToasterOpen = false,
  newSearchExpierence = false,
  searchFlyoutRecs = false,
  searchUrl = '',
}) => {
  const betaFlagGroup = useSearchParam('group');

  const [showRecsContainers, setShowRecsContainers] = useState(false);

  const autoSuggestDesktopPlaceholder = useTranslatedText('autoSuggestDesktopPlaceholder', { fallback: 'Search' });
  const closeMenu = useTranslatedText('closeMenu', { fallback: 'Close' });
  const recsTitle = useTranslatedText('topCatsAndCollections', { fallback: 'Top Categories & Collections' });

  const sharedModalContent = (
    <>
      <SearchBox
        betaFlagGroup={betaFlagGroup}
        searchUrl={searchUrl}
        showRecsContainers={setShowRecsContainers}
      />
      <div className={style['recs-container']}>
        {showRecsContainers && (
          <div className={style['links-container']}>
            <SearchRecommendations
              isBubbleStyle
              recsTitle={recsTitle?.value}
            />
          </div>
        )}
        {searchFlyoutRecs ? (
          showRecsContainers && (
            <BreakpointProvider>
              <Recommendations
                clientId="search-flyout-1"
                handleOnProductClick={handleAlgonomyProductClick}
                mode={`${newSearchExpierence ? '' : 'grid'}`}
                placementIds={`${PLACEMENT_IDS.mobile.searchFlyout}|${PLACEMENT_IDS.desktop.searchFlyout}`}
                styles={`${recsStyles.recsWrapper}`}
              />
            </BreakpointProvider>
          )
        ) : (
          <RecommendationSlider
            placementId="search_page.m_flyout_initial1|search_page.flyout_initial1"
            styles={`${recsStyles.recsWrapper} ${showRecsContainers ? '' : recsStyles.hide}`}
          />
        )}
      </div>
    </>
  );

  return (
    <>
      {newSearchExpierence ? (
        <Modal
          closeButtonLabel={closeMenu?.value}
          id="exposed-search-toaster"
          isFullscreen
          isOpen={isSearchToasterOpen}
          onClose={handleModalClose}
          onOpen={handleModalOpen}
          variant="headerless"
        >
          <>
            <div className={`scope-1892 ${style.logoWrapper}`}>
              <Logo kind={brand === 'hol' ? 'hco' : brand} />
            </div>
            {sharedModalContent}
          </>
        </Modal>
      ) : (
        <Toaster
          closeButtonLabel={closeMenu?.value}
          direction="from-right"
          heading={(
            <div className="scope-1892">
              <h2 className="headline">
                {autoSuggestDesktopPlaceholder.value}
              </h2>
            </div>
          )}
          id="search-toaster"
          isOpen={isSearchToasterOpen}
          onClose={handleModalClose}
          onOpen={handleModalOpen}
        >
          {sharedModalContent}
        </Toaster>

      )}
    </>
  );
};

SearchFlyout.propTypes = {
  brand: PropTypes.string,
  isSearchToasterOpen: PropTypes.bool,
  handleModalClose: PropTypes.func,
  newSearchExpierence: PropTypes.bool,
  searchFlyoutRecs: PropTypes.bool,
  searchUrl: PropTypes.string,
};

export default SearchFlyout;
