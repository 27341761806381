import { Modal, TabPanel, Tabs } from 'anf-core-react';
import React, { useContext, useEffect, useState } from 'react';
import ShopSimilarContext from '../../context/ShopSimilarContext';
import useTabNavigation from '../../hooks/useTabNavigation';
import useTranslatedText from '../../hooks/useTranslatedText';
import SimilarSlider from './SimilarSlider';
import style from './styles/similarModal.module.scss';

const SimilarModalContent = () => {
  const {
    productId,
    rows,
    facet,
    similarRecipeId,
    shopSimilarTerms,
    name: productName,
  } = useContext(ShopSimilarContext);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [isQuickViewOpen, setIsQuickViewOpen] = useState(false);
  const similarItems = useTranslatedText('similarItems', { fallback: 'Similar Items' });
  const colorLabel = useTranslatedText('similarColor', { fallback: 'Color' });
  const styleLabel = useTranslatedText('similarStyle', { fallback: 'Style' });

  useEffect(() => {
    setIsModalOpen(true);
  }, [productId, rows, facet, similarRecipeId, shopSimilarTerms, productName]);

  const tabPanelIDs = [
    'style-tabpanel',
    'color-tabpanel',
  ];

  const {
    refs,
    visibleTabID,
    handleTabClick,
    handleTabKeyDown,
  } = useTabNavigation(tabPanelIDs[0], tabPanelIDs);

  if (!productId || !shopSimilarTerms) return null;
  const similarTabData = [
    {
      ariaControls: tabPanelIDs[0],
      id: 'style-tab',
      isSelected: visibleTabID === tabPanelIDs[0],
      label: styleLabel.value,
      onClick: handleTabClick,
      ref: refs[0],
    },
    {
      ariaControls: tabPanelIDs[1],
      id: 'color-tab',
      isSelected: visibleTabID === tabPanelIDs[1],
      label: colorLabel.value,
      onClick: handleTabClick,
      ref: refs[1],
    },
  ];
  return (
    <Modal
      classList={`${style.similarModal} ${isQuickViewOpen ? style.shrink : ''}`}
      closeButtonLabel="Close"
      id="similarModal"
      isOpen={isModalOpen}
      onClose={() => {
        setIsModalOpen(false);
        setIsQuickViewOpen(false);
      }}
      variant="headerless"
    >
      <div className="scope-1892">
        <span>{productName}</span>
        <h2 data-property={similarItems.key}>{similarItems.value}</h2>
        {similarRecipeId !== 'default' ? (
          <>
            <span style={{ display: 'none' }}>{shopSimilarTerms[similarRecipeId]}</span>
            <SimilarSlider
              facet={facet}
              keyword={shopSimilarTerms[similarRecipeId]}
              productId={productId}
              rows={rows}
              setIsQuickViewOpen={setIsQuickViewOpen}
            />
          </>
        ) : (

          <Tabs
            controls={{
              tabs: similarTabData,
              onKeyDown: handleTabKeyDown,
            }}
            labelText={similarItems.value}
          >
            <TabPanel
              ariaLabelledBy={similarTabData[0].id}
              id={tabPanelIDs[0]}
              isVisible={visibleTabID === tabPanelIDs[0]}
            >
              <span style={{ display: 'none' }}>{shopSimilarTerms.style}</span>
              <SimilarSlider
                facet={facet}
                keyword={shopSimilarTerms.style}
                productId={productId}
                rows={rows}
                setIsQuickViewOpen={setIsQuickViewOpen}
              />
            </TabPanel>
            <TabPanel
              ariaLabelledBy={similarTabData[1].id}
              id={tabPanelIDs[1]}
              isVisible={visibleTabID === tabPanelIDs[1]}
            >
              <span style={{ display: 'none' }}>{ shopSimilarTerms.color}</span>
              <SimilarSlider
                facet={facet}
                keyword={shopSimilarTerms.color}
                productId={productId}
                rows={rows}
                setIsQuickViewOpen={setIsQuickViewOpen}
              />
            </TabPanel>
          </Tabs>
        )}
      </div>
    </Modal>
  );
};

export default SimilarModalContent;
