import { useCallback } from 'react';
import { useSessionStorage } from 'react-use';
import $window from '../../../tools/window';

const useSetVisitedProduct = ({
  kic,
  productPageUrl,
}) => {
  const [productToFocus, setProductToFocus] = useSessionStorage('productToFocus', {});

  const setHistory = useCallback(() => {
    const historyState = (typeof window.history.state === 'object' && !!window.history.state) ? window.history.state : {};
    window.history.replaceState({ ...historyState, productToFocus: 'true' }, '', window.location.pathname + window.location.search);
  }, []);

  const handleOpenProduct = useCallback((event) => {
    event.preventDefault();
    setHistory();

    setProductToFocus({ ...productToFocus, productId: kic });
    $window.location.href = productPageUrl;
  }, [kic, productPageUrl, setHistory, productToFocus, setProductToFocus]);

  return { handleOpenProduct };
};

export default useSetVisitedProduct;
