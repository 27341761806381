import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { LD_PAGE_RECS } from '../queries';

const useLDPageRecsJson = ({ existingRecsArray = [] }) => {
  /*
    If what we've passed in is a valid array of recommendation objects, there's no need to
    query for our LaunchDarkly data... that's what you'll see in the logic below!
  */

  const { data: ldPageRecData, loading, error } = useQuery(
    LD_PAGE_RECS,
    {
      skip: existingRecsArray.length > 0,
    },
  );

  if (loading || error) return [];

  const ldPageRecsJsonArray = existingRecsArray.length
    ? existingRecsArray
    : ldPageRecData?.config?.pageRecommendationJsonArray?.value?.collections || [];

  return ldPageRecsJsonArray;
};

useLDPageRecsJson.propTypes = {
  existingRecsArray: PropTypes.arrayOf(
    PropTypes.shape({
      imageUrl: PropTypes.string,
      label: PropTypes.string,
      linkUrl: PropTypes.string,
    }),
  ),
};

export default useLDPageRecsJson;
