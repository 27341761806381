const API_TIMEOUT = 5000;
const ARROW_WIDTH = 40;
const AUTOSUGGEST_THRESHOLD = 3;
const BROWSE_BETA_GROUP_PREFIX = 'BB_';
const BRAND_TAB_DESKTOP_HEIGHT = 50;
const STICKY_INTERSECTION_BUFFER = 10;
const CLICKED_SUGGESTION_KEY = 'clickedSuggestion';
const DEPT_SELECTION_KEY = 'deptSelection';
const DIGITAL_DATA_TIMEOUT = 5000;
const SEARCH_FLYOUT_DEBOUNCE = 500;
const EDITABLE_SEARCH_SCROLL_OFFSET = 210;
const FILTERS_PER_FACET = 2;
const HEADER_SCROLL_OFFSET = 115;
const MAX_RECENT_SEARCHES = 5;
const MAX_SLIDER_LENGTH = 10;
const MAX_SLIDES_VISIBLE = 3;
const MAX_SUGGESTED_FILTERS = 6;
const RECENT_SEARCHES_KEY = 'recentSearches';
const RECS_DISPLAY_LIMIT = 4;
const SCROLL_THRESHOLD = '800px';
const SEEK_BETA_GROUP_PREFIX = 'SB_';
const SIMILAR_PRODUCT_ROWS = '10';
const SIMILAR_DEFAULT_RECIPE = 'default';
const SLIDE_WIDTH = 300;
const SMALLEST_POSSIBLE_BLANK_IMAGE = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw';
const SSR_IMAGE_COUNT = 12;
const PDP_SHOP_SIMILAR_MAX_ROWS = 16;
const PRICE_NEGATIVE_NUMBER_MESSAGE = 'Please enter a positive value to proceed.';
const PRICE_MIN_MAX_MESSAGE = 'Min price can’t exceed max price. Please try again.';
const PRODUCTS_PER_PAGE = '42';
const PRESERVED_DEPARTMENT_ID = 'departmentId';
const FRANCHISE_PORTAL_SESSION_KEY = 'viewedFranchisePortal';
const EMPTY_TMNT = '??????';

const ACTION = {
  CLEAR_ALL_PARAMETERS: 'CLEAR_ALL_PARAMETERS',
  CRS_UPDATE: 'CRS_UPDATE',
  DEPARTMENT_CHANGE: 'DEPARTMENT',
  FACET_TOGGLE: 'FACET_TOGGLE',
  LOCAL_STORE_TOGGLE: 'LOCAL_STORE_TOGGLE',
  PAGINATION: 'PAGINATION',
  PRICE: 'PRICE',
  SEARCHTERM: 'SEARCHTERM',
  SORT_UPDATE: 'SORT',
  SUGGESTED_SEARCHTERM: 'SUGGESTED_SEARCHTERM',
};

const IMAGE_SIZE_POLICY = {
  XSMALL: 'xsmall',
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  XLARGE: 'xlarge',
};

const SHOP_SIMILAR_RECIPES = [
  SIMILAR_DEFAULT_RECIPE,
  'style',
  'color',
  'pdpStyle',
];
const LILY_AI_PRODUCT_ATTRIBUTES = [
  'AI_Product_Type',
  'AI_Style_Silhouette',
  'AI_Length',
  'AI_Color',
  'AI_Neck_Style',
  'AI_Sleeve_Length',
  'AI_Occasion',
  'AI_Dressing_Style',
];

const MAX_SIMILAR_CATEGORY_LENGTH = 3;
const LILY_OVERRIDES = [
  'hoodie',
];
const SIMILAR_FALLBACK_STYLE_NAMES = [
  'graphic',
];

const FEATURE_FLAG_GROUP_COOKIE = 'ANFGroup';

const FACET_DICTIONARY = {
  // Updated 2024-10-23 from https://anfcorp.atlassian.net/wiki/spaces/eComm/pages/602702043/Facets+Enabled+for+v3+Search+APIs
  collection: 'ads_f41502_ntk_cs',
  colorFamily: 'colorFamily_ntk_cs',
  courierEligibility: 'ads_f44001_ntk_cs',
  details: 'ads_f42001_ntk_cs',
  fit: 'ads_f11502_ntk_cs',
  flyType: 'ads_f37001_ntk_cs',
  gender: 'ads_f39501_ntk_cs',
  graphicTheme: 'ads_f49001_ntk_cs',
  influencerHandleCode: 'ads_f45002_ntk_cs',
  lengths: 'ads_f43003_ntk_cs',
  lengthStyles: 'ads_f36001_ntk_cs',
  logos: 'ads_f12002_ntk_cs',
  materials: 'ads_f41501_ntk_cs',
  neckline: 'ads_f37002_ntk_cs',
  nflTeamName: 'ads_f49002_ntk_cs',
  occasion: 'ads_f41001_ntk_cs',
  productCategories: 'ads_f36005_ntk_cs',
  productType: 'ads_f38501_ntk_cs',
  ripped: 'ads_f11002_ntk_cs',
  rise: 'ads_f36003_ntk_cs',
  scent: 'ads_f35501_ntk_cs',
  sizes: 'ads_f43002_ntk_cs',
  sleeveLength: 'ads_f36002_ntk_cs',
  stretch: 'ads_f11003_ntk_cs',
  styles: 'ads_f36501_ntk_cs',
  technicalDetails: 'ads_f41504_ntk_cs',
  technicalFeatures: 'ads_f10502_ntk_cs',
  trend: 'ads_f41503_ntk_cs',
  wash: 'ads_f36004_ntk_cs',
};

const PLACEMENT_IDS = {
  desktop: {
    searchtypeahead: 'search_page.typeahead1_rr1',
    searchNull: 'search_page.null1|search_page.null2|search_page.null3|search_page.null4|search_page.null5|search_page.null6',
    searchFlyout: 'search_page.flyout_initial1',
  },
  mobile: {
    searchtypeahead: 'search_page.m_typeahead1_rr1',
    searchNull: 'search_page.m_null1|search_page.m_null2|search_page.m_null3|search_page.m_null4|search_page.m_null5|search_page.m_null6',
    searchFlyout: 'search_page.m_flyout_initial1',
  },
};

const DEPARTMENT_ID_US_ANF_WOMENS = '12203-ANF';

const BREADCRUMB_URL_ANALYTICS_PARAMETERS = { pagefm: 'navigation-breadcrumbs' };
const CATEGORY_RAIL_URL_ANALYTICS_PARAMETERS = { pagefm: 'navigation-left+nav' };
const PRODUCT_CARD_URL_ANALYTICS_PARAMETERS = { pagefm: 'navigation-grid', prodvm: 'navigation-grid' };
const PRODUCT_CARD_SWATCH_URL_ANALYTICS_PARAMETERS = { pagefm: 'navigation-swatch', prodvm: 'navigation-swatch' };

module.exports = {
  ACTION,
  API_TIMEOUT,
  ARROW_WIDTH,
  AUTOSUGGEST_THRESHOLD,
  BROWSE_BETA_GROUP_PREFIX,
  BRAND_TAB_DESKTOP_HEIGHT,
  BREADCRUMB_URL_ANALYTICS_PARAMETERS,
  CATEGORY_RAIL_URL_ANALYTICS_PARAMETERS,
  CLICKED_SUGGESTION_KEY,
  DEPARTMENT_ID_US_ANF_WOMENS,
  DEPT_SELECTION_KEY,
  DIGITAL_DATA_TIMEOUT,
  EDITABLE_SEARCH_SCROLL_OFFSET,
  EMPTY_TMNT,
  FEATURE_FLAG_GROUP_COOKIE,
  FILTERS_PER_FACET,
  FRANCHISE_PORTAL_SESSION_KEY,
  HEADER_SCROLL_OFFSET,
  IMAGE_SIZE_POLICY,
  LILY_AI_PRODUCT_ATTRIBUTES,
  LILY_OVERRIDES,
  MAX_RECENT_SEARCHES,
  MAX_SIMILAR_CATEGORY_LENGTH,
  MAX_SLIDER_LENGTH,
  MAX_SLIDES_VISIBLE,
  MAX_SUGGESTED_FILTERS,
  PDP_SHOP_SIMILAR_MAX_ROWS,
  PRESERVED_DEPARTMENT_ID,
  PRICE_MIN_MAX_MESSAGE,
  PRICE_NEGATIVE_NUMBER_MESSAGE,
  PRODUCT_CARD_URL_ANALYTICS_PARAMETERS,
  PRODUCT_CARD_SWATCH_URL_ANALYTICS_PARAMETERS,
  PRODUCTS_PER_PAGE,
  RECENT_SEARCHES_KEY,
  RECS_DISPLAY_LIMIT,
  SCROLL_THRESHOLD,
  SEARCH_FLYOUT_DEBOUNCE,
  SEEK_BETA_GROUP_PREFIX,
  SHOP_SIMILAR_RECIPES,
  SIMILAR_DEFAULT_RECIPE,
  SIMILAR_FALLBACK_STYLE_NAMES,
  SIMILAR_PRODUCT_ROWS,
  SLIDE_WIDTH,
  SMALLEST_POSSIBLE_BLANK_IMAGE,
  SSR_IMAGE_COUNT,
  STICKY_INTERSECTION_BUFFER,
  FACET_DICTIONARY,
  PLACEMENT_IDS,
};
