import $window from './window';

function updateSort(urlSearchParams, sort) {
  if (sort) {
    urlSearchParams.delete('sort');
    urlSearchParams.set('sort', sort);
  }
}

function updateDepartmentId(urlSearchParams, departmentId) {
  urlSearchParams.delete('departmentId');
  if (departmentId) {
    urlSearchParams.set('departmentId', departmentId);
  }
}

function updateFacet(urlSearchParams, facet) {
  if (facet) {
    urlSearchParams.delete('facet');
    facet.forEach((facetItem) => {
      const isFacetString = typeof facetItem === 'string';
      const facetSplitArr = isFacetString ? facetItem?.split(':') : null;
      const facetObject = isFacetString
        ? { name: facetSplitArr[0].replace(/[(),"]/g, ''), value: facetSplitArr[1] } : facetItem;

      urlSearchParams.append('facet', `${facetObject.name}:${facetObject.value}`);
    });
  }
}

function updateFilter(urlSearchParams, filter) {
  if (filter) {
    urlSearchParams.delete('filter');
    urlSearchParams.set('filter', filter);
  }
}

function updateSearchTerm(urlSearchParams, searchTerm) {
  if (searchTerm) {
    urlSearchParams.delete('searchTerm');
    urlSearchParams.set('searchTerm', searchTerm);
  }
}

function updateSubmitMethod(urlSearchParams, submitMethod) {
  if (submitMethod) {
    urlSearchParams.set(
      'submitMethod',
      `${submitMethod.trigger}${submitMethod.attribute ? `:${submitMethod.attribute}` : ''}`,
    );
  }
}

function updateRows(urlSearchParams, rows) {
  urlSearchParams.set('rows', (rows) || '90');
}

function updateStart(urlSearchParams, start) {
  urlSearchParams.set('start', (start) || '0');
}

/**
 * Function that takes an object of new Query Parameters to update the current window URL
 * then returns an updated URLSearchParams object
 * @returns {URLSearchParams} - Updated URLSearchParams object
 */
const getUpdatedUrlQueryParameters = (newParameters) => {
  const {
    departmentId,
    facet,
    filter,
    rows,
    searchTerm,
    submitMethod,
    sort,
    start,
  } = newParameters;

  const urlSearchParams = new URLSearchParams($window.location.search);

  updateSearchTerm(urlSearchParams, searchTerm);
  updateFilter(urlSearchParams, filter);
  updateFacet(urlSearchParams, facet);
  updateDepartmentId(urlSearchParams, departmentId);
  updateSort(urlSearchParams, sort);
  updateSubmitMethod(urlSearchParams, submitMethod);
  updateRows(urlSearchParams, rows);
  updateStart(urlSearchParams, start);

  urlSearchParams.sort();
  return urlSearchParams;
};

const updateUrl = (params) => {
  const { persistHistoryState } = params;
  $window.history.pushState(persistHistoryState ? $window.history.state : {}, '', `${$window.location.pathname}?${getUpdatedUrlQueryParameters(params).toString()}`);
};

export default updateUrl;
