import { ACTION } from './constants';
import getPageType from './getPageType';
import isServer from './isServer';
import $window from './window';

const triggerAnalyticsEvent = (analyticsKey, analyticsData) => {
  if (!$window.digitalData) {
    return;
  }
  $window.digitalData.trigger(analyticsKey, analyticsData);
};

const triggerFilterAnalytics = (storeDetails, filter) => {
  // When the toggle is on and we have a store, send to dataLayer
  if (filter === `localStoreId:${storeDetails?.storeId}` && storeDetails?.storeName) {
    triggerAnalyticsEvent(`${getPageType()}_filter_applied`, {
      search_filter_applied_category: storeDetails.storeName,
      search_filter_applied_value_name: 'Same Day Delivery',
      event_name: 'filter_applied',
      tealium_event: 'filters_applied',
    });
  }
};

const triggerSearchTermAnalytics = ({
  actionType,
  completionType,
  isInitialSearch,
  previousSearchterm,
  resultsCount,
  submitMethod,
  searchTerm,
  userInputedSearchTerm,
}) => {
  if (
    previousSearchterm === searchTerm
        || ![ACTION.SEARCHTERM, 'pageLoad'].includes(actionType)
        || isServer()
        || !$window.digitalData
  ) {
    return;
  }

  const suggestionTypeMap = {
    popular: 'search',
    recent: 'search',
    suggestions: searchTerm,
    products: searchTerm,
  };

  const suggestionType = suggestionTypeMap[completionType] || null;

  const analyticsData = {
    key: 'search.search_bar_used',
    data: {
      name: 'Search bar used',
      search_bar_category: completionType ?? null,
      search_bar_suggestion: suggestionType,
      search_corrected_search_term: null,
      search_results: resultsCount,
      search_term: searchTerm,
    },
  };

  if (searchTerm !== userInputedSearchTerm && String(userInputedSearchTerm).trim() !== '') {
    analyticsData.data.search_corrected_search_term = searchTerm;
    analyticsData.data.search_term = userInputedSearchTerm;
  }

  if (!isInitialSearch) {
    analyticsData.data.data_text = 'editable search';
    analyticsData.data.data_action = submitMethod.trigger === 'attributePill' ? 'visual text' : 'text';
    if (submitMethod.trigger === 'attributePill') {
      analyticsData.data.search_attribute = submitMethod.attribute;
    }
  }
  triggerAnalyticsEvent(analyticsData.key, analyticsData.data);
};

const triggerFacetAnalytics = () => {
// TO DO: Implement facet analytics
};

export { triggerFilterAnalytics, triggerSearchTermAnalytics, triggerFacetAnalytics };
