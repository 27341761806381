import { gql } from '@apollo/client';

const DEPARTMENT_FRAGMENT = gql`
  fragment DepartmentFragment on Department {
    id
    name
    defaultCategoryIdBySite
  }
`;

export default DEPARTMENT_FRAGMENT;
