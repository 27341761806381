import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { useUpdateEffect } from 'react-use';
import gridTopContext from '../../context/gridTopContext';
import { HEADER_SCROLL_OFFSET } from '../../tools/constants';

function ProductGridScrollHandler({
  children,
  parameters,
  scrollOffset = HEADER_SCROLL_OFFSET,
}) {
  const gridElement = useRef();

  const scrollToGrid = () => {
    let gridTop = 0;
    if (gridElement.current) {
      // Find top of element relative to the viewport
      const { top } = gridElement.current.getBoundingClientRect();
      // Calculate top relative to the document
      gridTop = top + window.scrollY;
    }

    // Scroll to top of grid with room for approx 110px sticky header
    window.scrollTo({
      left: 0,
      top: (gridTop - scrollOffset),
      behavior: 'smooth',
    });
  };
  useUpdateEffect(() => {
    scrollToGrid();
  }, [parameters]);

  return (
    <gridTopContext.Provider value={gridElement}>
      <div ref={gridElement} className="scroll-handler">
        {children}
      </div>
    </gridTopContext.Provider>
  );
}

ProductGridScrollHandler.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  parameters: PropTypes.shape({
    departmentId: PropTypes.string,
    facet: PropTypes.arrayOf(PropTypes.string),
    filter: PropTypes.string,
    rows: PropTypes.string,
    searchTerm: PropTypes.string,
    sort: PropTypes.string,
    start: PropTypes.string,
  }).isRequired,
  scrollOffset: PropTypes.number,
};

export default ProductGridScrollHandler;
