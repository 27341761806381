import PropTypes from 'prop-types';
import React from 'react';
import SearchDetails from '../SearchDetails/SearchDetails';
import HeaderFilters from './HeaderFilters';

function BaseSearchHeader({
  facet = [],
  isClearAllButtonEnabled = false,
  onClearAllBtnClick = () => {},
  onFacetTagClick = () => {},
  searchTerm,
  resultsCount = 0,
}) {
  return (
    <>
      <SearchDetails resultsCount={resultsCount} />
      <HeaderFilters
        facet={facet}
        isClearAllButtonEnabled={isClearAllButtonEnabled}
        onClearAllBtnClick={onClearAllBtnClick}
        onFacetTagClick={onFacetTagClick}
        searchTerm={searchTerm}
      />
    </>
  );
}

BaseSearchHeader.propTypes = {
  facet: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  isClearAllButtonEnabled: PropTypes.bool,
  onClearAllBtnClick: PropTypes.func,
  onFacetTagClick: PropTypes.func,
  searchTerm: PropTypes.string.isRequired,
  resultsCount: PropTypes.number,
};

export default BaseSearchHeader;
