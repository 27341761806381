import { gql, useQuery } from '@apollo/client';
import Logo from 'anf-core-react/components/Logo';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import useTranslatedText from '../../../hooks/useTranslatedText';
import getBrandFamily from '../../../tools/getBrandFamily';
import StandaloneMfe from '../../StandaloneMfe/StandaloneMfe';
import UserPreferences from '../../UserPreferences/UserPreferences';
import FooterLink from '../FooterLink';
import style from '../footer.module.scss';
import BrandLinks from './BrandLinks';
import HighContrastToggle from './HighContrastToggle';
import SocialIcons from './SocialIcons';

const getLinks = gql`
  query Links {
    footer (isDesktop: true) {
      id
      footerLogos {
        id
        url
      }
      aboutLinks {
        url
        title
        id
        external
      }
      helpLinks {
        url
        title
        id
        external
      }
      iconLinks {
        url
        title
        id
        external
        icon
      }
      socialLinks {
        url
        title
        id
        external
        icon
      }
    }
  }
`;

const FooterGrid = ({
  brand,
  defaultCountry = '',
}) => {
  const brandFamily = getBrandFamily(brand);
  const ourBrands = useTranslatedText('ourBrands', { fallback: 'Our Brands' });
  const aboutUs = useTranslatedText('aboutUs', { fallback: 'About Us' });
  const help = useTranslatedText('help', { fallback: 'Help' });
  const trendWithUsTextPair = useTranslatedText('trendWithUs', { skip: brandFamily !== 'hol' });
  const seeLatestTextPair = useTranslatedText('seeLatest', { skip: brandFamily !== 'hol' });

  const { data } = useQuery(getLinks);
  if (!data) return null;

  const {
    footerLogos, aboutLinks, helpLinks, iconLinks, socialLinks,
  } = data?.footer || {};

  return (
    <div className={style.footerGrid} data-testid="desktop-footer">
      <div className={style.brandLinks}>
        <div className={classNames(style.logo, { [style.logoHco]: brand === 'hol' })}>
          <Logo kind={brand === 'hol' ? 'hco-seagull' : brand} />
        </div>
        <div>
          {footerLogos && (
            <>
              <hr className="hr" />
              <h2
                className={style.brandLinksHeading}
                data-property={ourBrands.key}
              >
                {ourBrands.value}
              </h2>
              <BrandLinks isSmallNav={false} links={footerLogos} />
            </>
          )}
        </div>
      </div>
      <div>
        <h2 className={style.linkHeading} data-property={aboutUs.key}>{aboutUs.value}</h2>
        {aboutLinks && (
          <ul className={style.linkList}>
            {aboutLinks.map((link) => (
              <li key={link.id}>
                <FooterLink link={link} />
              </li>
            ))}
          </ul>
        )}
      </div>
      <div>
        <h2 className={style.linkHeading} data-property={help.key}>{help.value}</h2>
        {helpLinks && (
          <ul className={style.linkList}>
            {helpLinks.map((link) => (
              <li key={link.id}>
                <FooterLink link={link} />
              </li>
            ))}
          </ul>
        )}
      </div>
      <div>
        {iconLinks && (
          <ul className={style.iconList}>
            {iconLinks.map((link) => (
              <li key={link.id}>
                <FooterLink link={link} />
              </li>
            ))}
            <li>
              <UserPreferences defaultCountry={defaultCountry} />
            </li>
          </ul>
        )}
        <HighContrastToggle />
      </div>
      <div>
        <div className={style.socialWrapper}>
          <div className={style.socialInnerWrapper}>
            <StandaloneMfe componentName="footerEmailSubscribeBlockStandalone" mfeName="customer" />
            {
              trendWithUsTextPair.value && <h2 className="h3">{trendWithUsTextPair.value}</h2>
            }
            {
              seeLatestTextPair.value && <p>{seeLatestTextPair.value}</p>
            }
            {socialLinks && <SocialIcons links={socialLinks} />}
          </div>
        </div>
      </div>
    </div>
  );
};

FooterGrid.propTypes = {
  brand: PropTypes.string.isRequired,
  defaultCountry: PropTypes.string,
};

export default FooterGrid;
