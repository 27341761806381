import { useQuery } from '@apollo/client';
import {
  Button,
  Icon,
} from 'anf-core-react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {
  useState,
} from 'react';
import useTranslatedText from '../../hooks/useTranslatedText';
import DomNodePortal from '../../tools/DomNodePortal';
import getPageType from '../../tools/getPageType';
import $window from '../../tools/window';
import SearchFlyout from '../SearchFlyout/SearchFlyout';
import SimilarEntryPoint from '../ShopSimilar/SimilarEntryPoint';
import FakeSearchInputField from './FakeSearchInputField';
import inputStyle from './fakeSearchInputField.module.scss';
import { CONFIG_DATA } from './queries';
import style from './searchWrapper.module.scss';

const SearchWrapper = ({
  brand = '',
  searchUrl = '',
}) => {
  const [isSearchToasterOpen, setisSearchToasterOpen] = useState(false);

  const { data: configData } = useQuery(CONFIG_DATA);

  const handleSearchIconClick = (event) => {
    if ($window.digitalData) {
      const pageType = getPageType();

      $window.digitalData.trigger(`${pageType}.search_icon.click`, {
        event_type: 'click',
        data_text: 'Search Icon',
        data_action: 'open',
      }, event);
    }
    setisSearchToasterOpen(true);
  };

  const newSearchExpierence = configData?.config?.assistiveSearchNav?.value;
  const mobileMFENavEnabled = configData?.config?.hasMFEMobileNavToaster?.value;
  const searchFlyoutRecs = configData?.config?.searchFlyoutRecsMigration?.value;

  const searchToasterLabel = useTranslatedText('searchToasterLabel', { fallback: 'Open Search' });
  const autoSuggestDesktopPlaceholder = useTranslatedText('autoSuggestDesktopPlaceholder', { fallback: 'Search' });
  const searchToasterButton = (
    <div
      className={classNames(
        style.wrapper,
        {
          [style.legacyStyles]: !mobileMFENavEnabled,
        },
      )}
      data-testid="search-toaster-button"
    >
      <Button
        classList={style.button}
        onClick={handleSearchIconClick}
        variant="borderless"
      >
        <Icon icon="search" labelText={searchToasterLabel.value} />
      </Button>
    </div>
  );

  const handleToasterClose = () => {
    setisSearchToasterOpen(false);
  };

  return (
    <>
      {(!mobileMFENavEnabled && !newSearchExpierence) && (
        <DomNodePortal targetNodeSelector=".rs-nav-bar--mfeSearchIcon">
          {searchToasterButton}
        </DomNodePortal>
      )}
      <SimilarEntryPoint />
      {newSearchExpierence ? (
        <>
          <FakeSearchInputField
            buttonLabel={autoSuggestDesktopPlaceholder.value}
            handleSearchIconClick={handleSearchIconClick}
            iconButton={searchToasterButton}
          />
          {!mobileMFENavEnabled && (
            <DomNodePortal targetNodeSelector=".fake-search-input-field-portal">
              <div className={inputStyle.mobileInputContainer}>
                <FakeSearchInputField
                  buttonLabel={autoSuggestDesktopPlaceholder.value}
                  handleSearchIconClick={handleSearchIconClick}
                  iconButton={searchToasterButton}
                />
              </div>
            </DomNodePortal>
          )}
        </>
      ) : searchToasterButton}
      <SearchFlyout
        brand={brand}
        handleModalClose={handleToasterClose}
        isSearchToasterOpen={isSearchToasterOpen}
        mobileMFENavEnabled={mobileMFENavEnabled}
        newSearchExpierence={newSearchExpierence}
        searchFlyoutRecs={searchFlyoutRecs}
        searchUrl={searchUrl}
      />
    </>
  );
};

SearchWrapper.propTypes = {
  brand: PropTypes.string,
  searchUrl: PropTypes.string,
};

export default SearchWrapper;
