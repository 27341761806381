import React from 'react';
import BreakpointProvider from '../BreakpointProvider';

const withBreakpointProvider = (WrappedComponent) => (props) => (
  <BreakpointProvider>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <WrappedComponent {...props} />
  </BreakpointProvider>
);

export default withBreakpointProvider;
