import getFacetKeysForAnalytics from '../../../tools/getFacetKeysForAnalytics';
import $window from '../../../tools/window';

/**
 * Triggers the search_filter_applied event on DigitalDataBridge for analytics tracking
 * @param {Map} facetMap - Map of applied Facets. {facetKey: [facetValue(s)]}
 * @param {String} departmentId - Current Department Id
 * @param {Number} appliedFilterCount - Number of applied filters
 * @param {Object} discoverTrigger - The discover trigger from the facet dropdown test
 * @param {String} facetId - The facet id of the facet selected from the facet dropdown test
*/
const triggerFacetAnalytics = ({
  facetMap,
  departmentId,
  appliedFilterCount,
  discoverTrigger = '',
  facetId = '',
}) => {
  if ($window?.digitalData?.trigger) {
    let dataText = 'rail';
    let dataAction = appliedFilterCount > 1 ? 'multiple' : 'single';

    if (discoverTrigger) {
      dataText = `${discoverTrigger} ${facetId} filter`;
      dataAction = 'applied';
    }

    const facetKeysForAnalytics = [...getFacetKeysForAnalytics(facetMap)];
    const appliedFilterCategories = discoverTrigger ? `${discoverTrigger} ${facetKeysForAnalytics}` : `${facetKeysForAnalytics}`;
    const appliedFacetValues = `${[...facetMap.values()]}`;

    const pageDescription = (!departmentId) ? 'All Genders' : departmentId;

    const analyticsEvent = {
      search_filter_applied_category: appliedFilterCategories,
      search_filter_applied_value_name: appliedFacetValues,
      event_name: 'filter_applied',
      event_type: 'filters',
      page_description: pageDescription,
      tealium_event: 'filter_applied',
      data_text: dataText,
      data_action: dataAction,
    };
    $window.digitalData.trigger('search_filter_applied', analyticsEvent);
  }
};

export default triggerFacetAnalytics;
