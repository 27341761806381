import { useQuery } from '@apollo/client';
import PropTypes, { string } from 'prop-types';
import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import useCRSParameterListener from '../../hooks/useCRSParameterListener';
import useScrollToProduct from '../../hooks/useScrollToProduct';
import useStoreData from '../../hooks/useStoreData';
import useUrlSync from '../../hooks/useUrlSync';
import { ACTION } from '../../tools/constants';
import getFacetKeysForAnalytics from '../../tools/getFacetKeysForAnalytics';
import getFacetmap from '../../tools/getFacetmap';
import getPageType from '../../tools/getPageType';
import getSelectedFacets from '../../tools/getSelectedFacets';
import manipulateFacetMap from '../../tools/manipulateFacetMap';
import reducer from '../../tools/parametersChange';
import { triggerFilterAnalytics } from '../../tools/triggerAnalytics';
import updateUrl from '../../tools/updateUrl';
import $window from '../../tools/window';
import { ERROR_MESSAGE } from '../Messages/Messages';
import BreadcrumbSection from './BreadcrumbSection';
import style from './CategoryPage.module.scss';
import CustomerRecommendationsMFE from './CustomerRecommendationsMFE';
import LeftRail from './LeftRail';
import Main from './Main';
import { CATEGORY_PAGE_QUERY, CATEGORY_PAGE_BY_FACET_QUERY } from './queries';

function CategoryPage({
  brand,
  categoryId: intlCategoryId,
  facet: intlFacet = [],
  filter: intlFilter = '',
  grouped = false,
  isUnifiedCategoryPage = false,
  rows: intlRows = '90',
  sort: intlSort = '',
  start: intlStart = '0',
  storeId = '',
  store = '',
  seqSlot = '',
}) {
  const intlFacetArray = typeof intlFacet === 'string' ? [intlFacet] : intlFacet;
  const [parameters, dispatch] = useReducer(reducer, {
    categoryId: intlCategoryId,
    facet: intlFacetArray,
    filter: intlFilter,
    rows: intlRows,
    sort: intlSort,
    start: intlStart,
  });
  const railRef = useRef({ appliedRailFilters: [] });

  const [productData, setProductData] = useState();
  const [pageBottomStartParameter, setPageBottomStartParameter] = useState(
    parseInt(parameters.start, 10),
  );
  const [pageTopStartParameter, setPageTopStartParameter] = useState(
    parseInt(parameters.start, 10),
  );
  const storeDetails = useStoreData();

  const { data: categoryPageData } = useQuery(CATEGORY_PAGE_QUERY, {
    variables: {
      categoryId: intlCategoryId,
      isUnifiedCategoryPage,
    },
  });

  const {
    data, previousData, loading, error,
  } = useQuery(CATEGORY_PAGE_BY_FACET_QUERY, {
    variables: {
      categoryId: intlCategoryId,
      facet: parameters.facet,
    },
  });

  const hasShopMyStoreEnabled = categoryPageData?.config?.hasShopMyStoreEnabled?.value ?? false;
  const hasCategoryV3APIEnabled = categoryPageData?.config?.hasCategoryV3APIEnabled?.value
    ?? false;

  useUrlSync(parameters, dispatch);

  useScrollToProduct();

  const handleStoreFilter = useCallback((storeIdFromFilter) => {
    dispatch({
      type: ACTION.LOCAL_STORE_TOGGLE,
      payload: storeIdFromFilter,
    });
    triggerFilterAnalytics(storeDetails, storeIdFromFilter);
  }, [storeDetails]);

  const handleSortChange = (event) => {
    dispatch({
      type: ACTION.SORT_UPDATE,
      payload: event.target.value,
    });
    $window.digitalData.trigger(`${getPageType()}_sort_applied`, {
      event_name: 'sort_applied',
      event_type: 'sort',
      search_filter_applied_value_name: event.target.value,
      tealium_event: 'sort_applied',
    });
  };

  const onPaginationButtonClick = (event, start) => {
    dispatch({
      type: ACTION.PAGINATION,
      payload: start,
    });
  };

  const onClearAllBtnClick = () => {
    railRef.current.appliedRailFilters = [];
    dispatch({
      type: ACTION.CLEAR_ALL_PARAMETERS,
    });
  };

  const handleCheckBoxChange = (event) => {
    const { target: { checked, value } } = event;
    railRef.current.appliedRailFilters = checked
      ? [...railRef.current.appliedRailFilters, value]
      : railRef.current.appliedRailFilters.filter((item) => item !== value);

    const facetMap = getFacetmap(event, parameters.facet);

    dispatch({
      type: ACTION.FACET_TOGGLE,
      payload: [...facetMap],
    });

    if ($window.digitalData) {
      const analyticsEvent = {
        search_filter_applied_category: `${[...getFacetKeysForAnalytics(facetMap)]}`,
        search_filter_applied_value_name: `${[...facetMap.values()]}`,
        event_name: 'filter_applied',
        event_type: 'filters',
        tealium_event: 'filter_applied',
        data_text: 'rail',
        data_action: railRef.current.appliedRailFilters.length > 1 ? 'multiple' : 'single',
      };
      $window.digitalData.trigger('search_filter_applied', analyticsEvent);
    }
  };

  /* Start Load More Test */

  const handleProductData = (returnedData) => {
    setProductData(returnedData);
    const initialStart = parseInt(parameters.start, 10);
    setPageBottomStartParameter(initialStart);
    setPageTopStartParameter(initialStart);
  };

  const appendProductData = (newProducts) => {
    setProductData((prevProducts) => [...prevProducts, ...newProducts]);
  };

  const prependProductData = (prependedProducts) => {
    setProductData((prevProducts) => [...prependedProducts, ...prevProducts]);
  };

  const handleLoadMoreButtonClick = (newPageBottomStart) => {
    setPageBottomStartParameter(newPageBottomStart);
  };

  const handleLoadPreviousButtonClick = (newPageTopStart) => {
    setPageTopStartParameter(newPageTopStart);
  };

  useEffect(() => {
    const handleMarketingFiltersClicked = (event) => {
      const { detail } = event;
      const { facetGenericName, facetLegacyName } = detail;
      const facetId = hasCategoryV3APIEnabled ? facetGenericName : facetLegacyName;
      const updatedFacetMap = manipulateFacetMap(parameters.facet, { ...detail, facetId });

      dispatch({
        type: ACTION.FACET_TOGGLE,
        payload: [...updatedFacetMap],
      });
    };
    $window.addEventListener('marketingFiltersClicked', handleMarketingFiltersClicked);

    return () => {
      $window.removeEventListener('marketingFiltersClicked', handleMarketingFiltersClicked);
    };
  });

  useEffect(() => {
    if (pageBottomStartParameter > 0
      && pageBottomStartParameter !== parseInt(parameters.start, 10)) {
      updateUrl({
        ...parameters,
        persistHistoryState: true,
        start: pageBottomStartParameter,
      });
    }
    // When parameters change we want start to reset to zero instead of triggering this
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageBottomStartParameter]);

  /* End Load More Test */

  const handleCategoryParametersEvent = useCallback((event) => {
    // if facets are on the only possible event we can get from CRS would be a store filter change

    handleStoreFilter(event.detail.filter ?? '');
  }, [handleStoreFilter]);
  // Need this for backward compatibility
  useCRSParameterListener(handleCategoryParametersEvent);

  if (error) {
    console.error('CategoryPage : CATEGORY_PAGE_BY_FACET_QUERY : Error executing GraphQL query', {
      categoryIdParameter: intlCategoryId,
    });

    return ERROR_MESSAGE;
  }

  let currentData;

  if (loading) {
    if (!previousData) {
      return null;
    }
    currentData = previousData;
  } else {
    currentData = data;
  }

  const onClearFacetTag = (payload) => {
    if (railRef.current.appliedRailFilters.includes(`${payload.facetKey},${payload.facetValue}`)) {
      railRef.current.appliedRailFilters = railRef.current.appliedRailFilters.filter(
        (item) => item !== `${payload.facetKey},${payload.facetValue}`,
      );
    }
    const facetMap = getSelectedFacets(parameters.facet);
    // Check if selected checkbox is in already selected facet filters
    if (facetMap.has(payload.facetKey)) {
      const checkedFacets = facetMap.get(payload.facetKey);
      const index = checkedFacets.indexOf(payload.facetValue);
      if (index !== -1) {
        if (checkedFacets.length === 1) {
          facetMap.delete(payload.facetKey);
        } else {
          checkedFacets.splice(index, 1);
        }
      }
    }
    dispatch({
      type: ACTION.FACET_TOGGLE,
      payload: [...facetMap],
    });
  };

  const main = (
    <Main
      appendProductData={appendProductData}
      brand={brand}
      categoryData={currentData.category}
      grouped={grouped}
      handleCheckBoxChange={handleCheckBoxChange}
      handleLoadMoreButtonClick={handleLoadMoreButtonClick}
      handleLoadPreviousButtonClick={handleLoadPreviousButtonClick}
      handleProductData={handleProductData}
      handleSortChange={handleSortChange}
      handleStoreFilter={handleStoreFilter}
      hasCatalogMfeEspots={categoryPageData?.config?.hasCatalogMfeEspots?.value ?? false}
      hasShopMyStoreEnabled={hasShopMyStoreEnabled}
      intlCategoryId={intlCategoryId}
      isUnifiedCategoryPage={isUnifiedCategoryPage}
      onClearAllBtnClick={onClearAllBtnClick}
      onClearFacetTag={onClearFacetTag}
      onPaginationButtonClick={onPaginationButtonClick}
      pageBottomStartParameter={pageBottomStartParameter}
      pageTopStartParameter={pageTopStartParameter}
      parameters={parameters}
      prependProductData={prependProductData}
      productData={productData}
      seqSlot={seqSlot}
      store={store}
      storeDetails={storeDetails}
      storeId={storeId}
    />
  );

  if (isUnifiedCategoryPage) {
    return (
      <div className="scope-1892">
        <CustomerRecommendationsMFE />
        <BreadcrumbSection
          brand={brand}
          categoryId={intlCategoryId}
          espotIdentifier={categoryPageData?.category?.espotIdentifier}
        />
        <div className={style.row}>
          <LeftRail
            brand={brand}
            categoryData={currentData.category}
            categoryId={intlCategoryId}
            handleCheckBoxChange={handleCheckBoxChange}
            handleStoreFilter={handleStoreFilter}
            hasStoreToggleFeature={hasShopMyStoreEnabled}
            parameters={parameters}
            storeData={storeDetails}
          />
          {main}
        </div>
      </div>
    );
  }

  return main;
}

CategoryPage.propTypes = {
  // Required props
  brand: PropTypes.string.isRequired,
  categoryId: PropTypes.string.isRequired,
  // Optional props
  facet: PropTypes.oneOfType([
    PropTypes.arrayOf(string),
    PropTypes.string,
  ]),
  filter: PropTypes.string,
  grouped: PropTypes.bool,
  isUnifiedCategoryPage: PropTypes.bool,
  rows: PropTypes.string,
  sort: PropTypes.string,
  start: PropTypes.string,
  storeId: PropTypes.string,
  store: PropTypes.string,
  seqSlot: PropTypes.string,
};

export default CategoryPage;
