import { Badge, Icon } from 'anf-core-react';
import PropTypes from 'prop-types';
import React from 'react';
import style from './productCard.module.scss';

const SocialProofBadge = ({
  brand = 'anf',
  isDesktop = false,
  socialProofMessage = '',

}) => {
  if (!socialProofMessage || (brand === 'hol' && !isDesktop)) return null;

  return (
    <div className={style.socialProofingBadgeContainer}>
      <Badge>
        <Icon icon="flame-filled" />
        {socialProofMessage}
      </Badge>
    </div>
  );
};

SocialProofBadge.propTypes = {
  socialProofMessage: PropTypes.string,
  brand: PropTypes.string,
  isDesktop: PropTypes.bool,
};

export default SocialProofBadge;
