import DigitalDataProvider from './DigitalDataProvider';
import DigitalDataTestProvider from './DigitalDataTestProvider';

// TEST FLAGS
const DD_DISABLE_SWATCH_HOVER = 'test.disableSwatchHover';
const DD_HYPERLINK_DESC = 'test.mfeHyperlinkDescriptor';
const DD_MODEL_IMAGERY_TEST = 'test.modelImageryTest';
const DD_PAGINATION_TEST = 'test.paginationVsLoadMoreTest';
const DD_SHOW_ANF_NAVIGATION_L3S_TEST = 'test.showAnfNavigationL3s';
const DD_SEARCH_FLYOUT_ALGONOMY_SUGGESTIONS_TEST = 'test.searchFlyoutAlgonomySuggestions';

const DD_DISCOVER_BY_FACET_TEST = 'test.discoverByFacet';

// FEATURE FLAGS
const DD_TIK_TOK_FOOTER_ICON_FLAG = 'flag.tik-tok-footer-icon';
const DD_TAP_TO_DISCOVER = 'flag.tap-to-discover-attributes';

export {
  DigitalDataTestProvider,
  DD_DISABLE_SWATCH_HOVER,
  DD_DISCOVER_BY_FACET_TEST,
  DD_HYPERLINK_DESC,
  DD_MODEL_IMAGERY_TEST,
  DD_PAGINATION_TEST,
  DD_TIK_TOK_FOOTER_ICON_FLAG,
  DD_TAP_TO_DISCOVER,
  DD_SHOW_ANF_NAVIGATION_L3S_TEST,
  DD_SEARCH_FLYOUT_ALGONOMY_SUGGESTIONS_TEST,
};

export default DigitalDataProvider;
