import { useQuery } from '@apollo/client';
import LDFLAGS from '../queries';

const useFeatureFlags = () => {
  const { data: ldFlagData } = useQuery(LDFLAGS);

  const userPreferenceBobToaster = ldFlagData?.config?.userPreferenceBobToaster?.value ?? false;

  return {
    userPreferenceBobToaster,
  };
};

export default useFeatureFlags;
