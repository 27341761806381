import { gql } from '@apollo/client';
import facetsFragment from '../../../queries/fragments/Facets';
import paginationFragment from '../../../queries/fragments/Pagination';
import sortDataFragment from '../../../queries/fragments/ProductGridHeaderCoreFields';
import productsFragment from '../../../queries/fragments/Products';
import DEPARTMENT_FRAGMENT from '../../DepartmentSelector/queries';

const SEARCH_RESULTS_PAGE_DATA = gql`
${productsFragment}
${paginationFragment}
${sortDataFragment}
${facetsFragment}
${DEPARTMENT_FRAGMENT}
query Search(
  $countryFulfillmentStore: String,
  $departmentId: String,
  $facet: [String],
  $filter: String,
  $initialSearchTerm: String,
  $rows: String!,
  $searchTerm: String!,
  $sort: String,
  $start: String!,
  ) {
  searchResults(
    countryFulfillmentStore: $countryFulfillmentStore,
    departmentId: $departmentId,
    facet: $facet,
    filter: $filter,
    initialSearchTerm: $initialSearchTerm
    rows: $rows,
    searchTerm: $searchTerm,
    sort: $sort,
    start: $start,
  ) {
    brand
    pagination {
      ...PaginationInfo
    }
    products {
      ...Products
    }
    sortData {
      ...SortData
    }
    facets {
      ...Facets
    }
    productTotalCount
    departmentId
    searchSuggestions {
      byName {
        rankedWords {
          words
        }
        rankedPhrases {
          phrases
        }
      }
      byAttributes {
        rankedColors {
          words
        }
        rankedNames {
          words
        }
        rankedStyles {
          words
        }
      }
    }
    suggestedSearchTerm
    departments {
        ...DepartmentFragment
      }
  }
}
`;

const STORE_ATTRIBUTES = gql`
  query storeAttributes {
    config {
      hasShopMyStoreEnabled: genericType(name: "hasLISFilter")
      noResultsDepartmentSuggestions: genericType(name: "noResultsDepartmentSuggestions")
    }
  }
`;

export { SEARCH_RESULTS_PAGE_DATA, STORE_ATTRIBUTES };
