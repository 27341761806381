import PropTypes from 'prop-types';
import React from 'react';
import NumberOfResults from '../NumberOfResults/NumberOfResults';
import style from './SearchDetails.module.scss';

export default function SearchDetails({
  resultsCount = 0,
}) {
  return (
    <div
      className={style['rs-search-details']}
    >
      <h1 className="h3 rs-search-details__product-count" data-testid="search-product-count">
        <NumberOfResults count={resultsCount} numberFirst />
      </h1>
      {/* we must tell a screen reader anytime the results or search term has been changed
       https://hub.accessible360.com/kb/articles/922#heading-results-available-announcement-for-screen-reader-users */}
      <span aria-live="assertive" className="screen-reader-text">
        <NumberOfResults count={resultsCount} numberFirst />
      </span>
    </div>
  );
}

SearchDetails.propTypes = {
  resultsCount: PropTypes.number,
};
