import Icon from 'anf-core-react/components/Icon';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext, useRef } from 'react';
import { useIsomorphicLayoutEffect } from 'react-use';
import DigitalDataContext from '../../context/digitalData';
import { DD_SHOW_ANF_NAVIGATION_L3S_TEST } from '../DigitalDataProvider';
import NavFlyoutPanel from '../NavFlyoutPanel/NavFlyoutPanel';
import SingleAemEspot from '../SingleAemEspot';

const LargeScreenNavBarCategory = ({
  allText,
  brand,
  category,
  hasAemLink,
  openCategoryId,
  handleWrappingListItems,
  openMenuText,
  setOpenCategoryId,
  storePreview,
}) => {
  const {
    categoryId, espotIdentifier, isFullWidthFlyout, name, url,
  } = category;
  const buttonRef = useRef();
  const flyoutRef = useRef();
  const l1Ref = useRef();
  const isOpen = categoryId === openCategoryId;

  /**
   * If flyout is open and not in full-width mode, shift its default positioning per CSS if
   * necessary such that the flyout does not extend past the right side of the viewport, if
   * possible (the flyout may be too wide regardless). We do this by adjusting the flyout's
   * 'left' style as little as possible for the purpose. We use useLayoutEffect instead of
   * useEffect to avoid flickering default positioning before showing new positioning. We use
   * useIsomorphicLayoutEffect to prevent React SSR warning about useLayoutEffect.
   */
  useIsomorphicLayoutEffect(() => {
    // ignore this logic in case of full width positioning is towards the whole nav tag
    if (!flyoutRef?.current || isFullWidthFlyout) return;
    // Reset previous shift left, if any
    flyoutRef.current.style.removeProperty('left');
    // 60px is the size of inner margin/padding of the flyout, we need to subtract it
    // to align the flyout with the l1 link
    const originalLeftPosition = l1Ref.current.offsetLeft - 60;
    // make sure we have the original styling applied before making shifts
    flyoutRef.current.style.setProperty('left', `${originalLeftPosition}px`);
    if (isOpen) {
      const domRect = flyoutRef.current.getBoundingClientRect();
      const leftToViewportLeft = domRect.left;
      const rightToViewportLeft = domRect.right;
      const horizontalWhitespace = 15;
      const viewportWidth = document.documentElement.clientWidth;
      const rightToViewportRight = viewportWidth - (rightToViewportLeft + horizontalWhitespace);

      if (rightToViewportRight <= 0) {
        /**
         * Shift left at least enough to fit the flyout plus some whitespace to the right side
         * of the flyout, but not so much that we cause the flyout to extend past the left side
         * of the viewport including whitespace to the left side of the flyout.
         */
        const shiftLeft = Math.min(
          Math.abs(rightToViewportRight),
          leftToViewportLeft - horizontalWhitespace,
        );
        flyoutRef.current.style.setProperty('left', `${originalLeftPosition - shiftLeft}px`);
      }
    }
  }, [
    isFullWidthFlyout,
    isOpen,
  ]);
  const {
    [DD_SHOW_ANF_NAVIGATION_L3S_TEST]: showAnfNavigationL3s,
  } = useContext(DigitalDataContext);

  return (
    <li
      ref={l1Ref}
      className={classNames('nav-bar-category-list-item', {
        'full-width': isFullWidthFlyout,
      })}
      data-large-nav-l1-list-item-cat-id={categoryId}
      data-testid={category.subCategories?.length > 0 ? 'standard-category' : ''}
      onMouseEnter={() => setOpenCategoryId(categoryId)}
      onMouseLeave={() => setOpenCategoryId(null)}
    >
      <h2
        className={classNames('nav-bar-category-link-heading', {
          'nav-bar-category-link-heading--active': isOpen,
        })}
      >
        <a
          className="nav-bar-category-link"
          href={url}
          id={`cat-label-${categoryId}`}
        >
          {hasAemLink ? (
            <SingleAemEspot espotId={`${espotIdentifier}-nav-special-interaction-desktop`} storePreview={storePreview} />
          ) : (
            name
          )}
        </a>
      </h2>
      <button
        ref={buttonRef}
        aria-controls={`flyout-${categoryId}`}
        aria-expanded={isOpen}
        className="nav-bar-category-toggle-button"
        // Blurring hides this button, which affects list items wrapping
        onBlur={handleWrappingListItems}
        onClick={() => setOpenCategoryId(isOpen ? null : categoryId)}
        // Focusing shows this button, which affects list items wrapping
        onFocus={handleWrappingListItems}
        type="button"
      >
        <Icon icon="down" />
        <span className="screen-reader-text">{openMenuText}</span>
      </button>
      <section
        ref={flyoutRef}
        aria-labelledby={`cat-label-${categoryId}`}
        className="flyout-panel-section"
        id={`flyout-${categoryId}`}
      >
        <NavFlyoutPanel
          allText={allText}
          brand={brand}
          categoryData={category}
          onBlur={(event) => {
            // If user tabs out of flyout, close flyout
            if (!flyoutRef.current?.contains(event.relatedTarget)) {
              setOpenCategoryId(null);
            }
          }}
          onKeyDown={(event) => {
            // If Escape is pressed, close flyout + move focus back to toggle button
            if (event.key === 'Escape') {
              setOpenCategoryId(null);
              buttonRef.current?.focus();
            }
          }}
          showL3Categories={brand !== 'anf' || (showAnfNavigationL3s ?? false)}
        />
      </section>
    </li>
  );
};

LargeScreenNavBarCategory.propTypes = {
  allText: PropTypes.string.isRequired,
  brand: PropTypes.string.isRequired,
  category: PropTypes.shape({
    categoryId: PropTypes.string,
    espotIdentifier: PropTypes.string,
    isFullWidthFlyout: PropTypes.bool,
    name: PropTypes.string,
    url: PropTypes.string,
    subCategories: PropTypes.arrayOf(
      PropTypes.shape({
        categoryId: PropTypes.string.isRequired,
        displayType: PropTypes.string,
        name: PropTypes.string,
        url: PropTypes.string,
        subCategories: PropTypes.arrayOf(
          PropTypes.shape({
            categoryId: PropTypes.string.isRequired,
            name: PropTypes.string,
            url: PropTypes.string,
          }),
        ),
      }),
    ),
  }).isRequired,
  handleWrappingListItems: PropTypes.func.isRequired,
  hasAemLink: PropTypes.bool.isRequired,
  openCategoryId: PropTypes.string.isRequired,
  openMenuText: PropTypes.string.isRequired,
  setOpenCategoryId: PropTypes.func.isRequired,
  storePreview: PropTypes.string.isRequired,
};

export default LargeScreenNavBarCategory;
