import { gql, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import React from 'react';
import getBrandFamily from '../../tools/getBrandFamily';
import { ERROR_MESSAGE, LOADING_MESSAGE } from '../Messages/Messages';
import SingleAemEspot from '../SingleAemEspot';
import SingleCmcEspot from '../SingleCmcEspot/SingleCmcEspot';

const getStoreAttributeData = gql`
query StoreAttribute {
  config {
    aemLandingExperienceConfig: genericType(name: "aemLandingExperienceConfig")
  }
}
`;

const LandingExperience = ({ categoryId, brand, storePreview }) => {
  const { data: storeAttributeData, loading, error } = useQuery(getStoreAttributeData);

  const brandFamily = getBrandFamily(brand);
  const brandContentEspotId = `${categoryId}-${brand}-BrandContentHomepageHeroEMS`;
  const lifestyleEspotId = `${categoryId}-${brand}-LifestyleTouts`;

  if (loading) return LOADING_MESSAGE;
  if (error) return ERROR_MESSAGE;

  const useBrandContentAem = (storeAttributeData.config.aemLandingExperienceConfig.value || [])
    .map((espotId) => espotId.toLowerCase()).includes(brandContentEspotId.toLowerCase());
  const useLifestyleAem = (storeAttributeData.config.aemLandingExperienceConfig.value || [])
    .map((espotId) => espotId.toLowerCase()).includes(lifestyleEspotId.toLowerCase());

  return (
    <>
      <div className="hero-wrap">
        {useBrandContentAem
          ? <SingleAemEspot espotId={brandContentEspotId} storePreview={storePreview} /> : (
            <SingleCmcEspot
              categoryId={categoryId}
              espotId={`${brandFamily}-BrandContentHomepageHeroEMS`}
            />
          )}
      </div>
      <div className="lifestyle-wrap">
        {useLifestyleAem
          ? <SingleAemEspot espotId={lifestyleEspotId} storePreview={storePreview} />
          : <SingleCmcEspot categoryId={categoryId} espotId={`${brandFamily}-LifestyleTouts`} />}
      </div>
    </>
  );
};

LandingExperience.propTypes = {
  categoryId: PropTypes.string.isRequired,
  brand: PropTypes.string.isRequired,
  storePreview: PropTypes.string.isRequired,
};

export default LandingExperience;
