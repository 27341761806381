import { DropDown } from 'anf-core-react';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useUpdateEffect, useLocalStorage, useEffectOnce } from 'react-use';
import useTranslatedText from '../../hooks/useTranslatedText';
import { PRESERVED_DEPARTMENT_ID } from '../../tools/constants';
import style from './DepartmentSelector.module.scss';

const getDepartmentOptions = (departments) => departments.map(
  (department) => ({ label: department.name, value: department.id }),
);

const getActiveDepartment = (departments, activeDepartmentID) => departments?.filter(
  (department) => department.id === activeDepartmentID,
);

function DepartmentSelector({
  departmentId = 'All',
  elasticDepartmentData = null,
  onDepartmentChange,
  searchTerm = '',
}) {
  const [activeDepartmentID, setActiveDepartmentID] = useState(departmentId !== '' ? departmentId : 'All');
  const [, setPreservedDeptId] = useLocalStorage(PRESERVED_DEPARTMENT_ID, '');

  useEffectOnce(() => {
    setPreservedDeptId(departmentId);
  });

  useUpdateEffect(() => {
    setActiveDepartmentID(departmentId);
  }, [departmentId]);

  const dropdownLabel = useTranslatedText('departmentDropdownLabel', { fallback: 'Shop By' });

  const departments = elasticDepartmentData ?? {};

  if (!departments.length) return null;

  const [activeDepartment] = getActiveDepartment(departments, activeDepartmentID);
  const departmentOptions = getDepartmentOptions(departments);

  const deptChangeHandler = (event) => {
    setActiveDepartmentID(event.target.value);
    onDepartmentChange(event);
    setPreservedDeptId(event.target.value);
  };

  return (
    <div className={style['gender-selector-container']}>
      <form data-testid="department-selector-form">
        <input name="searchTerm" type="hidden" value={searchTerm} />
        <input id="topCategoryId" name="topCategoryId" type="hidden" value={activeDepartment?.defaultCategoryIdBySite || ''} />
        <DropDown
          id="departmentSelection"
          label={dropdownLabel.value}
          name="department-selection"
          onChange={deptChangeHandler}
          options={departmentOptions}
          value={activeDepartmentID !== '' ? activeDepartmentID : 'All'}
        />
      </form>
    </div>
  );
}

export default DepartmentSelector;

DepartmentSelector.propTypes = {
  departmentId: PropTypes.string,
  onDepartmentChange: PropTypes.func.isRequired,
  searchTerm: PropTypes.string,
  elasticDepartmentData: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    defaultCategoryIdBySite: PropTypes.string,
  })),
};
