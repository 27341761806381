import PropTypes from 'prop-types';
import React from 'react';
import style from './DepartmentSelector.module.scss';
import DepartmentSelector from '.';

export default function DepartmentSelectorContainer({
  departmentId,
  elasticDepartmentData,
  intlSearchTerm,
  isDesktop,
  noMatchFoundFor,
  onDepartmentChange,
  parameters,
  showAdditionalControls,
  showCorrectedSearchText,
}) {
  return (
    <>
      {isDesktop && parameters.searchTerm && showAdditionalControls
        ? (
          <DepartmentSelector
            departmentId={(departmentId !== undefined && departmentId !== null
              ? departmentId : parameters.departmentId)}
            elasticDepartmentData={elasticDepartmentData}
            fromLeftRail
            onDepartmentChange={onDepartmentChange}
            searchTerm={intlSearchTerm}
          />
        ) : null}
      {showCorrectedSearchText ? (
        <span className={style.correctedTerm} data-testid="correctedSearchTerm">{noMatchFoundFor.value}</span>
      ) : null}
    </>
  );
}

DepartmentSelectorContainer.propTypes = {
  departmentId: PropTypes.string,
  elasticDepartmentData: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    defaultCategoryIdBySite: PropTypes.string,
  })),
  intlSearchTerm: PropTypes.string,
  isDesktop: PropTypes.bool,
  noMatchFoundFor: PropTypes.shape({
    value: PropTypes.string,
  }),
  onDepartmentChange: PropTypes.func,
  parameters: PropTypes.shape({
    searchTerm: PropTypes.string,
    departmentId: PropTypes.string,
  }),
  showAdditionalControls: PropTypes.bool,
  showCorrectedSearchText: PropTypes.bool,
};
