import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useSessionStorage } from 'react-use';
import useIsSsr from '../../../hooks/useIsSsr';
import { FRANCHISE_PORTAL_SESSION_KEY } from '../../../tools/constants';
import getUPrefShipTo from '../../../tools/getUPrefShipTo';
import $window from '../../../tools/window';
import { LDFLAGS, FRANCHISE_DATA } from '../queries';

/**
 * Gets the data needed to determine if the user should see the franchise Portal
 * based on either their shipTo country or their defaulted store country
 * @param {String} defaultCountry Country code of default site country
 */
const useFranchiseCountryData = (defaultCountry) => {
  const [initialValue, setInitialValue] = useState(false);
  const isSSR = useIsSsr();

  const { data: ldFlagData } = useQuery(LDFLAGS);
  const franchisePortalEligible = ldFlagData?.config?.franchisePortal?.value ?? false;

  useEffect(() => {
    const value = sessionStorage.getItem(FRANCHISE_PORTAL_SESSION_KEY);
    setInitialValue(value);
  }, []);

  const [viewedFranchisePortal, setViewedFranchisePortal] = useSessionStorage(
    FRANCHISE_PORTAL_SESSION_KEY,
    initialValue,
  );
  useEffect(() => {
    const handleFranchiseCountryReset = () => {
      setViewedFranchisePortal(false);
    };

    $window.addEventListener('mfe:resetFranchiseCountry', handleFranchiseCountryReset);

    return () => {
      $window.removeEventListener('mfe:resetFranchiseCountry', handleFranchiseCountryReset);
    };
  }, [setViewedFranchisePortal]);
  const franchiseEligible = franchisePortalEligible && !viewedFranchisePortal;
  const { data, loading, error } = useQuery(FRANCHISE_DATA, {
    skip: !franchiseEligible,
  });
  if (!franchiseEligible || loading || error) {
    if (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching franchise data', error);
    }
    return {
      countryCode: '',
      countryName: '',
      redirectUrl: '',
      showFranchisePortal: false,
      isMultiCountry: false,
      franchiseAllowedCountries: [],
      setViewedFranchisePortal,
    };
  }

  const franchiseData = data?.franchiseCountries || {};
  const { countryFromIP: geoLocationCountryCode, countries } = franchiseData;

  const geoLocationCountry = countries?.find(
    (country) => country.countryCode === geoLocationCountryCode,
  ) || defaultCountry;

  const shipToCountryCode = getUPrefShipTo();
  const shipToCountry = countries?.find(
    (country) => country.countryCode === shipToCountryCode,
  ) || geoLocationCountry;

  /*
    franchiseAllowedCountries is the array of shipTo countries for which the franchise
    portal is allowed (in this case, with respect to the country the user's IP is in.)
  */
  const franchiseAllowedCountries = geoLocationCountry?.franchiseAllowedCountries ?? [];

  /*
    We ultimately determine if the user should see the franchise portal based on
    (1) if franchise portal is enabled overall for their geoLocated country and
    (2) if their "shipToCountry" (which, if they haven't manually chosen one, is
    their geoLocated one) is in the list of countries for which the portal is
    enabled in their geoLocated country.
  */
  const showFranchisePortal = geoLocationCountry?.hasFranchisePortal
    && franchiseAllowedCountries
      .some((allowedCountry) => allowedCountry === shipToCountry.countryCode)
      && !isSSR;

  return {
    countryCode: shipToCountry?.countryCode ?? '',
    countryName: shipToCountry?.name ?? '',
    redirectUrl: geoLocationCountry?.redirectUrl ?? '',
    showFranchisePortal,
    isMultiCountry: franchiseAllowedCountries.length > 1,
    setViewedFranchisePortal,
  };
};

export default useFranchiseCountryData;
