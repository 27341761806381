import PropTypes from 'prop-types';
import React from 'react';
import useTranslatedText from '../../hooks/useTranslatedText';
import listStyles from './SearchDefaultView.module.scss';

export default function SearchDefaultList({
  clearAllButtonHandler = () => {},
  dataType,
  eventHandler,
  hasClearAllButton = false,
  headingLabel,
  options,
}) {
  const clearAll = useTranslatedText('clearAll', { fallback: 'clear all' });

  return (
    <div key={dataType} className={listStyles.listContainer} data-testid="auto-suggest-list-menu-wrapper">
      <h2 className="h3">{headingLabel}</h2>
      <ul
        className="auto-suggest-list-menu"
        data-testid={`${dataType.replace(/\s/g, '-')}-button-group`}
        data-type={dataType}
      >
        {options?.map((option) => (
          <li
            key={option.key}
            aria-label={option.title}
            aria-selected={false}
            className="suggestion-list-item"
            data-type={dataType}
            data-url={option.url ?? ''}
            data-value={option.title}
            onClick={eventHandler}
            onKeyDown={eventHandler}
            role="option"
            tabIndex={0}
          >
            <span aria-hidden="true" className="search-item">
              <span className="list-item-content">
                <span className="auto-suggest-list-item-label">
                  {option.title}
                  <span className="link-arrow" />
                </span>
              </span>
            </span>
          </li>
        ))}
        {hasClearAllButton
          && (
          <li
            key="clear-all"
            aria-label={clearAll.value}
            aria-selected={false}
            className="suggestion-list-item"
            data-value={clearAll.value}
            onClick={clearAllButtonHandler}
            onKeyDown={clearAllButtonHandler}
            role="option"
            tabIndex={0}
          >
            <span aria-hidden="true" className="search-item">
              <span className="list-item-content">
                <span className="auto-suggest-list-item-label">
                  <u>
                    {clearAll.value}
                  </u>
                  <span className="link-arrow" />
                </span>
              </span>
            </span>
          </li>
          )}
      </ul>
    </div>
  );
}

SearchDefaultList.propTypes = {
  dataType: PropTypes.string.isRequired,
  headingLabel: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      title: PropTypes.string.isRequired,
      url: PropTypes.string,
    }),
  ),
  hasClearAllButton: PropTypes.bool,
  clearAllButtonHandler: PropTypes.func,
  eventHandler: PropTypes.func.isRequired,
};
