import { ACTION } from '../../../tools/constants';

/**
 * Syncs the resolved search term with the state search term with Action SEARCHTERM
 * @param {string} resolvedSearchTerm - Search term from the response
 * @param {string} searchTerm - Search term from the state
 * @param {function} dispatch - Dispatch function
 */
const updateSearchTermToSuggested = (resolvedSearchTerm, searchTerm, dispatch) => {
  if (resolvedSearchTerm !== searchTerm) {
    dispatch({
      type: ACTION.SEARCHTERM,
      payload: { searchTerm: resolvedSearchTerm },
    });
  }
};

export default updateSearchTermToSuggested;
