import { gql, useQuery } from '@apollo/client';
import { HeadingBanner, Disclosure } from 'anf-core-react';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { ERROR_MESSAGE, LOADING_MESSAGE } from '../Messages/Messages';

const query = gql`
  query GETCATEGORIES {
    textFor {
      pageTitle: pair(pairKey: "siteMap") {...textPair}
    }
    categoriesList {
      ...CategoryFields
      ...CategoriesRecursive
    }
  }

  fragment CategoryFields on Category {
    categoryId
    name
    url
    brand
  }

  fragment CategoriesRecursive on Category {
    subCategories {
      ...CategoryFields
      subCategories {
        ...CategoryFields
        subCategories {
          ...CategoryFields
        }
      }
    }
  }

  fragment textPair on TextPair {
    key
    value
  }
`;

function List({ categoriesList }) {
  return categoriesList.map((category) => (
    <li key={category.categoryId} className="level1Category">
      <HeadingBanner>
        <a href={category.url}>
          <h2 className="h2">
            {category.brand && category.brand !== '??????' ? `${category.brand} - ${category.name}` : category.name}
          </h2>
        </a>
      </HeadingBanner>
      <ul className="level2CategoryWrapper">
        {category.subCategories.map((item) => <ListItem key={item.categoryId} className="level2Category" item={item} />)}
      </ul>
    </li>
  ));
}

function ListItem({ item, className }) {
  let children;
  const [isExpanded, setIsExpanded] = useState(false);
  const clickHandler = () => setIsExpanded(!isExpanded);
  if (item.subCategories?.length) {
    children = (
      <Disclosure
        headingPrefix={<a href={item.url}>{item.name}</a>}
        id={`button-${item.categoryId}`}
        isExpanded={isExpanded}
        label=""
        onClick={clickHandler}
      >
        <ul className="levelCategoryWrapper">
          {item.subCategories.map((subCategory) => (
            <ListItem key={subCategory.categoryId} className="levelCategory" item={subCategory} />
          ))}
        </ul>
      </Disclosure>
    );
  } else {
    children = (
      <a href={item.url}>
        {item.name}
      </a>
    );
  }

  return (
    <li className={className}>
      {children}
    </li>
  );
}

export default function SiteMapPage() {
  const { loading, error, data } = useQuery(query);
  if (loading) return LOADING_MESSAGE;
  if (error) return ERROR_MESSAGE;
  return (
    <main className="siteMap">
      <div className="sitemapSection">
        <HeadingBanner variant="boxed">
          <h1 className="h1" data-text-key={data.textFor.pageTitle.key}>
            {data.textFor.pageTitle.value}
          </h1>
        </HeadingBanner>
        <div>
          <ul className="allSiteMapList" data-testid="sitemap">
            <List categoriesList={data.categoriesList} />
          </ul>
        </div>
      </div>
    </main>
  );
}

ListItem.propTypes = {
  item: PropTypes.oneOfType([PropTypes.object]).isRequired,
  className: PropTypes.string.isRequired,
};
