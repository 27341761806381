import { useContext, useRef, useState } from 'react';
import BreakpointContext from '../../../context/breakpoint';
import DigitalDataContext from '../../../context/digitalData';
import { IMAGE_SIZE_POLICY } from '../../../tools/constants';
import getImageUrl from '../../../tools/getImageUrl';
import loadImages from '../../../tools/loadImages';
import useFirstScrollIntoView from '../../../tools/useFirstScrollIntoView';
import { DD_DISABLE_SWATCH_HOVER } from '../../DigitalDataProvider';
import getSwatchLimit from './getSwatchLimit';

const useImagePreloading = (swatchList, imageHostName, imageSet, setIsHovered, setHeight) => {
  const [imagesLoaded, setImagesLoaded] = useState(false);
  // Swatch images should only load if breakpoint is >= large
  const { large: isDesktop } = useContext(BreakpointContext);
  const swatchLimit = getSwatchLimit(isDesktop);

  // Don't preload images for swatch hover if it is disabled
  const {
    [DD_DISABLE_SWATCH_HOVER]: hasSwatchHover,
  } = useContext(DigitalDataContext);

  // On first scroll into view:
  // Load all swatch tile images
  // Load first `swatchLimit` swatch PFO images
  // Load product's PFO and PH images
  const { ref: productCardRef } = useFirstScrollIntoView(
    imageHostName
      ? async () => {
        const swatchImageUrls = [];
        if (isDesktop) {
          swatchList.forEach((swatch, index) => {
            swatchImageUrls.push(getImageUrl(
              imageHostName,
              swatch.swatchImage,
              IMAGE_SIZE_POLICY.XSMALL,
            ));
            if (!hasSwatchHover && index < swatchLimit) {
              swatchImageUrls.push(
                getImageUrl(
                  imageHostName,
                  swatch.product.imageSet.primaryFaceOutImage,
                  IMAGE_SIZE_POLICY.MEDIUM,
                ),
              );
            }
          });
        }
        const primaryFaceOutUrls = [
          getImageUrl(imageHostName, imageSet.primaryFaceOutImage, IMAGE_SIZE_POLICY.MEDIUM),
        ];
        const secondaryImageUrls = [
          getImageUrl(imageHostName, imageSet.primaryHoverImage, IMAGE_SIZE_POLICY.MEDIUM),
          ...swatchImageUrls,
        ];
        Promise.allSettled([
          loadImages(primaryFaceOutUrls, 'High'),
          loadImages(secondaryImageUrls),
        ]).then(() => setImagesLoaded(true));
      }
      : null,
  );

  // On first hover or focus, load PFO images for remaining swatches
  const hasPreloadedAdditionalPrimaryImages = useRef(false);
  const preloadAdditionalPrimaryImages = async () => {
    if (isDesktop && !hasPreloadedAdditionalPrimaryImages.current && imageHostName) {
      if (!hasSwatchHover) {
        hasPreloadedAdditionalPrimaryImages.current = true;
        const swatchImageUrls = swatchList.slice(Math.min(swatchLimit, swatchList.length))
          .map((swatch) => getImageUrl(
            imageHostName, swatch.product.imageSet.primaryFaceOutImage, IMAGE_SIZE_POLICY.MEDIUM,
          ));
        await loadImages(swatchImageUrls);
      }
      setHeight(productCardRef.current.scrollHeight);
    }
  };

  const setIsHoveredWithImagePreloading = (value) => {
    if (value) {
      preloadAdditionalPrimaryImages();
    }
    setIsHovered(value);
  };

  return {
    productCardRef, setIsHoveredWithImagePreloading, imagesLoaded,
  };
};

export default useImagePreloading;
