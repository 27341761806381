import { ProductDetail } from 'anf-core-react';
import PropTypes from 'prop-types';
import React, { useContext, Fragment } from 'react';
import DigitalDataContext from '../../context/digitalData';
import { DD_HYPERLINK_DESC } from '../DigitalDataProvider';
import filterHyperlinkShortDescriptors from './tools/filterHyperlinkShortDescriptors';
import getDepartmentWhitelist from './tools/hyperlinkShortDescriptorsSplitTest';

const ProductCardShortDescriptor = ({
  brand = '',
  departmentName = '',
  shortDescriptors,
}) => {
  const { [DD_HYPERLINK_DESC]: hyperlinkShortDescriptorsFlag } = useContext(DigitalDataContext);

  if (hyperlinkShortDescriptorsFlag) {
    const whitelist = getDepartmentWhitelist(brand, departmentName, hyperlinkShortDescriptorsFlag);
    const filteredHyperlinkShortDescriptors = filterHyperlinkShortDescriptors(
      shortDescriptors, whitelist,
    );

    if (filterHyperlinkShortDescriptors.length > 0) {
      /**
       * Using the `text` as a key is problematic iff the same short descriptor is entered
       * for both shortDescriptor1 & shortDescriptor2 in PIM
       * This makes the assumption that that will never be the case
       */
      const shortDescriptorContent = filteredHyperlinkShortDescriptors.map(
        ({ text, url }, index) => (
          <Fragment key={text}>
            {index > 0 && ' | '}
            <a href={url}>
              {text}
            </a>
          </Fragment>
        ),
      );

      return (
        <ProductDetail variant="small">
          {shortDescriptorContent}
        </ProductDetail>
      );
    }
    return null;
  }
  return (
    <ProductDetail variant="small">
      {shortDescriptors.join(' | ')}
    </ProductDetail>
  );
};

ProductCardShortDescriptor.propTypes = {
  brand: PropTypes.string,
  departmentName: PropTypes.string,
  shortDescriptors: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ProductCardShortDescriptor;
