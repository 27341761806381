import PropTypes from 'prop-types';
import React from 'react';
import style from './SkeletonGrid.module.scss';

const SkeletonProductGrid = ({ cardAmount }) => {
  const cardLayouts = [...Array(cardAmount)].map((_, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <div key={`skeleton_card_${index}`} className={style.cardWrapper}>
      <div className={`${style.img} ${style.skeleton}`} />
      <div className={style.swatchWrapper}>
        <div className={`${style.swatches} ${style.skeleton}`} />
        <div className={`${style.swatches} ${style.skeleton}`} />
        <div className={`${style.swatches} ${style.skeleton}`} />
      </div>
      <div className={`${style.text} ${style.skeleton}`} style={{ height: '10px' }} />
      <div className={`${style.text} ${style.skeleton}`} style={{ width: '30%' }} />
      <div className={`${style.text} ${style.skeleton}`} style={{ width: '20%' }} />
    </div>
  ));

  return (
    <section className={style.grid} data-testid="skeleton-grid">
      {cardLayouts}
    </section>
  );
};

SkeletonProductGrid.propTypes = {
  cardAmount: PropTypes.number.isRequired,
};

export default SkeletonProductGrid;
