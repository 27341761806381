import { gql } from '@apollo/client';

export const SUGGESTION_DATA = gql`
  fragment SuggestionData on SuggestedItemsV3 {
    departmentIdentifier
    departmentName
    name
    suggestedTerm
  }
`;
export default SUGGESTION_DATA;
