import $window from '../../../tools/window';

/**
 * Trigger a search_sort_applied event in the digitalData layer
 * @param {String} sortType - The type of sort applied
 * @returns {void}
 */
const triggerSortAnalytics = (sortType = '') => {
  $window.digitalData.trigger('search_sort_applied', {
    event_name: 'sort_applied',
    event_type: 'sort',
    search_filter_applied_value_name: sortType,
    tealium_event: 'sort_applied',
  });
};

export default triggerSortAnalytics;
