import { gql, useQuery } from '@apollo/client';
import Logo from 'anf-core-react/components/Logo';
import Toaster from 'anf-core-react/components/Toaster';
import PropTypes from 'prop-types';
import React from 'react';
import useTranslatedText from '../../hooks/useTranslatedText';
import getBrandFamily from '../../tools/getBrandFamily';
import SmallScreenNavCategories from '../SmallScreenNavCategories';
import SmallScreenNavFooter from '../SmallScreenNavFooter/SmallScreenNavFooter';
import style from './SmallScreenNav.module.scss';

const getOtherBrands = gql`
  query OtherBrands($filter: BrandsFilter) {
    otherBrandsInFamily: brands(filter: $filter) {
      description
      id
      url
    }
  }
`;

const SmallScreenNav = ({
  brand,
  defaultCountry = '',
  handleNavToggle,
  l0CategoryId,
  navOpen,
}) => {
  const brandFamily = getBrandFamily(brand);
  const { data } = useQuery(getOtherBrands, {
    skip: brandFamily !== 'anf',
    variables: {
      filter: {
        id: { ne: brand },
        ...brand === 'anf'
          ? { region: { nin: ['AM', 'CN'] } }
          : {},
      },
    },
  });
  const { value: closeMenuText } = useTranslatedText('closeMenu', { fallback: 'Close' });
  const { value: mainMenuText } = useTranslatedText('mainMenuLabel', { fallback: 'Main menu' });

  return (
    <Toaster
      closeButtonLabel={closeMenuText}
      direction="from-left"
      id="catalogNavToaster"
      isFullBleed
      isOpen={navOpen}
      onClose={handleNavToggle}
    >
      <div className={style.toasterContent}>
        <nav aria-label={mainMenuText} className="scope-1892">
          <SmallScreenNavCategories
            l0CategoryId={l0CategoryId}
          />
          {
            data?.otherBrandsInFamily?.length > 0
              && (
                <div className={style.otherBrandsInFamily}>
                  {data?.otherBrandsInFamily?.map(({ description, id, url }) => (
                    <a key={id} href={url}>
                      <Logo kind={id} />
                      <span className="screen-reader-text">{description}</span>
                    </a>
                  ))}
                </div>
              )
          }
        </nav>
        <div className={style.footerToaster}>
          <SmallScreenNavFooter defaultCountry={defaultCountry} />
        </div>
      </div>
    </Toaster>
  );
};

SmallScreenNav.propTypes = {
  brand: PropTypes.string.isRequired,
  defaultCountry: PropTypes.string,
  handleNavToggle: PropTypes.func.isRequired,
  l0CategoryId: PropTypes.string.isRequired,
  navOpen: PropTypes.bool.isRequired,
};

export default SmallScreenNav;
