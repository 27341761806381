import PropTypes from 'prop-types';
import React from 'react';
import priceType from '../types/priceType';
import promoMessageType from '../types/promoMessageType';
import ProductCardBadges from './ProductCardBadges';
import ProductCardDetails from './ProductCardDetails';
import CardLink from './ProductCardLink';
import ShortDescriptor from './ProductCardShortDescriptor';
import style from './productCard.module.scss';

const ProductDetailsSection = ({
  badges,
  memberPrice,
  price,
  productName,
  promoMessaging,
  shortDescriptors,
  brand,
  departmentName,
  showHyperlinkShortDescriptors,
  handleProductCardClick,
  productPageUrl,
}) => (
  <div className={style['product-details-wrapper']}>
    <CardLink onClick={handleProductCardClick} url={productPageUrl}>
      <ProductCardBadges badges={badges ?? []} />
      <ProductCardDetails
        memberPrice={memberPrice}
        price={price}
        productName={productName}
        promoMessaging={promoMessaging}
      />
      {!showHyperlinkShortDescriptors && shortDescriptors?.length > 0 && (
        <ShortDescriptor
          brand={brand}
          departmentName={departmentName}
          shortDescriptors={shortDescriptors}
        />
      )}
    </CardLink>
    {showHyperlinkShortDescriptors && shortDescriptors?.length > 0 && (
      <ShortDescriptor
        brand={brand}
        departmentName={departmentName}
        shortDescriptors={shortDescriptors}
      />
    )}
  </div>
);

export default ProductDetailsSection;

ProductDetailsSection.propTypes = {
  badges: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    theme: PropTypes.string,
  })),
  memberPrice: priceType,
  price: priceType,
  productName: PropTypes.string,
  promoMessaging: promoMessageType,
  shortDescriptors: PropTypes.arrayOf(PropTypes.string),
  brand: PropTypes.string,
  departmentName: PropTypes.string,
  showHyperlinkShortDescriptors: PropTypes.bool,
  handleProductCardClick: PropTypes.func,
  productPageUrl: PropTypes.string,
};
