import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import React from 'react';
import GET_MFE_COMPONENT from './queries';

const StandaloneMfe = ({
  componentName, includeScript = false, mfeName, ttl, page,
}) => {
  const { data } = useQuery(GET_MFE_COMPONENT, {
    variables: {
      componentName, includeScript, mfeName, ttl, page,
    },
  });

  if (!data?.standaloneMfe?.componentHtml) {
    return null;
  }

  return (
    // eslint-disable-next-line react/no-danger
    <div dangerouslySetInnerHTML={{ __html: data.standaloneMfe.componentHtml }} data-testid={`${mfeName}-${componentName}`} />
  );
};

StandaloneMfe.propTypes = {
  componentName: PropTypes.string.isRequired,
  includeScript: PropTypes.bool,
  mfeName: PropTypes.string.isRequired,
  ttl: PropTypes.number,
  page: PropTypes.string,
};

export default StandaloneMfe;
