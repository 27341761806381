import { gql, useQuery } from '@apollo/client';
import Icon from 'anf-core-react/components/Icon';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import FooterLink from './FooterLink';
import OneTrustModalLink from './OneTrustModalLink';
import style from './footer.module.scss';

const getLinks = gql`
  query Links {
    footer(isDesktop: true) {
      id
      utilityLinks {
        url
        title
        id
        external
      }
    }
  }
`;

const FooterUtility = ({ isAnf }) => {
  const { data } = useQuery(getLinks);

  if (!data) return null;
  const {
    utilityLinks,
  } = data?.footer || {};
  return (
    <div className={style.footerUtility} data-testid="footer-utility">
      <hr className={classNames(style.hr, 'hr')} />
      <div className={style.footerUtilityInner}>
        {isAnf
    && (
      <div className={style.moose} data-testid="moose">
        <Icon
          icon="moose"
          size="s"
        />
      </div>
    )}
        {utilityLinks && (
          <ul className={style.utilityLinks}>
            {utilityLinks.map((link) => (
              <li key={link.id}>
                {link.id === 'personalInfo' ? <OneTrustModalLink link={link} /> : <FooterLink link={link} />}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

FooterUtility.propTypes = {
  isAnf: PropTypes.bool.isRequired,
};

export default FooterUtility;
