import getPageType from '../../../tools/getPageType';
import runAnalyticsForQuickView from '../../../tools/runAnalyticsForQuickView';
import $window from '../../../tools/window';

const dataText = 'swatch_hover';
const eventType = 'click';

const triggerDigitalData = (eventName, eventDetails = {}) => {
  $window.digitalData.trigger(eventName, eventDetails);
};

const setDigitalData = (dataKey, data) => {
  $window.digitalData.set(dataKey, data);
};

export const triggerHoverAnalytics = () => {
  triggerDigitalData(`${getPageType()}_${dataText}_${eventType}`, {
    event_type: eventType,
    data_text: dataText,
    data_action: 'hover',
  });
};

export const triggerQuickviewAnalytics = ({
  algonomyOnClickAnalytics,
  categoryId,
  isMiniGrid,
  socialProofMessage,
}) => {
  runAnalyticsForQuickView(algonomyOnClickAnalytics);

  if (socialProofMessage) {
    setDigitalData('product.afSource', 'social proofing');
  } else {
    // Unset data in case it was set previously
    setDigitalData('product.afSource', undefined);
  }

  if (isMiniGrid && categoryId) {
    setDigitalData('product.productViewMethod', `mini grid - ${categoryId}`);
    setDigitalData('page.pageViewMethod', 'modal - quick view');

    const analyticsQVEvent = new CustomEvent('analytics.quick_view');
    triggerDigitalData(analyticsQVEvent);
  }
};

export const triggerMiniGridAnalytics = (categoryId) => {
  setDigitalData('product.productViewMethod', `mini grid - ${categoryId}`);
  setDigitalData('page.pageViewMethod', 'page load');

  const analyticsPDEvent = new CustomEvent('analytics.product_detail');

  triggerDigitalData(analyticsPDEvent);
};
