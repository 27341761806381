import { useQuery } from '@apollo/client';
import { Button } from 'anf-core-react';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import SearchResultsContext from '../../context/SearchResultsContext';
import ShopSimilarContext from '../../context/ShopSimilarContext';
import useFetchMySaves from '../../hooks/useFetchMySaves';
import useTranslatedText from '../../hooks/useTranslatedText';
import DomNodePortal from '../../tools/DomNodePortal';
import TmntHtml from '../../tools/TmntHtml';
import { PDP_SHOP_SIMILAR_MAX_ROWS, SIMILAR_DEFAULT_RECIPE } from '../../tools/constants';
import ProductCard from '../ProductCard';
import ProductGrid from '../ProductGrid/ProductGrid';
import SkeletonProductGrid from '../ProductGrid/SkeletonGrid';
import { SIMILAR_GRID_RESULTS, SIMILAR_CATEGORY_URL } from './queries';
import style from './styles/similarGridContent.module.scss';
import removeLastAttribute from './tools/removeLastAttribute';

const SimilarGridContent = ({
  brand = '',
  similarCategoryData = {
    id: '',
    name: '',
  },
}) => {
  const {
    containerId,
    rows,
    facet,
    similarRecipeId,
    productData,
    shopSimilarTerms,
  } = useContext(ShopSimilarContext);

  const {
    countryFulfillmentStore,
    departmentId,
  } = useContext(SearchResultsContext);

  const { mySavesItems, refetch } = useFetchMySaves();

  const shopAllL3 = useTranslatedText('shopAllL3', { fallback: `Shop All ${similarCategoryData?.name}`, replacements: [similarCategoryData?.name] });

  const { data } = useQuery(SIMILAR_CATEGORY_URL, {
    variables: {
      categoryId: similarCategoryData.id,
    },
    skip: !similarCategoryData.id || productData?.shopAllUrl,
  });

  const shopAllUrl = productData?.shopAllUrl ?? data?.category?.url;
  const initialSearchTerm = similarRecipeId === SIMILAR_DEFAULT_RECIPE
    || !shopSimilarTerms[similarRecipeId]
    ? shopSimilarTerms?.style
    : shopSimilarTerms[similarRecipeId];

  let searchTerm = initialSearchTerm;

  const { data: similarSearchResults, loading } = useQuery(SIMILAR_GRID_RESULTS, {
    variables: {
      searchTerm: initialSearchTerm,
      brand,
      countryFulfillmentStore,
      departmentId,
      facet,
      rows,
      start: '0',
    },
    skip: !initialSearchTerm,
  });

  const getProductCards = (products) => products.map((product) => {
    const {
      badges,
      collection,
      defaultSwatchSequence,
      departmentName,
      id,
      imageSet,
      kic,
      memberPrice,
      name,
      price,
      productPageUrl,
      promoMessaging,
      shortDescriptors,
      swatchList,
    } = product;
    return (
      <ProductCard
        key={id}
        mySavesItems={mySavesItems}
        product={{
          badges,
          collection,
          defaultSwatchSequence,
          departmentName,
          id,
          imageSet,
          kic,
          memberPrice,
          name,
          price,
          productPageUrl,
          promoMessaging,
          shortDescriptors,
          swatchList,
        }}
        refetch={refetch}
      />
    );
  });

  if (!shopSimilarTerms || !similarSearchResults) return null;

  if (similarSearchResults?.searchResults?.productTotalCount < 10) {
    searchTerm = removeLastAttribute(initialSearchTerm);
  }
  if (!searchTerm || similarSearchResults?.searchResults?.products.length === 0) return null;
  if (loading) return <SkeletonProductGrid cardAmount={16} />;

  return (
    <DomNodePortal targetNodeSelector={`#${containerId}`}>
      <div className="scope-1892">
        <span style={{ display: 'none' }}>{searchTerm}</span>
        <ProductGrid breakpoint="large" desktopColumnCount={4} mobileColumnCount={2}>
          {getProductCards(similarSearchResults?.searchResults?.products)}
        </ProductGrid>
        {shopAllUrl
          && similarSearchResults?.searchResults?.productTotalCount > PDP_SHOP_SIMILAR_MAX_ROWS
          && (
            <div className={style.wrapper}>
              <Button
                classList={style.viewMore}
                href={shopAllUrl}
                variant="tertiary-dark"
              >
                <TmntHtml
                  property={shopAllL3?.key || 'GLB_VIEW_MORE'}
                  value={shopAllL3?.value || `Shop All ${similarCategoryData.name}`}
                />
              </Button>
            </div>
          )}
      </div>
    </DomNodePortal>
  );
};

SimilarGridContent.propTypes = {
  brand: PropTypes.string,
  similarCategoryData: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
};

export default SimilarGridContent;
