import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { useWindowSize } from 'react-use';
import useStickyIntersection from '../../hooks/useStickyIntersection';
import DomNodePortal from '../../tools/DomNodePortal';
import BreakpointProvider from '../BreakpointProvider';
import CategoryGridWrapper from '../CategoryGridWrapper/CategoryGridWrapper';
import DigitalDataProvider, {
  DD_DISABLE_SWATCH_HOVER,
  DD_HYPERLINK_DESC,
  DD_MODEL_IMAGERY_TEST,
  DD_PAGINATION_TEST,
} from '../DigitalDataProvider';
import FacetsLeftRail from '../FacetsLeftRail';
import GroupedCategoryGridWrapper from '../GroupedCategoryGridWrapper';
import CategoryGridHeader from '../ProductGridHeader/CategoryGridHeader';
import ProductGridScrollHandler from '../ProductGridScrollHandler';
import StickyRefineBar from '../Refine/StickyRefineBar';
import ScrollToTop from '../ScrollToTop/ScrollToTop';
import searchPageStyle from '../SearchMainContent/SearchMainContent.module.scss';
import StoreDetailsProvider from '../StoreDetailsProvider/StoreDetailsProvider';
import StoreToggle from '../StoreToggle';
import { categoryGridProductType } from '../types';
import CategoryBottomEspot from './CategoryBottomEspot';
import CategoryHeroEspot from './CategoryHeroEspot';
import CategoryMarketingCampaignEspot from './CategoryMarketingCampaignEspot';
import style from './CategoryPage.module.scss';
import GridCategoryEndEspot from './GridCategoryEndEspot';

const Main = ({
  appendProductData,
  brand,
  categoryData,
  grouped,
  handleCheckBoxChange,
  handleLoadMoreButtonClick,
  handleLoadPreviousButtonClick,
  handleProductData,
  handleSortChange,
  handleStoreFilter,
  hasCatalogMfeEspots,
  hasShopMyStoreEnabled,
  intlCategoryId,
  isUnifiedCategoryPage,
  onClearAllBtnClick,
  onClearFacetTag,
  onPaginationButtonClick,
  pageBottomStartParameter,
  pageTopStartParameter,
  parameters,
  prependProductData,
  productData,
  seqSlot,
  store,
  storeDetails,
  storeId,
}) => {
  const gridWrapper = useRef();
  const [productTotalCount, setProductTotalCount] = useState(0);
  const { width } = useWindowSize();
  const gridIntersecting = useStickyIntersection(gridWrapper);

  const {
    breadcrumbTrailEntryView,
    facets: facetData,
    leftRailCatNavFlag,
    navParentCategoryId,
    name,
    popularSearches,
    sortData,
    title,
    url,
  } = categoryData;
  const {
    categoryId, facet, filter, rows, sort, start,
  } = parameters;
  const renderFacets = facetData?.length > 0;
  const isFacetSelected = parameters.facet?.length > 0;
  const isDesktop = width > 1025;
  const isStickyBarShowing = gridIntersecting && !isDesktop;

  const handleProductCount = (count) => {
    if (count !== undefined) {
      setProductTotalCount(count);
      // dispatch to CRS to update ANF object there
      window.dispatchEvent(new CustomEvent('mfe:updateProductCount', { detail: count }));
    }
  };

  const component = (
    <StoreDetailsProvider store={store} storeId={storeId}>
      <BreakpointProvider>
        <DigitalDataProvider
          keys={[
            DD_DISABLE_SWATCH_HOVER,
            DD_HYPERLINK_DESC,
            DD_MODEL_IMAGERY_TEST,
            DD_PAGINATION_TEST,
          ]}
        >
          {hasCatalogMfeEspots && (
            <>
              <CategoryHeroEspot brand={brand} categoryId={categoryId} />
              <CategoryMarketingCampaignEspot brand={brand} categoryId={categoryId} />
            </>
          )}
          <ProductGridScrollHandler parameters={parameters}>
            {!isUnifiedCategoryPage && (
              <>
                <DomNodePortal targetNodeSelector=".store-toggle-container-target">
                  {hasShopMyStoreEnabled && (
                    <>
                      <StoreToggle
                        handleStoreFilter={handleStoreFilter}
                        isShopMyStore={filter !== ''}
                        storeDetails={storeDetails}
                      />
                      <hr />
                    </>
                  )}
                </DomNodePortal>
                <DomNodePortal targetNodeSelector=".sort-and-filter-facet-container">
                  {renderFacets && (
                    <FacetsLeftRail
                      brand={brand}
                      facet={facet}
                      facetData={facetData}
                      isCategoryPage
                      onCheckBoxChange={handleCheckBoxChange}
                      popularSearches={popularSearches}
                    />
                  )}
                </DomNodePortal>
              </>
            )}
            <div className={searchPageStyle.gridHeaderPlaceholder}>
              <CategoryGridHeader
                brand={brand}
                categoryId={intlCategoryId}
                categoryName={name}
                categoryTitle={title}
                categoryUrl={url}
                facet={facet}
                facetData={facetData}
                filter={filter}
                handleStoreFilter={handleStoreFilter}
                hasSelectedCategoryFilters={filter !== '' || facet?.length > 0}
                hasShopMyStoreEnabled={hasShopMyStoreEnabled}
                isCategoryPage
                isFacetSelected={isFacetSelected}
                mobileGridHeaderFlag={leftRailCatNavFlag}
                navParentCategoryId={navParentCategoryId}
                onCheckBoxChange={handleCheckBoxChange}
                onClearAllBtnClick={onClearAllBtnClick}
                onFacetTagClick={onClearFacetTag}
                onSortChange={handleSortChange}
                resultsCount={productTotalCount}
                selectedSort={sort || sortData.defaultSortOption}
                sortData={sortData}
                storeDetails={storeDetails}
              />
            </div>
            <div ref={gridWrapper}>
              {isStickyBarShowing && (
                <StickyRefineBar
                  brand={brand}
                  breadcrumbTrailEntryView={breadcrumbTrailEntryView}
                  facetData={facetData}
                  isCategoryPage
                  isFacetSelected={isFacetSelected}
                  onCheckBoxChange={handleCheckBoxChange}
                  onClearAllBtnClick={onClearAllBtnClick}
                  onSortChange={handleSortChange}
                  resultsCount={productTotalCount}
                  selectedSort={parameters.sort || sortData.defaultSortOption}
                  sortData={sortData}
                />
              )}
              {grouped ? (
                <GroupedCategoryGridWrapper
                  key={`${categoryId}-Grouped-Page`}
                  categoryId={categoryId}
                  facet={facet}
                  filter={filter}
                  handleProductCount={handleProductCount}
                  isShopMyStore={parameters.filter !== ''}
                  onPaginationButtonClick={onPaginationButtonClick}
                  rows={rows}
                  seqSlot={seqSlot}
                  sort={sort}
                  start={start}
                />
              ) : (
                <CategoryGridWrapper
                  key={`${categoryId}-Page`}
                  categoryId={categoryId}
                  facet={facet}
                  filter={filter}
                  handleProductCount={handleProductCount}
                  isShopMyStore={parameters.filter !== ''}
                  onPaginationButtonClick={onPaginationButtonClick}
                  rows={rows}
                  seqSlot={seqSlot}
                  sort={sort}
                  start={start}
                  // Load More test props
                  /* eslint-disable react/jsx-sort-props */
                  appendProductData={appendProductData}
                  handleLoadMoreButtonClick={handleLoadMoreButtonClick}
                  handleLoadPreviousButtonClick={handleLoadPreviousButtonClick}
                  handleProductData={handleProductData}
                  pageBottomStartParameter={pageBottomStartParameter}
                  pageTopStartParameter={pageTopStartParameter}
                  prependProductData={prependProductData}
                  productData={productData}
                />
              )}
            </div>
            {hasCatalogMfeEspots && (
              <>
                <CategoryBottomEspot brand={brand} categoryId={categoryId} />
                <GridCategoryEndEspot categoryId={categoryId} brand={brand} />
              </>
            )}
            {isUnifiedCategoryPage && <ScrollToTop />}
          </ProductGridScrollHandler>
        </DigitalDataProvider>
      </BreakpointProvider>
    </StoreDetailsProvider>
  );

  if (isUnifiedCategoryPage) {
    return <main className={style.row_right}>{component}</main>;
  }

  return component;
};

Main.propTypes = {
  appendProductData: PropTypes.func.isRequired,
  brand: PropTypes.string.isRequired,
  categoryData: PropTypes.shape({
    breadcrumbTrailEntryView: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    categoryId: PropTypes.string,
    facets: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    leftRailCatNavFlag: PropTypes.bool,
    name: PropTypes.string,
    navParentCategoryId: PropTypes.string,
    popularSearches: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    searchURL: PropTypes.string,
    sortData: PropTypes.shape({
      defaultSortOption: PropTypes.string,
      sortOptions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          value: PropTypes.string,
        }),
      ),
    }),
    title: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  grouped: PropTypes.bool.isRequired,
  handleCheckBoxChange: PropTypes.func.isRequired,
  handleLoadMoreButtonClick: PropTypes.func.isRequired,
  handleLoadPreviousButtonClick: PropTypes.func.isRequired,
  handleProductData: PropTypes.func.isRequired,
  handleSortChange: PropTypes.func.isRequired,
  handleStoreFilter: PropTypes.func.isRequired,
  hasCatalogMfeEspots: PropTypes.bool.isRequired,
  hasShopMyStoreEnabled: PropTypes.bool.isRequired,
  intlCategoryId: PropTypes.string.isRequired,
  isUnifiedCategoryPage: PropTypes.bool.isRequired,
  onClearAllBtnClick: PropTypes.func.isRequired,
  onClearFacetTag: PropTypes.func.isRequired,
  onPaginationButtonClick: PropTypes.func.isRequired,
  pageBottomStartParameter: PropTypes.number.isRequired,
  pageTopStartParameter: PropTypes.number.isRequired,
  parameters: PropTypes.shape({
    categoryId: PropTypes.string,
    facet: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
    filter: PropTypes.string,
    rows: PropTypes.string,
    sort: PropTypes.string,
    start: PropTypes.string,
  }).isRequired,
  prependProductData: PropTypes.func.isRequired,
  productData: PropTypes.arrayOf(categoryGridProductType),
  seqSlot: PropTypes.string.isRequired,
  store: PropTypes.string.isRequired,
  storeDetails: PropTypes.shape({
    storeName: PropTypes.string,
    storeId: PropTypes.string,
  }),
  storeId: PropTypes.string.isRequired,
};

export default Main;
