import { useQuery } from '@apollo/client';
import { Button } from 'anf-core-react';
import PropTypes from 'prop-types';
import React from 'react';
import SEARCH_RESULTS_STATS_DATA from './queries';

function DepartmentButton({
  departmentId,
  departmentName,
  searchTerm,
  onDepartmentChange,
}) {
  const activeDepartment = departmentId === 'All' ? null : departmentId;
  const { data: statsData } = useQuery(SEARCH_RESULTS_STATS_DATA, {
    variables: {
      departmentId: activeDepartment,
      searchTerm,
      rows: '0',
    },
  });

  if (statsData?.searchResults?.productTotalCount === 0 || !statsData) {
    return null;
  }

  return (
    <Button
      onClick={onDepartmentChange}
      value={departmentId}
      variant="tertiary-dark"
    >
      {departmentName}
      {statsData?.searchResults?.productTotalCount ? ` (${statsData.searchResults.productTotalCount})` : ''}
    </Button>
  );
}
DepartmentButton.propTypes = {
  departmentId: PropTypes.string,
  departmentName: PropTypes.string,
  searchTerm: PropTypes.string,
  onDepartmentChange: PropTypes.func.isRequired,
};

DepartmentButton.defaultProps = {
  departmentId: '',
  departmentName: '',
  searchTerm: '',
};

export default DepartmentButton;
