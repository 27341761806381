import PropTypes from 'prop-types';
import React, {
  useCallback, useReducer, useRef, useEffect, useState,
} from 'react';
import { useWindowSize, useTitle } from 'react-use';
import useCRSParameterListener from '../../hooks/useCRSParameterListener';
import useIsScrolling from '../../hooks/useIsScrolling';
import useStickyIntersection from '../../hooks/useStickyIntersection';
import useStoreData from '../../hooks/useStoreData';
import useTranslatedText from '../../hooks/useTranslatedText';
import useUrlSync from '../../hooks/useUrlSync';
import {
  ACTION, PRODUCTS_PER_PAGE,
} from '../../tools/constants';
import getFacetmap from '../../tools/getFacetmap';
import getSelectedFacets from '../../tools/getSelectedFacets';
import isServer from '../../tools/isServer';
import reducer from '../../tools/parametersChange';
import { triggerFilterAnalytics } from '../../tools/triggerAnalytics';
import $window from '../../tools/window';
import EditableSearchHeader from '../EditableSearch/EditableSearchHeader';
import SearchAside from '../SearchAside/SearchAside';
import SearchErrorPage from '../SearchErrorPage';
import SearchMainContent from '../SearchMainContent/SearchMainContent';
import StoreDetailsProvider from '../StoreDetailsProvider/StoreDetailsProvider';
import style from './SearchPage.module.scss';
import useFeatureFlags from './hooks/useFeatureFlags';
import useSearchResponse from './hooks/useSearchResponse';
import triggerFacetAnalytics from './tools/triggerFacetAnalytics';
import triggerSortAnalytics from './tools/triggerSortAnalytics';
import updateSearchTermToSuggested from './tools/updateSearchTermToSuggested';

// all props come through gateway.jsx filtered through the parseEndpointParameters function
export default function SearchPage({
  brand: intlBrand = '',
  countryFulfillmentStore = '',
  departmentId: intlDepartmentId = '',
  facet = [],
  filter = '',
  searchTerm = '',
  sort = '',
  start = '0',
  userInputedSearchTerm,
  store = '',
  storeId = '',
}) {
  // feature flags
  const {
    departmentSuggestionsFlag,
    hasShopMyStoreEnabled,
  } = useFeatureFlags();

  const intlFacetArray = typeof facet === 'string' ? [facet] : facet;
  const isScrollingUp = useIsScrolling(['up']);
  const [parameters, dispatch] = useReducer(reducer, {
    actionType: 'pageLoad',
    countryFulfillmentStore,
    currentURL: $window.location?.pathname,
    departmentId: intlDepartmentId, // Provided by URL
    facet: intlFacetArray,
    filter,
    rows: PRODUCTS_PER_PAGE,
    searchTerm,
    sort,
    submitMethod: 'toaster',
    start,
    initialSearchTerm: userInputedSearchTerm,
    userInputedSearchTerm,
  });
  const { width } = useWindowSize();
  const [isDesktop, setIsDesktop] = useState(true);
  const gridWrapper = useRef(null);
  const railRef = useRef({ appliedRailFilters: [] });
  // derived state:
  const isFacetSelected = parameters.facet?.length > 0;
  const gridIntersecting = useStickyIntersection(gridWrapper);
  const storeDetails = useStoreData();
  const titlePageDomain = useTranslatedText('titlePageDomain', { fallback: '' });
  const titleSearchPage = useTranslatedText('titleSearchPage', { fallback: 'Search Page' });
  useTitle(parameters.searchTerm ? `${parameters.searchTerm} | ${titlePageDomain.value}` : `${titleSearchPage.value} | ${titlePageDomain.value}`);
  useUrlSync(parameters, dispatch);

  useEffect(() => {
    setIsDesktop(width >= 1025);
  }, [setIsDesktop, width]);

  const {
    data,
    error,
    loading,
    previousData,
    resolvedSearchTerm,
    setResolvedSearchTerm,
  } = useSearchResponse(parameters);

  // Events
  /**
   * Dispatches a LOCAL_STORE_TOGGLE Action to update the Local Store state
   * with new Store ID and current Search Term
   */
  const handleStoreFilter = useCallback((storeIdFromFilter) => {
    updateSearchTermToSuggested(resolvedSearchTerm, parameters.searchTerm, dispatch);
    dispatch({
      type: ACTION.LOCAL_STORE_TOGGLE,
      payload: storeIdFromFilter,
    });
    triggerFilterAnalytics(storeDetails, storeIdFromFilter);
  }, [parameters.searchTerm, resolvedSearchTerm, storeDetails]);

  /**
   * Dispatches a FACET_TOGGLE Action to update the Facet state
   * with new Facet Map and current Search Term
   * after removing the selected facet tag
   * @param {Object} payload - Object containing the facetKey and facetValue to be removed
  */
  const onClearFacetTag = useCallback((payload) => {
    if (railRef.current.appliedRailFilters.includes(`${payload.facetKey},${payload.facetValue}`)) {
      railRef.current.appliedRailFilters = railRef.current.appliedRailFilters.filter(
        (item) => item !== `${payload.facetKey},${payload.facetValue}`,
      );
    }
    const facetMap = getSelectedFacets(parameters.facet);
    // Check if selected checkbox is in already selected facet filters
    if (facetMap.has(payload.facetKey)) {
      const checkedFacets = facetMap.get(payload.facetKey);
      const index = checkedFacets.indexOf(payload.facetValue);
      if (index !== -1) {
        if (checkedFacets.length === 1) {
          facetMap.delete(payload.facetKey);
        } else {
          checkedFacets.splice(index, 1);
        }
      }
    }

    updateSearchTermToSuggested(resolvedSearchTerm, parameters.searchTerm, dispatch);
    dispatch({
      type: ACTION.FACET_TOGGLE,
      payload: [...facetMap],
    });
  }, [parameters.facet, parameters.searchTerm, resolvedSearchTerm]);

  /**
   * Dispatches a SORT_UPDATE Action to update the Sort state
   * with new Sort value and current Search Term
   */
  const handleSortChange = useCallback((event) => {
    updateSearchTermToSuggested(resolvedSearchTerm, parameters.searchTerm, dispatch);
    dispatch({
      type: ACTION.SORT_UPDATE,
      payload: event.target.value,
    });
    triggerSortAnalytics(event.target.value);
  }, [parameters.searchTerm, resolvedSearchTerm]);

  /**
   * Dispatches a CLEAR_ALL_PARAMETERS to clear all applied filters and update the Search Term
   */
  const onClearAllBtnClick = useCallback(() => {
    railRef.current.appliedRailFilters = [];
    updateSearchTermToSuggested(resolvedSearchTerm, parameters.searchTerm, dispatch);
    dispatch({ type: ACTION.CLEAR_ALL_PARAMETERS });
  }, [parameters.searchTerm, resolvedSearchTerm]);

  /**
   * Handle Department change from Department Dropdown
   * @param {Object} event - Event object from the dropdown change
   * Dispatches a DEPARTMENT_CHANGE to update the Department state with
   * new Department ID and current Search Term
   */
  const onDepartmentChange = useCallback((event) => {
    railRef.current.appliedRailFilters = [];
    updateSearchTermToSuggested(resolvedSearchTerm, parameters.searchTerm, dispatch);
    dispatch({
      type: ACTION.DEPARTMENT_CHANGE,
      payload: event.target.value === 'All' ? '' : event.target.value,
    });
  }, [parameters.searchTerm, resolvedSearchTerm]);

  /**
   * Dispatches a PAGINATION Action to update the Pagination state
   * with new Start value and current Search Term
   * @param {Object} _ - Event object from the pagination button click (not used)
   * @param {number} newStart - New Start value to be updated
   */
  const onPaginationButtonClick = useCallback((_, newStart) => {
    updateSearchTermToSuggested(resolvedSearchTerm, parameters.searchTerm, dispatch);
    dispatch({
      type: ACTION.PAGINATION,
      payload: newStart,
    });
  }, [parameters.searchTerm, resolvedSearchTerm]);

  /**
   * Dispatches a FACET_TOGGLE Action to update the Facet state
   * with new Facet Map and current Search Term
   * @param{Object} facetMap - Map of selected facets
   * @param{string} discoverTrigger - String to identify the discover trigger
   * @param{Object} discoverProps - Object with data_text and data_action for discover trigger
   */
  const updateFacets = useCallback((facetMap, discoverTrigger = '', facetId = '') => {
    updateSearchTermToSuggested(resolvedSearchTerm, parameters.searchTerm, dispatch);

    dispatch({
      type: ACTION.FACET_TOGGLE,
      payload: [...facetMap],
    });
    triggerFacetAnalytics({
      facetMap,
      departmentId: parameters.departmentId,
      appliedFilterCount: railRef.current.appliedRailFilters.length,
      discoverTrigger,
      facetId,
    });
  }, [parameters.departmentId, parameters.searchTerm, resolvedSearchTerm]);

  /**
   * Handle Facet checkbox change in either the left aside on Desktop or the Refine Toast on Mobile
   * @param {Object} event - Event object from the checkbox change
   * Dispatches a FACET_TOGGLE to update the Facet state with new Facet Map and current Search Term
   */
  const handleCheckBoxChange = useCallback((event) => {
    const { target: { checked, value } } = event;
    railRef.current.appliedRailFilters = checked
      ? [...railRef.current.appliedRailFilters, value]
      : railRef.current.appliedRailFilters.filter((item) => item !== value);

    const facetMap = getFacetmap(event, parameters.facet);
    updateFacets(facetMap);
  }, [parameters.facet, updateFacets]);

  /**
   * Handle Facet change from the Bottom Tray on Mobile
   * calls updateFacets to update the Facet state
   * Currently exposed through our Tap to Discover feature (Size and Length filters)
   * @param {string} facetId - ID of the facet being changed
   * @param {Array} filters - Array of selected filters
   * @param {string} discoverTrigger - String to identify the discover trigger
   */
  const handleFacetToasterChange = useCallback((facetId, filters, discoverTrigger = '') => {
    const facetMap = getSelectedFacets(parameters.facet);
    if (filters.length === 0) {
      facetMap.delete(facetId);
    } else {
      facetMap.set(facetId, filters);
    }

    updateFacets(facetMap, discoverTrigger, facetId);
  }, [parameters.facet, updateFacets]);

  /**
   * Submit search term from Editable Search Header or Search Default View using SEARCHTERM action
   * @param {string} submission.value - Search term, default is ''
   * @param {string} submission.submitMethod -
   *  Method used to submit the search term, default is 'user-initiated'
   * @param {string} submission.departmentId -
   *  Department ID to be suggested in the search term, if not passed then
   * the current state of departmentId will be used (parameters.departmentId)
   * which will be undefined from Search Default View
   */
  const onSearchTermChange = useCallback(({
    value: newSearchTerm = '',
    submitMethod = 'user-initiated',
    departmentId: suggestedDepartment = parameters.departmentId,
  }) => {
    railRef.current.appliedRailFilters = [];
    dispatch({
      type: ACTION.SEARCHTERM,
      payload: {
        searchTerm: newSearchTerm,
        submitMethod,
        departmentId: suggestedDepartment,
      },
    });
  }, [parameters.departmentId]);

  const handleCategoryParametersChanged = useCallback((event) => {
    handleStoreFilter(event.detail.filter ?? '');
  }, [handleStoreFilter]);

  useCRSParameterListener(handleCategoryParametersChanged);

  if (error) {
    console.error('SearchPage : getSearchData : Error executing GraphQL query', {
      searchTermParameter: searchTerm,
      facetParameter: parameters.facet,
      departmentIdParameter: parameters.departmentId,
      error,
    });

    if (isServer()) {
      return null;
    }

    return <SearchErrorPage error={error} />;
  }

  let currentData;

  if (loading) {
    if (!previousData) {
      return null;
    }
    currentData = previousData;
  } else {
    currentData = data;
  }

  const {
    departmentId,
    facets,
    pagination,
    products,
    sortData,
    productTotalCount,
    departments: elasticDepartmentData,
    searchSuggestions,
  } = currentData?.searchResults || {};

  const showAdditionalControls = productTotalCount !== 0 || !departmentSuggestionsFlag;
  const suggestionObject = searchSuggestions?.byName;

  return (
    <StoreDetailsProvider brand={intlBrand} store={store} storeId={storeId}>
      <div className="editable-search-container">
        <EditableSearchHeader
          departmentId={parameters.departmentId || departmentId}
          facet={parameters.facet}
          facetData={currentData?.searchResults?.facets}
          filter={parameters.filter}
          handleStoreFilter={handleStoreFilter}
          isDesktop={isDesktop}
          isFacetSelected={isFacetSelected}
          onCheckBoxChange={handleCheckBoxChange}
          onClearAllBtnClick={onClearAllBtnClick}
          onFacetToasterChange={handleFacetToasterChange}
          onSearchTermChange={onSearchTermChange}
          onSortChange={handleSortChange}
          resultsCount={currentData?.searchResults?.productTotalCount}
          searchTerm={resolvedSearchTerm || parameters.searchTerm}
          selectedSort={parameters.sort
            || currentData?.searchResults?.sortData?.defaultSortOption}
          setResolvedSearchTerm={setResolvedSearchTerm}
          showStickyBar={gridIntersecting && isScrollingUp}
          sortData={currentData?.searchResults?.sortData}
          storeDetails={storeDetails}
          suggestionObject={suggestionObject}
        />
      </div>
      <div className={`${style.gridContentWrap} scope-1892`} id="primary-content">
        {isDesktop ? (
          <SearchAside
            departmentId={(departmentId !== undefined && departmentId !== null
              ? departmentId : parameters.departmentId || '')}
            elasticDepartmentData={elasticDepartmentData}
            facets={currentData?.searchResults?.facets}
            handleCheckBoxChange={handleCheckBoxChange}
            handleStoreFilter={handleStoreFilter}
            hasShopMyStoreEnabled={hasShopMyStoreEnabled}
            isClearAllButtonEnabled={isFacetSelected
              || !!parameters.filter}
            isDesktop={isDesktop}
            onClearAllBtnClick={onClearAllBtnClick}
            onDepartmentChange={onDepartmentChange}
            parameters={parameters}
            showAdditionalControls={showAdditionalControls}
            storeDetails={storeDetails}
          />
        ) : null}
        <main ref={gridWrapper} className={style.main} tabIndex="-1">
          <SearchMainContent
            elasticDepartmentData={elasticDepartmentData}
            facets={facets}
            handleCheckBoxChange={handleCheckBoxChange}
            handleSortChange={handleSortChange}
            handleStoreFilter={handleStoreFilter}
            onClearAllBtnClick={onClearAllBtnClick}
            onClearFacetTag={onClearFacetTag}
            onDepartmentChange={onDepartmentChange}
            onPaginationButtonClick={onPaginationButtonClick}
            onSearchTermChange={onSearchTermChange}
            parameters={parameters}
            resultsCount={currentData?.searchResults?.productTotalCount}
            searchGridData={{
              loading, pagination, products, error, departmentId,
            }}
            sortData={sortData}
            storeDetails={storeDetails}
          />
        </main>
      </div>
    </StoreDetailsProvider>
  );
}
SearchPage.propTypes = {
  // Required props
  brand: PropTypes.string.isRequired,
  // Optional props
  searchTerm: PropTypes.string,
  countryFulfillmentStore: PropTypes.string,
  departmentId: PropTypes.string,
  facet: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  filter: PropTypes.string,
  userInputedSearchTerm: PropTypes.string,
  sort: PropTypes.string,
  start: PropTypes.string,
  store: PropTypes.string,
  storeId: PropTypes.string,
};
