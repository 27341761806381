import PropTypes from 'prop-types';
import React from 'react';
import addUrlParameters from '../../tools/addUrlParameters';
import { PRODUCT_CARD_URL_ANALYTICS_PARAMETERS } from '../../tools/constants';
import style from './productCard.module.scss';

const ProductCardLink = ({
  children,
  onClick = () => { },
  url,
}) => (
  <a className={style['product-content-link']} href={addUrlParameters(url, PRODUCT_CARD_URL_ANALYTICS_PARAMETERS)} onClick={onClick}>
    {children}
  </a>
);

ProductCardLink.propTypes = {
  children: PropTypes.node.isRequired,
  url: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default ProductCardLink;
